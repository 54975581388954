<template>
    <q-select
        v-model="result"
        outlined
        dense
        class="cell-span-col-6"
        label="Результат исполнения"
        clearable
        :options="PERFORM_RESULTS_PO_COMMON"
        emitValue
        mapOptions
        :disable="request.request_status_id != REQUEST_STATUS_VALUES.PERFORMING || !canBeEdited"
    />
    <q-input
        ref="resComment"
        v-model="note"
        outlined
        dense
        class="cell-span-col-6"
        type="textarea"
        :inputStyle="{ resize: 'none' }"
        label="Комментарий"
        errorMessage="Необходимо заполнить"
        :error="!commentRules"
        lazyRules
        hideBottomSpace
        :disable="request.request_status_id != REQUEST_STATUS_VALUES.PERFORMING || !canBeEdited"
    />
</template>

<script>
import { mapGetters } from "vuex";
import {
    REQUEST_STATUS_VALUES,
    PERFORM_RESULTS_PO_COMMON,
} from "@/store/constants";
import generateRequestProperty from "@/helpers/generateRequestProperty";

export default {
    name: "Moving_2_Result", // eslint-disable-line
    props: {
        canBeEdited: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        return {
            REQUEST_STATUS_VALUES,
            PERFORM_RESULTS_PO_COMMON,
        };
    },
    computed: {
        ...mapGetters({
            request: "request",
        }),
        result: generateRequestProperty("result"),
        note: generateRequestProperty("note"),
        commentRules() {
            return this.result
                ? this.note && this.note.length > 3
                : true;
        },
    },
};
</script>
