import PizZip from "pizzip";
import JSzipUtils from "jszip-utils";
import Docxtemplater from "docxtemplater";
import XlsxModule from "docxtemplater-xlsx-module";

export function createXls(
    siteHost,
    templateName,
    fileName,
    replacement,
) {
    const mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    return new Promise((resolve, reject) => {
        JSzipUtils.getBinaryContent(
            `${siteHost}/public/templates/${templateName}?${Date.now()}`,
            (error, content) => {
                if (error) {
                    reject(error);
                }
                const zip = new PizZip(content);
                const xls = new Docxtemplater(zip, { modules: [new XlsxModule()] });
                try {
                    xls.render(replacement);
                }
                catch (err) {
                    reject(error);
                }
                const blob = xls.getZip().generate({
                    type: "nodebuffer",
                    mimeType,
                    compression: "DEFLATE",
                });
                const fileXls = new File(
                    [blob],
                    fileName,
                    {
                        type: mimeType,
                    },
                );
                resolve(fileXls);
            },
        );
    });
}

export function saveXls(docForSave) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(docForSave, docForSave.name);
    }
    else {
        const url2 = window.URL.createObjectURL(docForSave);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url2;
        a.download = docForSave.name;
        a.click();
        window.URL.revokeObjectURL(url2);
        document.body.removeChild(a);
    }
}
