<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-spinner-grid
                    color="primary"
                    size="5.5em"
                />
            </div>
            <q-card-section>
                <div class="text-h6">
                    Проверка учетных единиц
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <SelectFilter
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    clearable
                />
            </q-card-section>

            <q-btn
                flat
                label="Проверить"
                color="primary"
                @click="checkTools"
            />

            <div
                v-if="result"
                class="dialog-common-grid-col-2"
            >
                <div class="cell cell-right">
                    Всего учетных единиц:
                </div>
                <div class="cell">
                    {{ result.toolsAll }}
                </div>
                <div class="cell cell-right">
                    Количество учетных единиц без моделей:
                </div>
                <div class="cell">
                    {{ result.toolsWithoutModel }}
                </div>
                <div class="cell cell-right">
                    Количество учетных единиц с незаполненными характеристиками:
                </div>
                <div class="cell">
                    {{ result.toolsWithoutTraits }}
                </div>
                <div class="cell cell-span-col-2 cell-center">
                    <q-btn
                        flat
                        label="Копировать характеристики"
                        color="primary"
                        @click="copyTraits"
                    />
                </div>
            </div>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-close-popup
                    flat
                    label="Закрыть"
                    color="primary"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import SelectFilter from "@/components/Common/SelectFilter.vue";

export default {
    name: "DialogCheckTools",
    components: {
        SelectFilter,
    },
    data() {
        return {
            regionId: null,
            result: null,
            showSpinner: false,
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
        }),
    },
    watch: {
        regionId() {
            this.result = null;
        },
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async checkTools() {
            this.showSpinner = true;
            const res = await axios.get(
                `${this.siteHost}/api/tools_check_traits`,
                {
                    params: {
                        regionId: this.regionId,
                    },
                },
            );
            this.result = res.data;
            this.showSpinner = false;
        },
        async copyTraits() {
            this.showSpinner = true;
            await axios.get(
                `${this.siteHost}/api/tools_copy_traits`,
                {
                    params: {
                        regionId: this.regionId,
                    },
                },
            );
            this.result = null;
            this.showSpinner = false;
        },
    },
};
</script>
