<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-circular-progress
                    showValue
                    class="text-light-blue q-ma-md"
                    :value="valueProgress"
                    size="5.5em"
                    color="light-blue"
                />
            </div>

            <q-card-section>
                <div class="text-h6">
                    Создание Приложения №1
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section style="max-height: 70vh;">
                <PoeDatePicker
                    ref="refDateStart"
                    v-model="dateStart"
                    labelTitle="Дата начала отчета"
                    :futureDateAllowed="false"
                />
                <PoeDatePicker
                    ref="refDateFinsh"
                    v-model="dateFinsh"
                    labelTitle="Дата окончания отчета"
                />
                <SelectFilter
                    ref="refUnit"
                    v-model="hostId"
                    :options="hosts"
                    optionLabel="name"
                    label="Балансодержатель"
                    clearable
                    class="q-mb-xs"
                    hideBottomSpace
                    :rules="[val => val || 'Нужно выбрать организацию']"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сформировать"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import { createXls, saveXls } from "@/helpers/createXls";

export default {
    name: "DialogCreateEnclosure1",
    components: {
        SelectFilter,
        PoeDatePicker,
    },
    data() {
        return {
            hostId: null,
            dateStart: null,
            dateFinsh: null,
            showSpinner: false,
            valueProgress: 0,
        };
    },
    computed: {
        ...mapGetters({
            hosts: "hosts",
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getUnitsOfOrg: "getUnitsOfOrg",
            getOrgProperty: "getOrgProperty",
            getRegionName: "getRegionName",
            unitsFull: "unitsFull",
        }),
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            this.showSpinner = true;
            this.valueProgress = 0;

            this.$refs.refDateStart.validate();
            this.$refs.refDateFinsh.validate();
            this.$refs.refUnit.validate();

            if (this.$refs.refDateStart.hasError ||
                this.$refs.refDateFinsh.hasError ||
                this.$refs.refUnit.hasError) {
                return;
            }

            const unitsIds = this.unitsFull
                .filter((u) => u.host_id === this.hostId)
                .map((u) => u.id);
            const unitsOfOrg = this.getUnitsOfOrg(this.hostId);

            for (let i = 0; i < unitsOfOrg.length; i++) {
                if (unitsIds.includes(unitsOfOrg[i].id)) {
                    const first = unitsOfOrg[i].id;
                    unitsIds.sort((x, y) => {
                        if (x === first) {
                            return -1;
                        }

                        return y === first ? 1 : 0;
                    });
                }
            }

            // TODO: send all units ids in one request
            let requests = [];
            for (let i = 0; i < unitsIds.length; i++) {
                requests = requests.concat(
                    (await this.$store.dispatch(
                        "getRequestsEnclosure1",
                        {
                            unitId: unitsIds[i],
                            date_beg: this.dateStart,
                            date_end: this.dateFinsh,
                        },
                    )).requests,
                );
            }

            const outFileName = `Приложение №1 за период с ${this.dateStart} по ${this.dateFinsh}.xlsx`;

            const replacement = {
                date_start: this.dateStart,
                date_finsh: this.dateFinsh,
                period_year: new Date(this.dateStart).getFullYear(),
                current_year: new Date().getFullYear(),
                items: await this.getRequestsData(requests),
                total_count: requests.length,
            };
            const replacement_empty = {
                date_start: this.dateStart,
                date_finsh: this.dateFinsh,
                period_year: new Date(this.dateStart).getFullYear(),
                current_year: new Date().getFullYear(),
            };

            createXls(
                this.siteHost,
                requests.length > 0 ? "enclosure-1.xlsx" : "enclosure-1-empty.xlsx",
                outFileName,
                requests.length > 0 ? replacement : replacement_empty,
            )
                .then((report) => {
                    saveXls(report);
                });
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        async getRequestsData(requests) {
            const items = [];
            for (let i = 0; i < requests.length; i++) {
                this.valueProgress = Math.round((i / requests.length) * 100);
                const orgId = this.getOrgIdOfUnit(requests[i].unit_id);
                const regionId = this.getOrgProperty(orgId, "region_id");
                const fullRegionName = this.getRegionName(regionId);
                const regionName = fullRegionName.includes(".") ? fullRegionName.split(".")[1].trim() : fullRegionName;
                const orgName = this.getOrgProperty(orgId, "name");
                const item = {
                    n: i + 1,
                    region_code: regionId,
                    region_name: regionName,
                    org_name: orgName,
                    co7_num: requests[i].co_7_number || "",
                    co7_date: requests[i].co_7_date || "",
                    form_correct: "да",
                    count: 1,
                };
                items.push(item);
            }
            return items;
        },
    },
};
</script>
