<template>
    <div>
        <div class="text-h6">
            Запчасти
        </div>
        <div :class="[
            'q-pb-xs',
            allowViewCost ? 'request-spares-grid' : 'request-spares-grid--short',
        ]">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Наименование
            </div>
            <div
                v-if="allowViewCost"
                class="cell cell-header cell-center"
            >
                Стоимость
            </div>
            <div class="cell cell-header cell-center">
                Количество
            </div>
            <div class="cell cell-header cell-center">
                <q-btn
                    round
                    :icon="matAdd"
                    size="sm"
                    color="primary"
                    :disable="!canBeEdited"
                    @click="addSpare"
                />
            </div>
            <!-- data -->
            <RequestSpareRow
                v-for="(spare, index) in reqSpares"
                :key="index"
                :spare="spare"
                :index="index"
                :canBeEdited="canBeEdited"
                :allowViewCost="allowViewCost"
            />
        </div>
        <div
            v-if="allowViewCost"
            class="q-pb-xs"
        >
            <q-input
                v-model="reqSparesCost"
                readonly
                outlined
                dense
                label="Общая стоимость запчастей"
                class="q-py-xs"
                :disable="!canBeEdited"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import RequestSpareRow from "./RequestSpareRow.vue";
import DialogSearchSpare from "./DialogSearchSpare.vue";

export default {
    name: "MovingRepairSpares",
    components: {
        RequestSpareRow,
    },
    props: {
        canBeEdited: {
            type: Boolean,
            required: true,
        },
        allowViewCost: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            reqSpares: "reqSpares",
            reqSparesCost: "reqSparesCost",
        }),
    },
    methods: {
        addSpare() {
            this.$q.dialog({
                component: DialogSearchSpare,
                componentProps: {
                    dialogMessage: "dialogMessage",
                },
            })
                .onOk((spare) => {
                    this.$store.dispatch("addRequestSpare", spare);
                });
        },
    },
};
</script>
