<template>
    <q-dialog
        v-model="showFilter"
        position="top"
    >
        <q-card style="width: 70%; max-width: 70%;">
            <div class="filter-grid--tool">
                <SelectFilter
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    clearable
                />
                <q-select
                    v-model="orgId"
                    outlined
                    dense
                    clearable
                    :options="orgsFiltered"
                    optionValue="id"
                    optionLabel="name"
                    mapOptions
                    emitValue
                    label="Организация"
                />
                <q-input
                    v-model="surname"
                    outlined
                    dense
                    clearable
                    label="Фамилия"
                />
                <q-input
                    v-model="name"
                    outlined
                    dense
                    clearable
                    label="Имя"
                />
                <q-input
                    v-model="patronymic"
                    outlined
                    dense
                    clearable
                    label="Отчество"
                />
                <q-input
                    v-model="post"
                    outlined
                    dense
                    clearable
                    label="Должность"
                />
            </div>
            <div class="filter-controls">
                <q-btn
                    label="Очистить"
                    @click="$store.dispatch(
                        'setFilterUsers',
                        {
                            region_id: null,
                            organization_id: null,
                            post: null,
                            surname: null,
                            name: null,
                            patronymic: null,
                        },
                    )"
                />
                <q-btn
                    label="Закрыть"
                    @click="showFilter = false"
                />
            </div>
        </q-card>
    </q-dialog>

    <q-btn
        round
        :icon="matSearch"
        @click="showFilter = !showFilter"
    />
</template>

<script>
import { mapGetters } from "vuex";
import SelectFilter from "@/components/Common/SelectFilter.vue";

function generateProperty(property) {
    return {
        get() {
            return this.filterUsers[property];
        },
        set(value) {
            this.$store.dispatch(
                "setFilterUsers",
                {
                    ...this.filterUsers,
                    [property]: value,
                },
            );
        },
    };
}

export default {
    name: "FilterUsers",
    components: {
        SelectFilter,
    },
    data() {
        return {
            showFilter: false,
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            orgs: "orgs",
            filterUsers: "filterUsers",
        }),
        orgsFiltered() {
            return this.regionId
                ? this.orgs.filter((o) => o.region_id === this.regionId)
                : this.orgs;
        },
        regionId: generateProperty("region_id"),
        orgId: generateProperty("organization_id"),
        post: generateProperty("post"),
        surname: generateProperty("surname"),
        name: generateProperty("name"),
        patronymic: generateProperty("patronymic"),
    },
    watch: {
        regionId(v) {
            if (v) {
                this.orgId = null;
            }
        },
    },
};
</script>
