<template>
    <q-btn
        round
        :icon="matAttachFile"
        size="sm"
        color="primary"
        @click="addDoc"
    >
        <q-tooltip
            :delay="300"
            anchor="top right"
            self="bottom middle"
        >
            Добавить документ
        </q-tooltip>
    </q-btn>
    <input
        id="hiddenFileInputId"
        type="file"
        name="hiddenFileInputId"
        multiple
        style="display: none;"
        :accept="fileTypes.join(',')"
        @change="filesListChanged"
    >
</template>

<script>
export default {
    name: "FilesList",
    props: {
        fileTypes: {
            type: Array,
            default: () => [
                ".png",
                ".jpeg",
                ".jpg",
                ".pdf",
                ".doc",
                ".docx",
                ".xls",
                ".xlsx",
                ".zip",
                ".rar",
                ".7z",
            ],
        },
        maxFileSize: {
            type: Number,
            default: 52428800, // 50Mb
        },
        maxFilesCount: {
            type: Number,
            default: 5,
        },
        fileSection: {
            type: String,
            default: "request",
        },
        filesList: {
            type: Array,
            required: true,
        },
    },
    emits: ["update:filesList"],
    methods: {
        addDoc() {
            const fileInput = document.getElementById("hiddenFileInputId");
            fileInput.click();
        },
        filesListChanged(e) {
            // TODO: check if file exists
            if (this.filesList.length + e.target.files.length > this.maxFilesCount) {
                this.$q.notify({
                    type: "notify-failure",
                    message: `Не более ${this.maxFilesCount} файлов`,
                });
                return;
            }

            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files[i];

                if (file.size > this.maxFileSize) {
                    const sizeMb = this.maxFileSize / (1024 * 1024);
                    this.$q.notify({
                        type: "notify-failure",
                        message: `Размер файла не может быть более ${sizeMb} мегабайт`,
                    });
                    return;
                }

                const ext = `.${file.name.split(".").pop().toLowerCase()}`;

                if (!this.fileTypes.includes(ext)) {
                    this.$q.notify({
                        type: "notify-failure",
                        message: "Неподдерживаемый тип файла",
                    });
                    return;
                }
            }

            const aFiles = [];
            for (let i = 0; i < e.target.files.length; i++) {
                const file = e.target.files[i];
                aFiles.push({
                    file_name: file.name,
                    mimetype: file.type,
                    section: this.fileSection,
                    blob: file,
                });
            }

            this.$emit(
                "update:filesList",
                [
                    ...this.filesList,
                    ...aFiles,
                ],
            );
        },
    },
};
</script>
