<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-circular-progress
                    :value="valueProgress"
                    class="text-light-blue q-ma-md"
                    color="light-blue"
                    showValue
                    size="5.5em"
                />
            </div>

            <q-card-section>
                <div class="text-h6">
                    Отчет по подсистемам ГАС «Правосудие»
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section style="max-height: 70vh;">
                <PoeDatePicker
                    ref="refDateStart"
                    v-model="dateStart"
                    :futureDateAllowed="false"
                    labelTitle="Дата начала отчета"
                />
                <PoeDatePicker
                    ref="refDateFinsh"
                    v-model="dateFinsh"
                    labelTitle="Дата окончания отчета"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    :icon="matSave"
                    color="primary"
                    flat
                    label="Сформировать"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    :icon="matCancel"
                    color="primary"
                    flat
                    label="Отменить"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import { createDoc, saveDoc } from "@/helpers/createDoc";

export default {
    name: "DialogCreateSubsystemReport",
    components: {
        PoeDatePicker,
    },
    data() {
        return {
            dateStart: null,
            dateFinsh: null,
            showSpinner: false,
            valueProgress: 0,
        };
    },
    computed: {
        ...mapGetters({
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getOrgProperty: "getOrgProperty",
            programsSubsystems: "programsSubsystems",
            programs: "programs",
            regions: "regions",
        }),
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            this.showSpinner = true;
            this.valueProgress = 0;

            this.$refs.refDateStart.validate();
            this.$refs.refDateFinsh.validate();

            if (this.$refs.refDateStart.hasError ||
                this.$refs.refDateFinsh.hasError) {
                return;
            }

            const { requests } = (await this.$store.dispatch(
                "getRequestsSoftAllRegions",
                {
                    date_beg: this.dateStart,
                    date_end: this.dateFinsh,
                },
            ));

            const subsystems = await this.getSubsystemReport(this.programsSubsystems, this.programs, requests);
            const totalCount = await this.getTotalCount(requests);
            const outFileName = `Отчет по подсистемам ГАС «Правосудие» за период с ${this.dateStart} по ${this.dateFinsh}.docx`;

            const replacement = {
                rep_date_start: this.dateStart,
                rep_date_finsh: this.dateFinsh,
                ss: subsystems,
                total_count: totalCount,
            };

            createDoc(
                "report-subsystem.docx",
                outFileName,
                replacement,
            )
                .then((report) => {
                    saveDoc(report);
                });
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        getRequestCount(requests, program_id) {
            let count = 0;
            for (let i = 0; i < requests.length; i++) {
                const orgId = this.getOrgIdOfUnit(requests[i].unit_id);
                const regionId = this.getOrgProperty(orgId, "region_id");
                if (regionId < 99 && requests[i].program_id === program_id) {
                    count += 1;
                }
            }
            return count;
        },
        async getSubsystemReport(programsSubsystems, programs, requests) {
            const items = [];
            let counter = 0;
            for (let i = 0; i < programsSubsystems.length; i++) {
                this.valueProgress = Math.round((i / programsSubsystems.length) * 100);
                for (let j = 0; j < programs.length; j++) {
                    if (programs[j].gas_sub_system_id === programsSubsystems[i].id) {
                        counter += 1;
                        const item = {
                            n: counter,
                            subsystem_title: programsSubsystems[i].title,
                            pi_title: programs[j].title,
                            req_count: this.getRequestCount(requests, programs[j].id),
                        };
                        items.push(item);
                    }
                }
            }
            return items;
        },
        async getTotalCount(requests) {
            return requests.length;
        },
    },
};
</script>
