<template>
    <q-dialog
        ref="dialogEditToolSoftware"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Событие
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <PoeDatePicker
                    v-model="item.date_time"
                    labelTitle="Дата начала отчета"
                    :futureDateAllowed="false"
                />
                <q-select
                    v-model="item.event_id"
                    outlined
                    dense
                    :options="TOOLS_EVENTS"
                    mapOptions
                    emitValue
                    label="Событие"
                    class="q-mb-sm"
                    clearable
                />
                <q-input
                    v-model="item.comment"
                    outlined
                    dense
                    label="Примечание"
                    class="q-mb-sm"
                    autogrow=""
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { TOOLS_EVENTS } from "@/store/constants";
import { dateYMDtoDMY, dateDMYtoYMD } from "@/helpers/helpers";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";

export default {
    name: "DialogEditToolMovingDialogEdit",
    components: {
        PoeDatePicker,
    },
    props: {
        itemForEdit: {
            type: Object,
            default: () => ({
                title: "",
                type: 1,
                version: "",
                description: "",
                comment: "",
            }),
        },
    },
    emits: ["ok"],
    setup() {
        return {
            TOOLS_EVENTS,
        };
    },
    data() {
        return {
            item: {},
        };
    },
    methods: {
        show() {
            this.item = {
                ...this.itemForEdit,
                date_time: dateYMDtoDMY(this.itemForEdit.date_time),
            };
            this.$refs.dialogEditToolSoftware.show();
        },
        hide() {
            this.$refs.dialogEditToolSoftware.hide();
        },
        onOkClick() {
            this.$emit(
                "ok",
                {
                    ...this.item,
                    date_time: dateDMYtoYMD(this.item.date_time),
                },
            );

            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
