<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Программное обеспечение
            ({{ programsFilter.length }})
            <q-space />
            <FilterPrograms
                v-model:programGasSubSystemId="filterProgramGasSubSystemId"
                v-model:programTitle="filterProgramTitle"
                v-model:programType="filterProgramType"
                v-model:programOrgTypes="filterProgramOrgTypes"
            />
        </div>
        <div class="content-body">
            <div class="programs-grid">
                <div class="cell cell-header cell-center">
                    №
                </div>
                <div class="cell cell-header cell-center">
                    Тип
                </div>
                <div class="cell cell-header cell-center">
                    Наименование
                </div>
                <div class="cell cell-header cell-center">
                    Версия
                </div>
                <div class="cell cell-header cell-center">
                    Описание
                </div>
                <div class="cell cell-header cell-center">
                    <q-btn
                        v-if="canBeEdited"
                        round
                        :icon="matAdd"
                        size="0.7rem"
                        color="primary"
                        @click="editItem(null)"
                    />
                </div>

                <div
                    v-for="(program, index) in programsFilter"
                    :key="index"
                    class="row-wrapper"
                    @click="() => editItem(index)"
                >
                    <div class="cell cell-center">
                        {{ index + 1 }}
                    </div>
                    <div class="cell cell-center">
                        {{ program.type === PROGRAM_TYPES_VALUES.SOFT_COMMON ? "ОПО" : "СПО" }}
                    </div>
                    <div class="cell">
                        {{ program.title }}
                    </div>
                    <div class="cell cell-center">
                        {{ program.version }}
                    </div>
                    <div class="cell">
                        {{ program.description }}
                    </div>
                    <div class="cell cell-center">
                        <q-btn
                            v-if="canBeEdited"
                            round
                            :icon="matDelete"
                            size="0.7rem"
                            @click="(e) => deleteItem(e, index)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RIGHTS_CONSTANTS, PROGRAM_TYPES_VALUES } from "@/store/constants";
import FilterPrograms from "@/components/Filters/FilterPrograms.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";
import DialogEditProgram from "@/components/Dialogs/DialogEditProgram.vue";

export default {
    name: "Programs",
    components: {
        FilterPrograms,
    },
    setup() {
        return {
            PROGRAM_TYPES_VALUES,
        };
    },
    data() {
        return {
            filterProgramGasSubSystemId: null,
            filterProgramTitle: "",
            filterProgramType: 3,
            filterProgramOrgTypes: [],
        };
    },
    computed: {
        ...mapGetters({
            programs: "programs",
            currentUser: "currentUser",
        }),
        canBeEdited() {
            return !!(this.currentUser.rightsObject.RIGHT_PROGRAMS & RIGHTS_CONSTANTS.FULL);
        },
        programsFilter() {
            return this.programs
                .filter((p) => (this.filterProgramTitle
                    ? p.title.toLowerCase().includes(this.filterProgramTitle.toLowerCase())
                    : true))
                .filter((p) => ((p.type & this.filterProgramType) !== 0))
                .filter((p) => (this.filterProgramOrgTypes.length > 0
                    ? p.programs_org_types
                        .map((pt) => pt.org_type_id)
                        .filter((ot) => this.filterProgramOrgTypes.includes(ot))
                        .length > 0
                    : true))
                .filter((p) => {
                    if (this.filterProgramGasSubSystemId && !p.gas_sub_system_id) {
                        return false;
                    }
                    return this.filterProgramGasSubSystemId
                        ? p.gas_sub_system_id === this.filterProgramGasSubSystemId
                        : true;
                });
        },
    },
    methods: {
        editItem(index) {
            this.$q.dialog({
                component: DialogEditProgram,
                componentProps: {
                    itemForEdit: index === null
                        ? {}
                        : { ...this.programsFilter[index] },
                },
            })
                .onOk((program) => {
                    this.$store.dispatch("saveProgram", {
                        id: index === null ? null : program.id,
                        program,
                    })
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err.message,
                            });
                        });
                });
        },
        deleteItem(event, index) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить программное обеспечение
                        "${this.programsFilter[index].title} ${this.programsFilter[index].version}"?`,
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteProgram", this.programsFilter[index].id)
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
    },
};
</script>
