<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Комплекты ЗИП
            <q-space />
            <FilterSparesSetsItems />
        </div>

        <div class="spares-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Регион
            </div>
            <div class="cell cell-header cell-center">
                ЗИП
            </div>
            <div class="cell cell-header cell-center">
                Код
            </div>
            <div class="cell cell-header cell-center">
                Наименование
            </div>
            <div class="cell cell-header cell-center">
                Стоимость
            </div>
            <div class="cell cell-header cell-center">
                Количество
            </div>
            <div class="cell cell-header cell-center">
                Остаток
            </div>
            <div class="cell cell-header cell-center" />
            <!-- data -->
            <div
                v-for="(sparesSetItem, index) in sparesSetsItems"
                :key="index"
                class="row-wrapper"
                @click="editItem(index)"
            >
                <div class="cell cell-center">
                    {{ index + 1 }}
                </div>
                <div class="cell cell-center">
                    {{ getRegionName(sparesSetRegionId(sparesSetItem.spares_set_id)) }}
                </div>
                <div class="cell cell-center">
                    {{ sparesSetName(sparesSetItem.spares_set_id) }}
                </div>
                <div class="cell cell-center">
                    {{ sparesSetItem.code }}
                </div>
                <div class="cell">
                    {{ sparesSetItem.title }}
                </div>
                <div class="cell cell-right">
                    {{ (+sparesSetItem.cost).toFixed(2) }}
                </div>
                <div class="cell cell-right">
                    {{ sparesSetItem.amount }}
                </div>
                <div class="cell cell-right">
                    {{ sparesSetItem.amount - +sparesSetItem.spent }}
                </div>
                <div class="cell cell-center">
                    <q-btn
                        v-if="allowZipWrite"
                        round
                        :icon="matDelete"
                        size="0.7rem"
                        @click="(e) => deleteItem(e, index)"
                    />
                </div>
            </div>
        </div>

        <div class="app-controls">
            <ButtonNewItem
                buttonToolTip="Добавить программное обеспечение"
                @start-edit="editItem"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import ButtonNewItem from "@/components/Common/ButtonNewItem.vue";
import FilterSparesSetsItems from "@/components/Filters/FilterSparesSetsItems.vue";

import DialogEditSparesSet from "@/components/Dialogs/DialogEditSparesSet.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";
import { RIGHTS_CONSTANTS } from "@/store/constants";

export default {
    name: "Spares",
    components: {
        ButtonNewItem,
        FilterSparesSetsItems,
    },
    computed: {
        ...mapGetters({
            sparesSets: "sparesSets",
            sparesSetsItems: "sparesSetsItems",
            sparesSetName: "sparesSetName",
            sparesSetRegionId: "sparesSetRegionId",
            sparesSet: "sparesSet",
            currentUser: "currentUser",
            getRegionName: "getRegionName",
        }),
        allowZipWrite() {
            return !!(this.currentUser.rightsObject.RIGHT_ZIP & RIGHTS_CONSTANTS.FULL);
        },
    },
    methods: {
        editItem(index) {
            this.$q.dialog({
                component: DialogEditSparesSet,
                componentProps: {
                    itemForEdit: index === undefined
                        ? {}
                        : ({

                            ...this.sparesSet(this.sparesSetsItems[index].spares_set_id),
                        }),
                },
            })
                .onOk((sparesSet) => {
                    this.$store.dispatch("saveSparesSet", {
                        id: index === undefined ? null : sparesSet.id,
                        sparesSet,
                    })
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
        deleteItem(event, index) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: "Удалить ЗИП?",
                },
            })
                .onOk(() => {
                    this.$store.dispatch(
                        "deleteSparesSetItem",
                        this.sparesSetsItems[index].id,
                    )
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
    },
};
</script>
