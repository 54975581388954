<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Исполнение запроса Администратору системы
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <q-select
                    ref="refState"
                    v-model="responseState"
                    :options="INNER_REQS_STATES"
                    outlined
                    dense
                    mapOptions
                    class="q-mb-sm"
                    :rules="[val => val || 'Нужно указать']"
                    hideBottomSpace
                    label="Состояние"
                />
                <q-input
                    ref="refMessage"
                    v-model="responseMessage"
                    outlined
                    dense
                    :rules="[val => val.length < 3000 || 'Значение до 3000 символов']"
                    hideBottomSpace
                    type="textarea"
                    label="Комментарий"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { INNER_REQS_STATES } from "@/store/constants";

export default {
    name: "DialogSystemRequestResponse",
    emits: ["ok"],
    setup() {
        return {
            INNER_REQS_STATES,
        };
    },
    data() {
        return {
            responseState: null,
            responseMessage: "",
        };
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            this.$refs.refState.validate();
            this.$refs.refMessage.validate();

            if (this.$refs.refState.hasError || this.$refs.refMessage.hasError) {
                return;
            }

            this.$emit(
                "ok",
                {
                    response_state: this.responseState.id,
                    response_message: this.responseMessage,
                },
            );

            this.hide();
        },
    },
};
</script>
