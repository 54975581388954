<template>
    <div class="text-h6 q-mt-sm">
        Список перемещений
    </div>
    <div class="places-list-grid">
        <div class="cell cell-header cell-center">
            Дата
        </div>
        <div class="cell cell-header cell-center">
            Объект
        </div>
        <div class="cell cell-header cell-center">
            Помещение
        </div>
        <div class="cell cell-header cell-center">
            Должность
        </div>
        <div class="cell cell-header cell-center">
            ФИО
        </div>
        <div class="cell cell-header cell-center">
            <q-btn
                v-if="allowEdit"
                round
                :icon="matAdd"
                size="0.7rem"
                color="primary"
                @click="editItem(null)"
            />
        </div>
        <div
            v-for="(tp, index) in modelValue"
            :key="index"
            class="row-wrapper"
        >
            <div class="cell cell-center">
                {{ dateYMDtoDMY(tp.date_time) }}
            </div>
            <div class="cell">
                {{ getUnitTitle(+tp.unit_id) }}
            </div>
            <div class="cell">
                {{ tp.room }}
            </div>
            <div class="cell">
                {{ tp.man_post }}
            </div>
            <div class="cell">
                {{ tp.man_fio }}
            </div>
            <div class="cell cell-center">
                <q-btn
                    v-if="allowEdit"
                    round
                    :icon="matDelete"
                    size="0.7rem"
                    @click="(e) => deleteItem(e, index)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RIGHTS_CONSTANTS } from "@/store/constants";
import { dateYMDtoDMY } from "@/helpers/helpers";
import DialogEditToolPlaceDialogEdit from "./DialogEditToolPlaceDialogEdit.vue";

export default {
    name: "DialogEditToolPlace",
    props: {
        modelValue: {
            type: Array,
            required: true,
        },
    },
    emits: ["update:modelValue"],
    setup() {
        return {
            dateYMDtoDMY,
        };
    },
    computed: {
        ...mapGetters({
            unitsFull: "unitsFull",
            getUnitTitle: "getUnitTitle",
            currentUser: "currentUser",
        }),
        allowEdit() {
            return !!(this.currentUser.rightsObject.RIGHT_TOOL_PLACE_EDIT & RIGHTS_CONSTANTS.FULL);
        },
    },
    methods: {
        editItem(index) {
            this.$q.dialog({
                component: DialogEditToolPlaceDialogEdit,
                componentProps: {
                    itemForEdit: index === null
                        ? {}
                        : { ...this.modelValue[index] },
                },
            })
                .onOk((ts) => {
                    if (index === null) {
                        this.$emit(
                            "update:modelValue",
                            [
                                ...this.modelValue,
                                ts,
                            ].sort((a, b) => (a.date_time < b.date_time ? 1 : -1)),
                        );
                    }
                    else {
                        this.$emit(
                            "update:modelValue",
                            [
                                ...this.modelValue.slice(0, index),
                                ts,
                                ...this.modelValue.slice(index + 1),
                            ].sort((a, b) => (a.date_time < b.date_time ? 1 : -1)),
                        );
                    }
                });
        },
        deleteItem(event, index) {
            event.stopPropagation();
            this.$emit(
                "update:modelValue",
                [
                    ...this.modelValue.slice(0, index),
                    ...this.modelValue.slice(index + 1),
                ].sort((a, b) => (a.date_time < b.date_time ? 1 : -1)),
            );
        },
    },
};
</script>
