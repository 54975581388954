<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-circular-progress
                    :value="valueProgress"
                    class="text-light-blue q-ma-md"
                    color="light-blue"
                    showValue
                    size="5.5em"
                />
            </div>

            <q-card-section>
                <div class="text-h6">
                    Краткая таблица по ремонтам ПТС за период
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section style="max-height: 70vh;">
                <PoeDatePicker
                    ref="refDateStart"
                    v-model="dateStart"
                    :futureDateAllowed="false"
                    labelTitle="Дата начала отчета"
                />
                <PoeDatePicker
                    ref="refDateFinsh"
                    v-model="dateFinsh"
                    labelTitle="Дата окончания отчета"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    :icon="matSave"
                    color="primary"
                    flat
                    label="Сформировать"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    :icon="matCancel"
                    color="primary"
                    flat
                    label="Отменить"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import { createXls, saveXls } from "@/helpers/createXls";
import {
    REPAIRS_TYPES_VALUES,
    REQUEST_KINDS_VALUES,
    REQUEST_STATUS_VALUES,
} from "@/store/constants";

export default {
    name: "DialogCreateRepairShortTable",
    components: {
        PoeDatePicker,
    },
    data() {
        return {
            dateStart: null,
            dateFinsh: null,
            showSpinner: false,
            valueProgress: 0,
        };
    },
    computed: {
        ...mapGetters({
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getOrgProperty: "getOrgProperty",
            getRegionName: "getRegionName",
            postTitle: "postTitle",
            currentUser: "currentUser",
            regions: "regions",
        }),
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            this.showSpinner = true;
            this.valueProgress = 0;

            this.$refs.refDateStart.validate();
            this.$refs.refDateFinsh.validate();

            if (this.$refs.refDateStart.hasError ||
                this.$refs.refDateFinsh.hasError) {
                return;
            }

            const { requests } = (await this.$store.dispatch(
                "getRequestsRepairAllRegions",
                {
                    date_beg: this.dateStart,
                    date_end: this.dateFinsh,
                },
            ));

            const requestCounters = await this.getRequestCounters(requests);
            const outFileName = `Краткая таблица по ремонтам ПТС за период с ${this.dateStart} по ${this.dateFinsh}.xlsx`;

            const replacement = {
                rep_date_start: this.dateStart,
                rep_date_finsh: this.dateFinsh,
                items: await this.getRepairShortTableItems(requests),
                service_contract_all: requestCounters.get("SERVICE_CONTRACT"),
                filial_spare_all: requestCounters.get("FILIAL_SPARE"),
                filial_hand_all: requestCounters.get("FILIAL_HAND"),
                no_guaranty_all: requestCounters.get("NO_GUARANTY"),
                guaranty_all: requestCounters.get("GUARANTY"),
            };

            createXls(
                this.siteHost,
                "short-table-repair.xlsx",
                outFileName,
                replacement,
            )
                .then((repairtable) => {
                    saveXls(repairtable);
                });
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        async getRepairShortTableItems(requests) {
            const dataItems = new Map();
            // no need region code because region name contains region code
            // const zeroPad = (num, places) => String(num).padStart(places, "0");
            for (let i = 0; i < this.regions.length; i++) {
                const regionCode = this.regions[i].code;
                if (regionCode < 99) {
                    const item = {
                        region_id: regionCode,
                        region_name: this.regions[i].title,
                        service_contract_region: 0,
                        filial_spare_region: 0,
                        filial_hand_region: 0,
                        no_guaranty_region: 0,
                        guaranty_region: 0,
                    };
                    dataItems.set(regionCode, item);
                }
            }
            for (let i = 0; i < requests.length; i++) {
                this.valueProgress = Math.round((i / requests.length) * 100);
                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                    requests[i].request_status_id === REQUEST_STATUS_VALUES.CLOSED) {
                    const orgId = this.getOrgIdOfUnit(requests[i].unit_id);
                    const regionId = this.getOrgProperty(orgId, "region_id");
                    if (regionId < 99) {
                        const item = dataItems.get(regionId);
                        if (requests[i].repair_type_id === REPAIRS_TYPES_VALUES.SERVICE_CONTRACT) {
                            item.service_contract_region += 1;
                        }
                        if (requests[i].repair_type_id === REPAIRS_TYPES_VALUES.FILIAL_SPARE) {
                            item.filial_spare_region += 1;
                        }
                        if (requests[i].repair_type_id === REPAIRS_TYPES_VALUES.FILIAL_HAND) {
                            item.filial_hand_region += 1;
                        }
                        if (requests[i].repair_type_id === REPAIRS_TYPES_VALUES.GUARANTY) {
                            item.guaranty_region += 1;
                        }
                        else {
                            item.no_guaranty_region += 1;
                        }
                        dataItems.set(regionId, item);
                    }
                }
            }
            const items = Array.from(dataItems.values());
            items.sort((a, b) => a.region_id - b.region_id);
            return items;
        },
        async getRequestCounters(requests) {
            const counters = new Map();
            counters.set("SERVICE_CONTRACT", 0);
            counters.set("FILIAL_SPARE", 0);
            counters.set("FILIAL_HAND", 0);
            counters.set("NO_GUARANTY", 0);
            counters.set("GUARANTY", 0);
            for (let i = 0; i < requests.length; i++) {
                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                    requests[i].request_status_id === REQUEST_STATUS_VALUES.CLOSED) {
                    if (requests[i].repair_type_id === REPAIRS_TYPES_VALUES.SERVICE_CONTRACT) {
                        const count = counters.get("SERVICE_CONTRACT") + 1;
                        counters.set("SERVICE_CONTRACT", count);
                    }
                    if (requests[i].repair_type_id === REPAIRS_TYPES_VALUES.FILIAL_SPARE) {
                        const count = counters.get("FILIAL_SPARE") + 1;
                        counters.set("FILIAL_SPARE", count);
                    }
                    if (requests[i].repair_type_id === REPAIRS_TYPES_VALUES.FILIAL_HAND) {
                        const count = counters.get("FILIAL_HAND") + 1;
                        counters.set("FILIAL_HAND", count);
                    }
                    if (requests[i].repair_type_id === REPAIRS_TYPES_VALUES.GUARANTY) {
                        const count = counters.get("GUARANTY") + 1;
                        counters.set("GUARANTY", count);
                    }
                    else {
                        const count = counters.get("NO_GUARANTY") + 1;
                        counters.set("NO_GUARANTY", count);
                    }
                }
            }
            return counters;
        },
    },
};
</script>
