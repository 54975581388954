import axios from "axios";
import { createStore } from "vuex";
import Notify from 'quasar/src/plugins/notify/Notify.js';;

import auth from "./auth";
import usersRolesUnits from "./usersRolesUnits";
import users from "./users";
import organizations from "./organizations";
import posts from "./posts";
import regions from "./regions";
import manufacturers from "./manufacturers";
import tools from "./tools";
import toolsKinds from "./toolsKinds";
import toolsKindsTraits from "./toolsKindsTraits";
import toolsModels from "./toolsModels";
import toolsSets from "./toolsSets";
import sparesSets from "./sparesSets";
import roles from "./roles";
import requests from "./requests";
import pagination from "./pagination";
import programs from "./programs";
import requestsInner from "./requestInner";
import requestDocs from "./requestDocs";
import requestSpares from "./requestSpares";
import requestWorks from "./requestWorks";
import requestComments from "./requestComments";
import filesForSigners from "./filesForSigners";
import servicesRepairs from "./servicesRepairs";
import problemsCategories from "./problemsCategories";
import commonDisrepairs from "./commonDisrepairs";

const poesoStore = createStore({
    state: {
        showMainSpinner: false,
    },
    getters: {
        appDevMode: () => process.env.VUE_APP_MODE === "development",
        showMainSpinner: (state) => state.showMainSpinner,
    },
    actions: {
        async loadDataFromDB({ commit, dispatch }) {
            commit("setMainSpinnerState", true);

            const pA = dispatch("getRegionsFromDB");
            const pB = dispatch("getOrganizationsFromDB");

            Promise
                .all([pA, pB])
                .finally(() => {
                    const p01 = dispatch("getUnitsFromDB");
                    const p02 = dispatch("getOrgKindsFromDB");
                    const p03 = dispatch("getPostsFromDB");
                    const p04 = dispatch("getProgramsFromDB");
                    const p05 = dispatch("getProgramsSubsystemsFromDB");
                    const p06 = dispatch("getProblemsCategoriesFromDB");
                    const p07 = dispatch("getUsersFromDB");
                    const p08 = dispatch("getRolesListFromDB");
                    const p09 = dispatch("getUsersRolesFromDB");
                    const p10 = dispatch("getUsersUnitsFromDB");
                    const p11 = dispatch("getServicesRepairsFromDB");
                    const p12 = dispatch("getManufacturersFromDB");
                    const p13 = dispatch("getToolsKindsFromDB");
                    const p14 = dispatch("getToolsKindsTraitsFromDB");
                    const p15 = dispatch("getToolsModelsFromDB");
                    const p16 = dispatch("getToolsSetsNamesFromDB");
                    const p17 = dispatch("getToolsSetsFromDB");
                    const p18 = dispatch("getSparesSetsFromDB");
                    const p19 = dispatch("getSparesSetsItemsFromDB");
                    const p20 = dispatch("getToolsAgeCountFromDB");
                    const p21 = dispatch("getRequestsInnerFromDB");
                    const p22 = dispatch("getCommonDisrepairsFromDB");

                    Promise
                        .all([
                            p01, p02, p03, p04, p05, p06, p07, p08, p09, p10,
                            p11, p12, p13, p14, p15, p16, p17, p18, p19, p20,
                            p21, p22,
                        ])
                        .finally(() => {
                            commit("setMainSpinnerState", false);
                        });
                });
        },
    },
    mutations: {
        setMainSpinnerState(state, spinnerState) {
            state.showMainSpinner = spinnerState;
        },
    },
    modules: {
        auth,
        usersRolesUnits,
        users,
        organizations,
        posts,
        regions,
        manufacturers,
        tools,
        toolsKinds,
        toolsKindsTraits,
        toolsModels,
        toolsSets,
        sparesSets,
        roles,
        requests,
        pagination,
        programs,
        requestsInner,
        requestDocs,
        requestSpares,
        requestWorks,
        requestComments,
        filesForSigners,
        servicesRepairs,
        problemsCategories,
        commonDisrepairs,
    },
});

// Add a request interceptor
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
    (config) => {
        // Do something before request is sent
        // console.log("AXIOS REQUEST INTERCEPTOR");
        config.headers.userId = poesoStore.getters.currentUser.id;
        config.headers.accessToken = poesoStore.getters.currentUser.accessToken;
        return config;
    },
    (error) => {
        // Do something with request error
        console.log("AXIOS ERROR");
        return Promise.reject(error);
    },
);

axios.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        // console.log(response.headers.freshtoken);

        // console.log(response);
        if (!response.config.url.includes("public/files")) {
            poesoStore.dispatch("setUserToken", response.headers.freshtoken);
        }

        return response;
    },
    (error) => {
        // console.log(error.response);
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        console.log(error);

        if (!error.response) {
            Notify.create({
                type: "notify-failure",
                message: "Ошибка сети. Перегрузите страницу",
            });
        }
        else if ([404, 409].includes(error.response.status)) {
            //
        }
        else {
            poesoStore.dispatch("logout");
            // router.push({ name: "Home" });
            if (window.location.pathname !== "/login") {
                window.location.assign("/");
            }
        }
        return Promise.reject(error);
    },
);

export default poesoStore;
