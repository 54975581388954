import axios from "axios";
import { siteHost } from "@/store/constants";

export function getSingleRequest(requestId) {
    return new Promise((resolve, reject) => {
        axios.get(`${siteHost}/api/request_single/${requestId}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
