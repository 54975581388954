import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        commonDisrepairs: [],
    },
    getters: {
        commonDisrepairs: (state) => state.commonDisrepairs,
    },
    actions: {
        getCommonDisrepairsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/common_disrepairs`)
                    .then((res) => {
                        // console.log(res.data.commonDisrepairs);
                        commit("setCommonDisrepair", res.data.commonDisrepairs);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveCommonDisrepair({ dispatch }, disrepair) {
            const method = disrepair.id === null
                ? "post"
                : "put";
            const url = disrepair.id === null
                ? `${siteHost}/api/common_disrepairs`
                : `${siteHost}/api/common_disrepairs/${disrepair.id}`;

            return new Promise((resolve, reject) => {
                axios[method](
                    url,
                    {
                        disrepair,
                    },
                )
                    .then(() => {
                        dispatch("getCommonDisrepairsFromDB");
                        resolve("Сохранено");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        deleteCommonDisrepair({ dispatch }, commonDisrepairId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/common_disrepairs/${commonDisrepairId}`)
                    .then(() => {
                        dispatch("getCommonDisrepairsFromDB");
                        resolve("Удалено");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
    mutations: {
        setCommonDisrepair(state, disrepairs) {
            state.commonDisrepairs = disrepairs;
        },
    },
};
