<template>
    <div class="content">
        <q-input
            v-model="excelData"
            outlined
            dense
            type="textarea"
        />
        <q-btn
            filled
            label="Импортировать"
            color="primary"
            :icon="matSave"
            @click="doImport"
        />
        <div v-html="resultOrImport" />
    </div>
</template>

<script>
/* eslint-disable prefer-destructuring */
import { mapGetters } from "vuex";

export default {
    name: "UnitsImport",
    data() {
        return {
            excelData: "",
            resultOrImport: "",
        };
    },
    computed: {
        ...mapGetters({
            orgs: "orgs",
            getUnitsOfOrg: "getUnitsOfOrg",
        }),
    },
    methods: {
        async doImport() {
            let iOrgsExits = 0;
            const lines = this.excelData.split("\n");
            const aNewOrgs = [];

            for (let l = 0; l < lines.length; l++) {
                const line = lines[l];

                const fields = line.split("\t");

                if (fields.length <= 1) {
                    continue;
                }

                const vnCode = fields[0].trim();
                const isHost = [
                    "UD", "OS", "AJ", "KJ",
                    "AS", "AA", "AO", "IC",
                    "AV", "KV", "OV", "AI",
                ]
                    .reduce((a, c) => a || vnCode.includes(c), false);

                const aVnCodeToKind = [
                    { code: "UD", kindId: 3 }, // "Управление Судебного департамента"
                    { code: "OS", kindId: 6 }, // "Верховный суд республик..."
                    { code: "AJ", kindId: 5 }, // "Апелляционный суд общей юрисдикции"
                    { code: "KJ", kindId: 4 }, // "Кассационный суд общей юрисдикции"
                    { code: "AS", kindId: 11 }, // "Арбитражные суды субъектов Российской Федерации"
                    { code: "AA", kindId: 10 }, // "Арбитражные апелляционные суды"
                    { code: "AO", kindId: 8 }, // "Арбитражный суд округа"
                    { code: "IC", kindId: 17 }, // "Филиал ФГБУ ИАЦ Судебного департамента"
                    { code: "RS", kindId: 7 }, // "Районный(городской, межрайонный) суд общей юрисдикции"
                    { code: "AV", kindId: 14 }, // "Апелляционный военный суд"
                    { code: "GV", kindId: 15 }, // "Гарнизонный военный суд"
                    { code: "KV", kindId: 12 }, // "Кассационный военный суд"
                    { code: "OV", kindId: 13 }, // "Окружной (флотский) военный суд"
                    { code: "AI", kindId: 9 }, // "Суд по интеллектуальным правам"
                ];

                const vnCodeToKind = aVnCodeToKind.find((o) => vnCode.includes(o.code));
                const kindId = vnCodeToKind ? vnCodeToKind.kindId : null;

                const orgOld = this.orgs.find((o) => o.vncode === vnCode);
                if (orgOld) {
                    iOrgsExits++;
                }
                else {
                    const unitAddress = fields[3]
                        .replace("1-е зд.: ", "")
                        .replace("2-е зд.: ", "")
                        .replace("3-е зд.: ", "")
                        .replace("4-е зд.: ", "")
                        .replace("5-е зд.: ", "")
                        .replace("6-е зд.: ", "");

                    const org = aNewOrgs.find((o) => o.vncode === vnCode);

                    if (org) {
                        org.units.push(unitAddress);
                    }
                    else {
                        aNewOrgs.push({
                            region_id: +fields[1],
                            vncode: vnCode,
                            name: fields[2].trim(),
                            fullname: fields[2].trim(),
                            address: "",
                            mail_address: "",
                            phone: "",
                            is_host: isHost,
                            host_id: null,
                            kind_id: kindId,
                            assignment: "",
                            hot_line_email: "",
                            hot_line_password: "",
                            units: [unitAddress],
                        });
                    }
                }
            }

            for (let i = 0; i < aNewOrgs.length; i++) {
                await this.$store.dispatch("saveOrganizationTemp", aNewOrgs[i]);
            }

            this.excelData = "";
            this.resultOrImport = iOrgsExits;

            await this.$store.dispatch("getOrganizationsFromDB");
            this.$store.dispatch("getUnitsFromDB");
        },
    },
};
</script>
