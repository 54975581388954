import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        sparesSets: [],
        sparesSetsItems: [],
        sparesSetsItemsFilter: {
            codeExact: false,
            titleExact: false,
        },
    },
    getters: {
        sparesSets: (state) => state.sparesSets,
        sparesSetsItems: (state) => state.sparesSetsItems,
        sparesSetName: (state) => (sparesSetId) => {
            const sparesSet = state.sparesSets.find((ss) => ss.id === sparesSetId);
            return sparesSet
                ? sparesSet.title
                : "";
        },
        sparesSetRegionId: (state) => (sparesSetId) => {
            const sparesSet = state.sparesSets.find((ss) => ss.id === sparesSetId);
            return sparesSet
                ? sparesSet.region_id
                : "";
        },
        sparesSet: (state) => (sparesSetId) => {
            const sparesSet = state.sparesSets.find((ss) => ss.id === sparesSetId);
            return sparesSet
                ? {
                    ...sparesSet,
                    items: state.sparesSetsItems
                        .filter((ssi) => ssi.spares_set_id === sparesSetId)
                        .map((ssi) => ({ ...ssi })),
                }
                : {};
        },
        sparesSetsItemCost: (state) => (itemId) => {
            const item = state.sparesSetsItems.find((i) => i.id === itemId);
            return item ? item.cost : 0;
        },
        sparesSetsItemsFilter: (state) => state.sparesSetsItemsFilter,
    },
    actions: {
        getSparesSetsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/spares_sets`)
                    .then((res) => {
                        commit("setSparesSets", res.data.spares_sets);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getSparesSetsItemsFromDB({ commit, getters }) {
            return new Promise((resolve, reject) => {
                const filterS = JSON.stringify(getters.sparesSetsItemsFilter);
                axios.get(`${siteHost}/api/spares_sets_items/${filterS}`)
                    .then((res) => {
                        commit("setSparesSetsItems", res.data.spares_sets_items);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveSparesSet({ dispatch }, sparesSet) {
            const method = sparesSet.id
                ? "put"
                : "post";

            const url = sparesSet.id
                ? `${siteHost}/api/spares_sets/${sparesSet.id}`
                : `${siteHost}/api/spares_sets`;

            return new Promise((resolve, reject) => {
                axios[method](
                    url,
                    sparesSet,
                )
                    .then(() => {
                        dispatch("getSparesSetsFromDB");
                        dispatch("getSparesSetsItemsFromDB");
                        resolve("ЗИП сохранен");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        deleteSparesSetItem({ dispatch }, sparesSetItemId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/spares_sets/${sparesSetItemId}`)
                    .then(() => {
                        dispatch("getSparesSetsFromDB");
                        dispatch("getSparesSetsItemsFromDB");
                        resolve("Элемент комплекта оборудования удален");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        setSparesSetsItemsFilter({ commit, dispatch }, filter) {
            commit("setSparesSetsItemsFilter", filter);
            dispatch("getSparesSetsItemsFromDB");
        },
    },
    mutations: {
        setSparesSets(state, sparesSets) {
            state.sparesSets = sparesSets;
        },
        setSparesSetsItems(state, sparesSetsItems) {
            state.sparesSetsItems = sparesSetsItems;
        },
        setSparesSetsItemsFilter(state, filter) {
            state.sparesSetsItemsFilter = filter;
        },
    },
};
