<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Документы
            <q-space />
            <SelectFilter
                v-model="signerIac"
                :options="signersIac"
                label="Подписант от филиала"
                clearable
            />
            <SelectFilter
                v-model="signerUnit"
                :options="signersUnit"
                label="Подписант от объекта"
                clearable
            />
            <SelectFilter
                v-model="signerFinal"
                :options="signersFinal"
                label="Подписант от УСД"
                clearable
            />
            <q-toggle
                v-model="showNotSignedOnly"
                style="font-size: 1rem;"
                label="Только не подписанные документы"
            />
        </div>

        <div class="documents-list-grid">
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Номер заявки
            </div>
            <div class="cell cell-header cell-center">
                Документ
            </div>
            <div class="cell cell-header cell-center">
                Подписант от филиала
            </div>
            <div class="cell cell-header cell-center">
                Подписант от объекта
            </div>
            <div class="cell cell-header cell-center">
                Подписант от УСД
            </div>

            <DocumentsListRow
                v-for="(doc, index) in filesForList"
                :key="index"
                :doc="doc"
                :docNumber="index + 1"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import DocumentsListRow from "./DocumentsListRow.vue";

export default {
    name: "DocumentsList",
    components: {
        SelectFilter,
        DocumentsListRow,
    },
    data() {
        return {
            signerIac: null,
            signerUnit: null,
            signerFinal: null,
            showNotSignedOnly: true,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            filesForSigner: "filesForSigner",
            getUserFIO: "getUserFIO",
        }),
        filesForList() {
            return this.filesForSigner
                .filter((doc) => {
                    if (!this.showNotSignedOnly) {
                        return true;
                    }

                    const docSignIac = doc.request_file_signers.find((ds) => ds.signer_kind === "iac");
                    if (!docSignIac && doc.signer_iac_id === this.currentUser.id) {
                        return true;
                    }

                    const docSignUnt = doc.request_file_signers.find((ds) => ds.signer_kind === "unit");
                    if (docSignIac && !docSignUnt && doc.signer_unit_id === this.currentUser.id) {
                        return true;
                    }

                    const docSignFnl = doc.request_file_signers.find((ds) => ds.signer_kind === "final");
                    if (docSignUnt && !docSignFnl && doc.signer_final_id === this.currentUser.id) {
                        return true;
                    }

                    return false;
                })
                .filter((doc) => (
                    this.signerIac
                        ? doc.signer_iac_id === this.signerIac
                        : true
                ))
                .filter((doc) => (
                    this.signerUnit
                        ? doc.signer_unit_id === this.signerUnit
                        : true
                ))
                .filter((doc) => (
                    this.signerFinal
                        ? doc.signer_final_id === this.signerFinal
                        : true
                ));
        },
        filesForListTest() {
            const retVal = [];

            for (let i = 0; i < 10; i++) {
                retVal.push({
                    request_number: i + 1,
                    file_name: "AAAA",
                    path: "BBBB",
                    signer_iac_id: 141,
                    signer_iac_sign_id: 141,
                    signer_unit_id: 141,
                    signer_unit_sign_id: 141,
                    signer_final_id: 141,
                    request_file_signers: [
                        {
                            signer_kind: "iac",
                        },
                    ],
                });
            }
            return retVal;
        },
        signersIac() {
            const signersIds = Array.from(new Set(this.filesForSigner.map((e) => e.signer_iac_id)));
            return signersIds.map((e) => ({
                id: e,
                title: `${this.getUserFIO(e)}`,
            }));
        },
        signersUnit() {
            const signersIds = Array.from(new Set(this.filesForSigner.map((e) => e.signer_unit_id)));
            return signersIds.map((e) => ({
                id: e,
                title: `${this.getUserFIO(e)}`,
            }));
        },
        signersFinal() {
            const signersIds = Array.from(new Set(this.filesForSigner.map((e) => e.signer_final_id)));
            return signersIds
                .map((e) => ({
                    id: e,
                    title: `${this.getUserFIO(e)}`,
                }))
                .filter((e) => e.id);
        },
    },
    mounted() {
        this.$store.dispatch("getFilesForSignersFromDB");
    },
};
</script>
