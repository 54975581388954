<template>
    <div class="cell cell__center cell--header">
        {{ role.title }}
        <q-menu
            touchPosition
            contextMenu
        >
            <q-list
                dense
                style="min-width: 100px"
            >
                <q-item
                    v-close-popup
                    clickable
                    @click="editRole"
                >
                    <q-item-section>
                        Изменить
                    </q-item-section>
                </q-item>
                <q-item
                    v-close-popup
                    clickable
                    @click="deleteRole"
                >
                    <q-item-section>
                        Удалить
                    </q-item-section>
                </q-item>
            </q-list>
        </q-menu>
    </div>
    <q-select
        :modelValue="role.access_level"
        :options="accessLevels"
        mapOptions
        emitValue
        borderless
        dense
        class="cell"
        @update:modelValue="(al) => setAccessLevel(role.id, al)"
    />
    <div
        v-for="(right, indexR) in RIGHTS"
        :key="indexR"
        :class="[
            'cell',
            hoverRoleIndex === roleIndex || hoverRightIndex === indexR ? 'cell--highlight' : '',
        ]"
        @mouseover="onMouseOver(indexR)"
    >
        <div
            v-if="right.right.length == 1"
            :class="[
                'cell--checkbox',
                isDataSending ? 'cell--waiting' : '',
            ]"
            @click="() => triggerRoleRight(
                role.id,
                right.key,
                !role.rightsObject[right.key] ? right.right[0] : null,
            )"
        >
            <q-icon
                v-if="!!role.rightsObject[right.key]"
                :name="matCheckBox"
                color="primary"
                size="1.4rem"
            />
            <q-icon
                v-else
                :name="matCheckBoxOutlineBlank"
                color="blue-grey-4"
                size="1.4rem"
            />
        </div>
        <div
            v-else
            :class="[
                'cell--radiogroup',
                isDataSending ? 'cell--waiting' : '',
            ]"
        >
            <div
                v-for="(rv, iR) in [null, ...right.right]"
                :key="iR"
                style="text-wrap: nowrap;"
                @click="triggerRoleRight(
                    role.id,
                    right.key,
                    rv,
                )"
            >
                <input
                    type="radio"
                    :name="`${roleIndex}_${right.key}`"
                    :value="null"
                    :checked="role.rightsObject[right.key] == rv"
                >
                {{ getLabel(rv) }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RIGHTS_CONSTANTS, RIGHTS } from "@/store/constants";
import DialogEditRole from "@/components/Dialogs/DialogEditRole.vue";

export default {
    name: "RolesRightsRole",
    props: {
        role: {
            type: Object,
            required: true,
        },
        accessLevels: {
            type: Array,
            required: true,
        },
        roleIndex: {
            type: Number,
            required: true,
        },
    },
    setup() {
        return {
            RIGHTS,
        };
    },
    data() {
        return {
            tempCheckBoxValue: false,
            isDataSending: false,
        };
    },
    computed: {
        ...mapGetters({
            hoverRoleIndex: "hoverRoleIndex",
            hoverRightIndex: "hoverRightIndex",
        }),
    },
    mounted() {
        // console.log(RIGHTS);
        // console.log(this.role);
    },
    methods: {
        setAccessLevel(roleId, value) {
            this.$store.dispatch(
                "setRoleAccessLevel",
                {
                    id: roleId,
                    value,
                },
            );
        },
        getLabel(r) {
            if (r === RIGHTS_CONSTANTS.READ) {
                return "Чтение";
            }
            if (r === RIGHTS_CONSTANTS.FULL) {
                return "Чтение и запись";
            }
            if (r === RIGHTS_CONSTANTS.OWN) {
                return "Свои объекты";
            }
            if (r === RIGHTS_CONSTANTS.ALL) {
                return "Все объекты";
            }
            return "Нет";
        },
        editRole() {
            this.$q.dialog({
                component: DialogEditRole,
                componentProps: {
                    itemForEdit: { ...this.role },
                },
            })
                .onOk((roleEdited) => {
                    this.$store.dispatch(
                        "updateRole",
                        roleEdited,
                    )
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
        deleteRole() {
            this.$store.dispatch(
                "deleteRole",
                this.role.id,
            )
                .then((message) => {
                    this.$q.notify({
                        type: "notify-success",
                        message,
                    });
                })
                .catch((err) => {
                    this.$q.notify({
                        type: "notify-failure",
                        message: err,
                    });
                });
        },
        async triggerRoleRight(roleId, rightKey, value) {
            if (this.isDataSending) {
                return;
            }

            this.isDataSending = true;
            await this.$store.dispatch(
                "setRoleRights",
                {
                    id: roleId,
                    key: rightKey,
                    value,
                },
            );
            this.isDataSending = false;
        },
        onMouseOver(rightIndex) {
            this.$store.dispatch(
                "setHovers",
                {
                    rightIndex,
                    roleIndex: this.roleIndex,
                },
            );
        },
    },
};
</script>
