<template>
    <Moving_2_Result :canBeEdited="canBeEdited" />
</template>

<script>
import Moving_2_Result from "./Moving_2_Result.vue";

export default {
    name: "DialogEditRequestMovingConsultation",
    components: {
        Moving_2_Result,
    },
    props: {
        canBeEdited: {
            type: Boolean,
            required: true,
        },
    },
};
</script>
