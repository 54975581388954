export function tc(dirKind, tcPr, pAlign, value, width) {
    const tcPrF = tcPr || width
        ? `<w:tcPr>
            ${tcPr ? `<w:${dirKind} w:val="${tcPr}"/>` : ""}
            ${width ? `<w:tcW w:w="${width}" w:type="dxa" />` : ""}
            </w:tcPr>`
        : "";
    const pAlignF = pAlign
        ? `<w:pPr>
            <w:jc w:val="${pAlign}"/>
        </w:pPr>`
        : "";

    const valueM = `${value}`
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&apost;")
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");

    return `<w:tc>${tcPrF}
        <w:p>${pAlignF}
            <w:r><w:t>${valueM}</w:t></w:r>
        </w:p>
    </w:tc>`;
}

export function tCellP(
    value,
    {
        dirSpan = null,
        tcPr = null,
        vAlign = null,
        pAlign = null,
        pPrBold = false,
        width = null,
        dirTxt = null,
        bgColor = null,
        fontSize = null,
    },
) {
    const content = `${value}`
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&apost;")
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");

    const tcPrF = tcPr || width || dirTxt || bgColor || vAlign
        ? ["<w:tcPr>",
            tcPr ? `<w:${dirSpan} w:val="${tcPr}"/>` : "",
            width ? `<w:tcW w:w="${width}" w:type="dxa" />` : "",
            dirTxt ? `<w:textDirection w:val="${dirTxt}" />` : "",
            bgColor ? `<w:shd w:fill="${bgColor}" />` : "",
            vAlign ? `<w:vAlign w:val="${vAlign}" />` : "",
            "</w:tcPr>"].join("")
        : "";

    // paragraph align
    const pAlignF = pAlign
        ? ["<w:pPr>",
            `<w:jc w:val="${pAlign}" />`,
            "</w:pPr>"].join("")
        : "";

    const rPrBoldF = pPrBold || fontSize
        ? [
            "<w:rPr>",
            pPrBold ? "<w:b w:val=\"true\"/>" : "",
            fontSize ? `<w:sz w:val="${fontSize}" />` : "",
            "</w:rPr>",
        ].join("")
        : "";

    return `<w:tc>${tcPrF}
            <w:p>${pAlignF}
                <w:r>${rPrBoldF}<w:t>${content}</w:t></w:r>
            </w:p>
        </w:tc>`;
}
