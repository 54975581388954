export default function (property) {
    return {
        get() {
            return this.request[property];
        },
        set(value) {
            this.$store.dispatch(
                "setRequestField",
                {
                    field: property,
                    value,
                },
            );
        },
    };
}
