<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 80vw; max-width: 80vw;">
            <q-card-section>
                <div class="content-title">
                    Поиск ЗИП
                    <q-space />
                    <FilterSparesSetsItems />
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh; min-height: 70vh"
                class="scroll"
            >
                <div class="spares-list">
                    <div class="spares-list-header">
                        ЗИП
                    </div>
                    <div class="spares-list-header">
                        Код
                    </div>
                    <div class="spares-list-header">
                        Наименование
                    </div>
                    <div class="spares-list-header">
                        Стоимость
                    </div>
                    <div class="spares-list-header">
                        Количество
                    </div>
                    <div class="spares-list-header" />

                    <template
                        v-for="(sparesSetItem, index) in itemsForChoose"
                        :key="index"
                    >
                        <div class="spares-list-data">
                            {{ sparesSetName(sparesSetItem.spares_set_id) }}
                        </div>
                        <div class="spares-list-data">
                            {{ sparesSetItem.code }}
                        </div>
                        <div class="spares-list-data">
                            {{ sparesSetItem.title }}
                        </div>
                        <div class="spares-list-data">
                            {{ sparesSetItem.cost }}
                        </div>
                        <div class="spares-list-data">
                            {{ sparesSetItem.amount - +sparesSetItem.spent }}
                        </div>
                        <div class="spares-list-data">
                            <q-btn
                                round
                                :icon="matCheck"
                                color="secondary"
                                size="sm"
                                @click="onOkClick(sparesSetItem)"
                            />
                        </div>
                    </template>
                </div>
                <!--
                <div v-if="sparesSetsItems.length > 10">
                    Показаны первые 10 элементов, используйте фильтр для уменьшения
                    количества элементов.
                </div>
                -->
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import FilterSparesSetsItems from "@/components/Filters/FilterSparesSetsItems.vue";

export default {
    name: "DialogSearchSpare",
    components: {
        FilterSparesSetsItems,
    },
    emits: ["ok"],
    computed: {
        ...mapGetters({
            sparesSetsItems: "sparesSetsItems",
            sparesSetName: "sparesSetName",
        }),
        itemsForChoose() {
            return this.sparesSetsItems
                .filter((item) => item.amount - +item.spent !== 0);
            // return this.sparesSetsItems.slice(0, 10);
        },
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick(sparesSetItem) {
            const retVal = {
                ...sparesSetItem,
                spare_id: sparesSetItem.id,
                amount: 1,
            };
            delete retVal.id;
            this.$emit("ok", retVal);

            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
