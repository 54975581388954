<template>
    <div class="toolbar">
        <q-btn
            round
            :icon="outlinedHome"
            unelevated
            @click="() => goToPath('/')"
        />

        <q-btn
            v-for="(list, index) in menuListsFiltered"
            :key="index"
            :label="list.title"
            unelevated
            @click="() => list.path && list.path.startsWith('/') ? goToPath(list.path) : null"
        >
            <q-menu
                autoClose
                fit
            >
                <q-list style="min-width: 100px">
                    <template v-for="(item, itemInd) in list.items">
                        <q-separator
                            v-if="item.title === 'separator'"
                            :key="`s_${itemInd}`"
                        />
                        <q-item
                            v-else-if="item.method"
                            :key="`m_${itemInd}`"
                            :disable="item.disable"
                            clickable
                            @click="() => this[`${item.method}`]()"
                        >
                            <q-item-section>{{ item.title }}</q-item-section>
                        </q-item>
                        <q-item
                            v-else
                            :key="`i_${itemInd}`"
                            :clickable="item.path !== ''"
                            :disable="item.path === '' || item.disable"
                            @click="() => goToPath(item.path)"
                        >
                            <q-item-section>{{ item.title }}</q-item-section>
                        </q-item>
                    </template>
                </q-list>
            </q-menu>
        </q-btn>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import DialogEditRequest from "@/components/Dialogs/DialogEditRequest/DialogEditRequest.vue";
import DialogCreateMultipleRequests from "@/components/Dialogs/DialogCreateMultipleRequests.vue";
import DialogSystemRequest from "@/components/Dialogs/DialogSystemRequest.vue";
import DialogCreateFormular from "@/components/Dialogs/DialogCreateFormular.vue";
import DialogCreateEnclosure1 from "@/components/Dialogs/DialogCreateEnclosure1.vue";
import DialogCreateEnclosure2 from "@/components/Dialogs/DialogCreateEnclosure2.vue";
import DialogCreateRequestsArchive from "@/components/Dialogs/DialogCreateRequestsArchive.vue";
import DialogCreateRepairReport1 from "@/components/Dialogs/DialogCreateRepairReport1.vue";
import DialogCreateRepairReport2 from "@/components/Dialogs/DialogCreateRepairReport2.vue";
import DialogCreateRepairReport3 from "@/components/Dialogs/DialogCreateRepairReport3.vue";
import DialogCreateReportWaitingRepair from "@/components/Dialogs/DialogCreateReportWaitingRepair.vue";
import DialogCreateRepairShortTable from "@/components/Dialogs/DialogCreateRepairShortTable.vue";
import DialogCreateFailureReportForm from "@/components/Dialogs/DialogCreateFailureReportForm.vue";
import DialogCreateSubsystemReport from "@/components/Dialogs/DialogCreateSubsystemReport.vue";
// import DialogImportFromExcel from "@/components/Dialogs/DialogImportFromExcel.vue";
import DialogCheckToolsModels from "@/components/Dialogs/DialogCheckToolsModels.vue";
import DialogEditTool from "@/components/Dialogs/DialogEditTool/DialogEditTool.vue";
import DialogCheckTools from "@/components/Dialogs/DialogCheckTools.vue";
import DialogParseXlsx from "@/components/Dialogs/DialogParseXlsx.vue";
import { pathsAndRights } from "@/store/pathsAndRights";

export default {
    name: "MainMenu",
    data() {
        return {
            menuLists: [
                {
                    title: "Техническая поддержка",
                    items: [
                        {
                            title: "Новая заявка",
                            method: "createRequest",
                            path: "menuItemCreateRequest",
                        },
                        {
                            title: "Создание группы заявок",
                            method: "createMultipleRequests",
                            path: "menuItemСreateMultipleRequests",
                        },
                        { title: "separator" },
                        {
                            title: "Заявки",
                            path: "/requests",
                        },
                        {
                            title: "Заявки головного исполнителя",
                            path: "/requests-hot-line",
                        },
                        { title: "separator" },
                        {
                            title: "Создать запрос администратору Системы",
                            method: "createSystemRequest",
                        },
                    ],
                },
                {
                    title: "Документы на подпись",
                    path: "/documents-list",
                },
                {
                    title: "Оборудование",
                    path: "menuListTools",
                    items: [
                        {
                            title: "Добавить оборудование",
                            method: "createTool",
                            path: "menuItemCreateTool",
                        },
                        { title: "separator" },
                        {
                            title: "Список оборудования по типам",
                            path: "/tools-list",
                        },
                        {
                            title: "Общий список оборудования",
                            path: "/tools",
                        },
                        /*
                        { title: "separator" },
                        { title: "Комплекты оборудования", path: "/tools-sets" },
                        */
                        { title: "separator" },
                        {
                            title: "Комплекты ЗИП",
                            path: "/spares",
                        },
                    ],
                },
                {
                    title: "Отчеты",
                    path: "menuListReports",
                    items: [
                        {
                            title: "Табличная часть формуляра",
                            method: "createFormular",
                        },
                        {
                            title: "Приложение №1",
                            method: "createEnclosure1",
                        },
                        {
                            title: "Приложение №2",
                            method: "createEnclosure2",
                        },
                        {
                            title: "Журнал учета заявок в архив",
                            method: "createRequestsArchive",
                        },
                        {
                            title: "Отчет по ремонтам №1",
                            method: "createRepairReport1",
                        },
                        {
                            title: "Отчет по ремонтам №2",
                            method: "createRepairReport2",
                        },
                        {
                            title: "Отчет по ремонтам №3",
                            method: "createRepairReport3",
                        },
                        {
                            title: "Отчет о количестве единиц ПТС ожидающих ремонта",
                            method: "createReportWaitingRepair",
                        },
                        {
                            title: "Краткая таблица по ремонтам ПТС",
                            method: "createRepairShortTable",
                        },
                        {
                            title: "Форма отчета по отказам ПТС",
                            method: "createFailureReportForm",
                        },
                        {
                            title: "Отчет по подсистемам ГАС «Правосудие»",
                            method: "createSubsystemReport",
                        },
                    ],
                },
                {
                    title: "Справочники",
                    path: "menuListReferences",
                    items: [
                        {
                            title: "Регионы",
                            path: "/regions",
                        },
                        {
                            title: "Балансодержатели",
                            path: "/hosts",
                        },
                        {
                            title: "Организации",
                            path: "/organizations",
                        },
                        {
                            title: "Типы организаций",
                            path: "/organization-kinds",
                        },
                        { title: "separator" },
                        {
                            title: "Модели учетных единиц",
                            path: "/tools-models",
                        },
                        {
                            title: "Виды учетных единиц",
                            path: "/tools-kinds",
                        },
                        {
                            title: "Характеристики видов учетных единиц",
                            path: "/tools-kinds-traits",
                        },
                        {
                            title: "Виды комплектов учетных единиц",
                            path: "/tools-sets-names",
                        },
                        {
                            title: "Производители оборудования",
                            path: "/manufacturers",
                        },
                        { title: "separator" },
                        {
                            title: "Подсистемы ГАС 'Правосудие'",
                            path: "/programs-subsystems",
                        },
                        {
                            title: "Программное обеспечение",
                            path: "/programs",
                        },
                        {
                            title: "Неисправности ПТС",
                            path: "/common-disrepairs",
                        },
                        {
                            title: "Категории проблем",
                            path: "/problems-categories",
                        },
                        { title: "separator" },
                        {
                            title: "Учетные записи",
                            path: "/users",
                        },
                        {
                            title: "Должности",
                            path: "/posts",
                        },
                        {
                            title: "Исполнители Техподдержки ",
                            path: "/users-hotline",
                        },
                        {
                            title: "Сервисные центры",
                            path: "/services-repairs",
                        },
                    ],
                },
                {
                    title: "Помощь",
                    items: [
                        // { title: "Краткая инструкция по использованию", path: "" },
                        // { title: "Справка по ПИ 'Сервисное обслуживание'", path: "" },
                        {
                            title: "О программе",
                            path: "/about",
                        },
                    ],
                },
                {
                    title: "Администрирование",
                    path: "menuListManagement",
                    items: [
                        {
                            title: "Роли и права",
                            path: "/roles",
                        },
                        {
                            title: "Запросы администратору Системы",
                            path: "/request-inner",
                        },
                        { title: "separator" },
                        {
                            title: "Проверка моделей учетных единиц",
                            method: "checkToolsModels",
                        },
                        {
                            title: "Проверка учетных единиц",
                            method: "checkTools",
                        },
                        { title: "separator" },
                        {
                            title: "Подсчет оборудования",
                            method: "calcToolsFromXlsxData",
                        },
                        // { title: "Импорт заявок", path: "/requests-import" },
                        // { title: "separator" },
                        /*
                        {
                            title: "Импорт оборудования из Excel",
                            method: "importFromExcel",
                        },
                        */
                    ],
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
        }),
        menuListsFiltered() {
            return this.menuLists
                .filter((m) => this.checkPathAndRights(m))
                .map((m) => (!m.items
                    ? m
                    : {
                        ...m,
                        items: m.items.filter((item) => this.checkPathAndRights(item)),
                    }
                ));
        },
    },
    methods: {
        checkPathAndRights(menuItem) {
            if (!menuItem.path && !menuItem.method) {
                return true;
            }

            const pathRights = pathsAndRights.find((par) => (
                par.path === menuItem.path ||
                par.path === menuItem.method
            ));

            if (!pathRights) {
                return true;
            }

            const allow = pathRights.rightsA
                ? pathRights.rightsA.reduce(
                    (a, r) => (
                        a || !!(this.currentUser.rightsObject[r.rightName] & r.rightValue)
                    ),
                    false,
                )
                : true;

            return allow;
        },
        goToPath(path) {
            if (this.$route.path !== path) {
                this.$router.push(path);
            }
        },
        createRequest() {
            this.$store.dispatch("setRequest", {})
                .then(() => {
                    this.$q.dialog({
                        component: DialogEditRequest,
                    });
                });
        },
        createMultipleRequests() {
            this.$q.dialog({
                component: DialogCreateMultipleRequests,
            });
        },
        createSystemRequest() {
            this.$q.dialog({
                component: DialogSystemRequest,
            });
        },
        createFormular() {
            this.$q.dialog({
                component: DialogCreateFormular,
            });
        },
        createEnclosure1() {
            this.$q.dialog({
                component: DialogCreateEnclosure1,
            });
        },
        createEnclosure2() {
            this.$q.dialog({
                component: DialogCreateEnclosure2,
            });
        },
        createRequestsArchive() {
            this.$q.dialog({
                component: DialogCreateRequestsArchive,
            });
        },
        createRepairReport1() {
            this.$q.dialog({
                component: DialogCreateRepairReport1,
            });
        },
        createRepairReport2() {
            this.$q.dialog({
                component: DialogCreateRepairReport2,
            });
        },
        createRepairReport3() {
            this.$q.dialog({
                component: DialogCreateRepairReport3,
            });
        },
        createReportWaitingRepair() {
            this.$q.dialog({
                component: DialogCreateReportWaitingRepair,
            });
        },
        createRepairShortTable() {
            this.$q.dialog({
                component: DialogCreateRepairShortTable,
            });
        },
        createFailureReportForm() {
            this.$q.dialog({
                component: DialogCreateFailureReportForm,
            });
        },
        createSubsystemReport() {
            this.$q.dialog({
                component: DialogCreateSubsystemReport,
            });
        },
        /*
        importFromExcel() {
            this.$q.dialog({
                component: DialogImportFromExcel,
            });
        },
        */
        createTool() {
            this.$q.dialog({
                component: DialogEditTool,
                componentProps: {
                    itemForEdit: {},
                },
            })
                .onOk(({ tool, filesList, filesListPictures }) => {
                    this.$store.dispatch(
                        "saveTool",
                        {
                            tool,
                            filesList,
                            filesListPictures,
                        },
                    );
                });
        },
        checkToolsModels() {
            this.$q.dialog({
                component: DialogCheckToolsModels,
            });
        },
        checkTools() {
            this.$q.dialog({
                component: DialogCheckTools,
            });
        },
        calcToolsFromXlsxData() {
            this.$q.dialog({
                component: DialogParseXlsx,
            });
        },
    },
};
</script>
