<template>
    <div class="cell cell-center">
        {{ index + 1 }}
    </div>
    <div class="cell">
        <q-input
            v-model="title"
            borderless
            dense
            :disable="!canBeEdited"
        />
    </div>
    <div
        v-if="allowViewCost"
        class="cell"
    >
        <q-input
            v-model="cost"
            borderless
            dense
            type="number"
            :disable="!canBeEdited"
        />
    </div>
    <div class="cell cell-center">
        <q-btn
            round
            :icon="matDelete"
            size="sm"
            class="poeso-table--control-button"
            :disable="!canBeEdited"
            @click="() => removeWork(index)"
        />
    </div>
</template>

<script>
export default {
    name: "RequestWorkRow",
    props: {
        work: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        canBeEdited: {
            type: Boolean,
            required: true,
        },
        allowViewCost: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        title: {
            get() {
                return this.work.title;
            },
            set(value) {
                this.$store.dispatch(
                    "setRequestWork",
                    {
                        index: this.index,
                        work: {
                            ...this.work,
                            title: value,
                        },
                    },
                );
            },
        },
        cost: {
            get() {
                return this.work.cost;
            },
            set(value) {
                this.$store.dispatch(
                    "setRequestWork",
                    {
                        index: this.index,
                        work: {
                            ...this.work,
                            cost: value,
                        },
                    },
                );
            },
        },
    },
    methods: {
        removeWork(index) {
            this.$store.dispatch("delRequestWork", index);
        },
    },
};
</script>
