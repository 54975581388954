<template>
    <div>
        <Documents
            fileSection="requestDocs"
            :filesList="requestDocs"
            :addDocToList="addDoc"
            :deleteDoc="delDoc"
            :readonly="disallowDelDocs || requestCompleted"
            doWorkWithSigns
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Documents from "@/components/Documents/Documents.vue";
import { RIGHTS_CONSTANTS } from "@/store/constants";

export default {
    name: "DialogEditRequestDocuments",
    components: {
        Documents,
    },
    props: {
        requestCompleted: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            requestDocs: "requestDocs",
        }),
        disallowDelDocs() {
            return !(this.currentUser.rightsObject.RIGHT_REQUEST_DOCS_DELETE & RIGHTS_CONSTANTS.FULL);
        },
    },
    methods: {
        addDoc(file) {
            this.$store.dispatch("addRequestDoc", file);
        },
        delDoc(index) {
            this.$store.dispatch("delRequestDoc", index);
        },
    },
};
</script>
