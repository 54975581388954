<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Комплекты оборудования
        </div>
        <div class="content-body tools-sets-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Вид комплекта
            </div>
            <div class="cell cell-header cell-center">
                Наименование комплекта
            </div>
            <div class="cell cell-header cell-center">
                <q-btn
                    round
                    :icon="matAdd"
                    size="0.7rem"
                    color="primary"
                    @click="editItem(null)"
                />
            </div>
            <!-- data -->
            <div
                v-for="(toolsSet, index) in toolsSets"
                :key="index"
                class="row-wrapper"
                @click="editItem(index)"
            >
                <div class="cell cell-center">
                    {{ index + 1 }}
                </div>
                <div class="cell">
                    {{ toolsSetName(toolsSet.type_id) }}
                </div>
                <div class="cell">
                    {{ toolsSet.name }}
                </div>
                <div class="cell cell-center">
                    <q-btn
                        round
                        :icon="matDelete"
                        size="0.7rem"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogToolsSetEdit from "@/components/Dialogs/DialogToolsSetEdit.vue";

export default {
    name: "ToolsSets",
    computed: {
        ...mapGetters({
            toolsSets: "toolsSets",
            toolsSetName: "toolsSetName",
        }),
    },
    methods: {
        editItem(index) {
            this.$q.dialog({
                component: DialogToolsSetEdit,
                componentProps: {
                    dialogTitle: `${index
                        ? "Редактирование комплекта оборудования"
                        : "Создание комплекта оборудования"}`,
                    itemForEdit: `${index === null ? "" : this.toolsSets[index].name}`,
                },
            })
                .onOk((toolsSet) => {
                    this.$store.dispatch("saveToolsSet", toolsSet)
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
    },
};
</script>
