import { createRouter, createWebHistory } from "vue-router";

import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";
import Organizations from "@/views/Organizations.vue";
import OrganizationsKinds from "@/views/OrganizationsKinds.vue";
import Users from "@/views/Users.vue";
import UsersHotline from "@/views/UsersHotline.vue";
import Tools from "@/views/Tools.vue";
import ToolsAgeCount from "@/views/ToolsAgeCount.vue";
import ToolsModels from "@/pages/ToolsModels/ToolsModels.vue";
import ToolsKinds from "@/views/ToolsKinds.vue";
import ToolsKindsTraits from "@/views/ToolsKindsTraits.vue";
import ToolsSetsNames from "@/views/ToolsSetsNames.vue";
import ToolsSets from "@/views/ToolsSets.vue";
import Spares from "@/views/Spares.vue";
import RolesRights from "@/pages/RolesRights/RolesRights.vue";
// import Roles from "@/views/Roles.vue";
import Requests from "@/views/Requests.vue";
import RequestsHotLine from "@/pages/RequestsHotLine/RequestsHotLine.vue";
import ProgramsSubsystems from "@/views/ProgramsSubsystems.vue";
import Programs from "@/views/Programs.vue";
import DocumentsList from "@/pages/DocumentsList/DocumentsList.vue";
import Manufacturers from "@/views/Manufacturers.vue";
import RequestInner from "@/views/RequestInner.vue";
import Posts from "@/views/Posts.vue";
import ServicesRepairs from "@/views/ServicesRepairs.vue";
import Regions from "@/views/Regions.vue";
import ProblemsCategories from "@/views/ProblemsCategories.vue";
import CommonDisrepairs from "@/pages/references/CommonDisrepairs.vue";

// import RequestsImport from "@/pages/RequestsImport.vue";
import UnitsImport from "@/pages/helperPages/UnitsImport.vue";

import { pathsAndRights } from "@/store/pathsAndRights";
import store from "../store";

const routes = [
    // common
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    // requests
    {
        path: "/requests",
        name: "Requests",
        component: Requests,
    },
    {
        path: "/requests-hot-line",
        name: "RequestsHotLine",
        component: RequestsHotLine,
    },
    // documents list
    {
        path: "/documents-list",
        name: "DocumentsList",
        component: DocumentsList,
    },
    // tools
    {
        path: "/tools-list",
        name: "ToolsAgeCount",
        component: ToolsAgeCount,
    },
    {
        path: "/tools",
        name: "Tools",
        component: Tools,
    },
    {
        path: "/spares",
        name: "Spares",
        component: Spares,
    },
    // references
    {
        path: "/regions",
        name: "Regions",
        component: Regions,
    },
    {
        path: "/hosts",
        name: "Hosts",
        component: Organizations,
        props: {
            isHost: true,
            title: "Балансодержатели",
        },
    },
    {
        path: "/organizations",
        name: "Organizations",
        component: Organizations,
        props: {
            isHost: false,
            title: "Организации",
        },
    },
    {
        path: "/organization-kinds",
        name: "OrganizationsKinds",
        component: OrganizationsKinds,
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
    },
    {
        path: "/users-hotline",
        name: "UsersHotline",
        component: UsersHotline,
    },
    {
        path: "/tools-models",
        name: "ToolsModels",
        component: ToolsModels,
    },
    {
        path: "/tools-kinds",
        name: "ToolsKinds",
        component: ToolsKinds,
    },
    {
        path: "/tools-kinds-traits",
        name: "ToolsKindsTraits",
        component: ToolsKindsTraits,
    },
    {
        path: "/tools-sets",
        name: "ToolsSets",
        component: ToolsSets,
    },
    {
        path: "/tools-sets-names",
        name: "ToolsSetsNames",
        component: ToolsSetsNames,
    },
    {
        path: "/manufacturers",
        name: "Manufacturers",
        component: Manufacturers,
    },
    {
        path: "/programs-subsystems",
        name: "ProgramsSubsystems",
        component: ProgramsSubsystems,
    },
    {
        path: "/programs",
        name: "Programs",
        component: Programs,
    },
    {
        path: "/common-disrepairs",
        name: "CommonDisrepairs",
        component: CommonDisrepairs,
    },
    {
        path: "/problems-categories",
        name: "ProblemsCategories",
        component: ProblemsCategories,
    },
    {
        path: "/posts",
        name: "Posts",
        component: Posts,
    },
    {
        path: "/services-repairs",
        name: "ServicesRepairs",
        component: ServicesRepairs,
    },
    // administration
    // {
    //     path: "/roles-rights",
    //     name: "RolesRights",
    //     component: RolesRights,
    // },
    {
        path: "/roles",
        name: "RolesRights",
        component: RolesRights,
    },
    {
        path: "/request-inner",
        name: "RequestInner",
        component: RequestInner,
    },
    {
        path: "/about",
        name: "About",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ "../views/About.vue"),
    },
    {
        path: "/units-import",
        name: "UnitsImport",
        component: UnitsImport,
    },
    {
        path: "/:catchAll(.*)",
        name: "Error404",
        component: Home,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to) => {
    const pathRights = pathsAndRights.find((par) => par.path === to.path);

    if (to.path === "/login" && store.getters.currentUser.isAuthenticated) {
        return { name: "Home" };
    }

    if (to.name === "Error404") {
        return { name: "Home" };
    }

    if (!pathRights) {
        return true;
    }

    const allow = pathRights.rightsA
        ? pathRights.rightsA.reduce(
            (a, r) => (
                a || !!(store.getters.currentUser.rightsObject[r.rightName] & r.rightValue)
            ),
            false,
        )
        : true;

    return allow || { name: "Home" };
});

export default router;
