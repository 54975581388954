import date from 'quasar/src/utils/date/date.js';;

class MomentClass {
    timeStamp = Date.now();

    constructor(value, format) {
        if (value) {
            this.timeStamp = format
                ? date.extractDate(value, format).getTime()
                : (new Date(value)).getTime();
        }
    }

    format(f) {
        return date.formatDate(this.timeStamp, f);
    }

    isBefore(t) {
        return this.timeStamp < t.timeStamp;
    }

    valueOf() {
        return this.timeStamp;
    }
}

export default function moment(v, f) {
    return new MomentClass(v, f);
}
