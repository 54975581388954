<template>
    <div class="filter-programs">
        <q-input
            :modelValue="programTitle"
            outlined
            dense
            clearable
            label="Наименование ПО"
            @update:modelValue="(v) => $emit('update:programTitle', v)"
        />
        <q-select
            :modelValue="programType"
            outlined
            dense
            :options="programTypes"
            optionValue="value"
            mapOptions
            emitValue
            label="Тип программного обеспечения"
            @update:modelValue="(v) => $emit('update:programType', v)"
        />
        <q-select
            :modelValue="programOrgTypes"
            outlined
            dense
            multiple
            :options="orgKinds"
            optionValue="id"
            optionLabel="title"
            mapOptions
            emitValue
            label="Типы организаций"
            @update:modelValue="(v) => $emit('update:programOrgTypes', v)"
        />
        <q-select
            :modelValue="programGasSubSystemId"
            outlined
            dense
            clearable
            :options="programsSubsystems"
            optionValue="id"
            optionLabel="title"
            mapOptions
            emitValue
            label="Подсистема ГАС &quot;Правосудие&quot;"
            @update:modelValue="(v) => $emit('update:programGasSubSystemId', v)"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { PROGRAM_TYPES } from "@/store/constants";

export default {
    name: "FilterOrganizations",
    props: {
        programGasSubSystemId: {
            type: Number,
            default: null,
        },
        programTitle: {
            type: String,
            default: "",
        },
        programType: {
            type: Number,
            default: 3,
        },
        programTechSupport: {
            type: String,
            default: "",
        },
        programOrgTypes: {
            type: Array,
            default: () => ([]),
        },
    },
    emits: [
        "update:programGasSubSystemId",
        "update:programTitle",
        "update:programType",
        "update:programTechSupport",
        "update:programOrgTypes",
    ],
    setup() {
        return {
            programTypes: [
                {
                    value: 3,
                    label: "Все",
                },
                ...PROGRAM_TYPES,
            ],
        };
    },
    computed: {
        ...mapGetters({
            orgKinds: "orgKinds",
            programsSubsystems: "programsSubsystems",
        }),
    },
};
</script>
