import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        posts: [],
    },
    getters: {
        posts: (state) => state.posts,
        postTitle: (state) => (postId) => {
            const post = state.posts.find((p) => p.id === postId);
            return post
                ? post.title
                : null;
        },
    },
    actions: {
        getPostsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/posts`)
                    .then((res) => {
                        commit("setPosts", res.data.posts);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        savePost({ dispatch }, uPost) {
            const method = uPost.id === null
                ? "post"
                : "put";
            const url = uPost.id === null
                ? `${siteHost}/api/posts`
                : `${siteHost}/api/posts/${uPost.id}`;

            return new Promise((resolve, reject) => {
                axios[method](
                    url,
                    {
                        post: uPost,
                    },
                )
                    .then(() => {
                        dispatch("getPostsFromDB");
                        resolve("Сохранено");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        deletePost({ commit }, postId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/posts/${postId}`)
                    .then(() => {
                        commit("deletePost", postId);
                        resolve("Удалено");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
    mutations: {
        setPosts(state, posts) {
            state.posts = posts;
        },
        deletePost(state, postId) {
            const index = state.posts.findIndex((p) => p.id === postId);
            if (index >= 0) {
                state.posts.splice(index, 1);
            }
        },
    },
};
