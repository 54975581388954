import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        toolsKinds: [],
    },
    getters: {
        toolsKinds: (state) => state.toolsKinds,
        getToolKind: (state) => (toolKindId) => {
            const toolKind = state.toolsKinds.find((tk) => tk.id === toolKindId);
            return toolKind || {};
        },
        getToolKindName: (state) => (toolKindId) => {
            const toolKind = state.toolsKinds.find((tk) => tk.id === toolKindId);
            return toolKind
                ? toolKind.title
                : "";
        },
    },
    actions: {
        getToolsKindsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/tool_kind`)
                    .then((res) => {
                        // console.log(res.data.tool_kind);
                        commit("setToolsKinds", res.data.tool_kind);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveToolKind(context, toolKind) {
            const method = toolKind.id
                ? "put"
                : "post";

            const url = toolKind.id
                ? `${siteHost}/api/tool_kind/${toolKind.id}`
                : `${siteHost}/api/tool_kind`;

            return new Promise((resolve, reject) => {
                axios[method](url, toolKind)
                    .then(() => {
                        resolve("Вид оборудования сохранен");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        deleteToolKind(context, toolKindId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/tool_kind/${toolKindId}`)
                    .then(() => {
                        resolve("Вид оборудования удален");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
    mutations: {
        setToolsKinds(state, toolsKinds) {
            state.toolsKinds = toolsKinds;
        },
    },
};
