import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        organizations: [],
        orgKinds: [],
        units: [],
        filter: {
            region_id: null,
            host_id: null,
            name: null,
            vncode: null,
            is_host: false,
        },
    },
    getters: {
        orgs: (state) => (
            // TODO: user's rights ?
            state.organizations
        ),
        orgsFiltered: (state) => (
            state.organizations
                .filter((org) => (state.filter.region_id
                    ? org.region_id === state.filter.region_id
                    : true))
                .filter((org) => (state.filter.host_id
                    ? org.host_id === state.filter.host_id || org.id === state.filter.host_id
                    : true))
                .filter((org) => (state.filter.name
                    ? org.name.toLowerCase().includes(state.filter.name.toLowerCase())
                    : true))
                .filter((org) => (state.filter.vncode
                    ? org.vncode.toLowerCase().includes(state.filter.vncode.toLowerCase())
                    : true))
                .filter((org) => (state.filter.is_host
                    ? org.is_host
                    : true))
        ),
        hosts: (state) => (
            // TODO: user's rights ?
            state.organizations.filter((org) => org.is_host)
        ),
        orgKinds: (state) => state.orgKinds,
        filterOrg: (state) => state.filter,
        getOrgProperty: (state) => (orgId, property) => {
            const org = state.organizations.find((o) => o.id === orgId);
            return org
                ? org[property]
                : "";
        },
        getSignersCountByOrgKind: (state, getters) => (unitId) => {
            const orgKindId = getters.getOrgProperty(
                getters.getOrgIdOfUnit(unitId),
                "kind_id",
            );

            const orgKind = state.orgKinds.find((ok) => ok.id === orgKindId);

            return orgKind ? orgKind.signers_count || 2 : 2;
        },
        getKsaTitle: (state, getters) => (unitId) => {
            const orgKindId = getters.getOrgProperty(
                getters.getOrgIdOfUnit(unitId),
                "kind_id",
            );

            const orgKind = state.orgKinds.find((ok) => ok.id === orgKindId);

            console.log(orgKind);

            return orgKind ? orgKind.ksa_title || "" : "";
        },
        getUnitsOfOrg: (state) => (orgId) => (
            state.units.filter((unit) => unit.organization_id === orgId)
        ),
        getOrgIdOfUnit: (state) => (unitId) => {
            const unit = state.units.find((u) => u.id === unitId);
            if (unit) {
                const org = state.organizations.find((o) => o.id === unit.organization_id);

                return org
                    ? org.id
                    : null;
            }
            return null;
        },
        unitAddress: (state) => (unitId) => {
            const unit = state.units.find((u) => u.id === unitId);
            return unit
                ? unit.address
                : "";
        },
        getUnitTitle: (state) => (unitId) => {
            const unit = state.units.find((u) => u.id === unitId);
            return unit
                ? `${unit.name} ${unit.address}`
                : "UNIT NOT FOUND";
        },
        unitsFull: (state) => state.units,
        filialOrg: (state) => {
            let filialOrg = null;
            const filialKind = state.orgKinds.find((orgKind) => orgKind.is_filial);
            if (filialKind) {
                filialOrg = state.organizations.find((org) => org.kind_id === filialKind.id);
            }
            return filialOrg;
        },
    },
    actions: {
        getOrganizationsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/organization`)
                    .then((res) => {
                        // console.log("O", res.data);
                        commit("setOrganizations", res.data.organization);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getUnitsFromDB({ commit, getters }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/unit`)
                    .then((res) => {
                        // console.log("U", res.data);
                        const unitsWithOrgs = res.data.units
                            .map((u) => {
                                const org = getters.orgs.find((o) => o.id === u.organization_id);

                                return org
                                    ? {
                                        ...org,
                                        id: u.id,
                                        address: u.address,
                                        organization_id: u.organization_id,
                                        title: `${org.vncode}, ${org.name}, ${u.address}`,
                                    }
                                    : null;
                            })
                            .filter((u) => u !== null);

                        commit("setUnits", unitsWithOrgs);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveOrganization({ dispatch }, { org, units }) {
            const url = org.id
                ? `${siteHost}/api/organization/${org.id}`
                : `${siteHost}/api/organization`;
            const method = org.id
                ? "put"
                : "post";

            return new Promise((resolve, reject) => {
                axios[method](
                    url,
                    {
                        organization: org,
                        units,
                    },
                )
                    .then(() => {
                        dispatch("getOrganizationsFromDB");
                        dispatch("getUnitsFromDB");
                        resolve("Организация сохранена");
                    })
                    .catch((err) => {
                        reject(err.response.data.message);
                    });
            });
        },
        /*
        saveOrganizationTemp({ }, organization) {
            const org = { ...organization };
            const units = organization.units.map((u) => ({ address: u }));
            delete org.units;

            return new Promise((resolve, reject) => {
                axios.post(
                    `${siteHost}/api/organization`,
                    {
                        organization: org,
                        units,
                    },
                )
                    .then(() => {
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        */
        deleteOrganization({ dispatch }, orgId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/organization/${orgId}`)
                    .then(() => {
                        dispatch("getOrganizationsFromDB");
                        resolve("Организация удалена");
                    })
                    .catch((err) => {
                        reject(err.response.data.message);
                    });
            });
        },
        getOrgKindsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/organization_kind`)
                    .then((res) => {
                        commit("setOrgKinds", res.data.orgKinds);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveOrgKind({ dispatch }, orgKind) {
            return new Promise((resolve, reject) => {
                if (orgKind.id) {
                    axios.put(
                        `${siteHost}/api/organization_kind/${orgKind.id}`,
                        orgKind,
                    )
                        .then(() => {
                            dispatch("getOrgKindsFromDB");
                            resolve("Тип организации сохранен");
                        })
                        .catch((err) => {
                            reject(err);
                        });
                }
                else {
                    axios.post(
                        `${siteHost}/api/organization_kind`,
                        orgKind,
                    )
                        .then(() => {
                            dispatch("getOrgKindsFromDB");
                            resolve("Тип организации сохранен");
                        })
                        .catch((err) => {
                            reject(err);
                        });
                }
            });
        },
        delOrgKind({ dispatch }, orgKindId) {
            axios.delete(`${siteHost}/api/organization_kind/${orgKindId}`)
                .then(() => {
                    dispatch("getOrgKindsFromDB");
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        setFilterOrg({ commit }, filter) {
            commit("setFilterOrg", filter);
        },
    },
    mutations: {
        setOrganizations(state, organizations) {
            state.organizations = organizations;
        },
        setOrgKinds(state, orgKinds) {
            state.orgKinds = orgKinds;
        },
        setUnits(state, units) {
            state.units = units;
        },
        setFilterOrg(state, filter) {
            state.filter = filter;
        },
    },
};
