<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Должности
            ({{ posts.length }})
        </div>
        <div class="content-body">
            <div class="simple-three-grid">
                <!-- header -->
                <div class="cell cell-header cell-center">
                    №
                </div>
                <div class="cell cell-header cell-center">
                    Наименование
                </div>
                <div class="cell cell-header cell-center">
                    <q-btn
                        round
                        :icon="matAdd"
                        color="primary"
                        size="0.7rem"
                        @click="editItem(null)"
                    />
                </div>
                <!-- data -->
                <div
                    v-for="(post, index) in posts"
                    :key="index"
                    class="row-wrapper"
                    @click="editItem(index)"
                >
                    <div class="cell cell-center">
                        {{ index + 1 }}
                    </div>
                    <div class="cell">
                        {{ post.title }}
                    </div>
                    <div class="cell cell-center">
                        <q-btn
                            round
                            :icon="matDelete"
                            size="0.7rem"
                            @click="(e) => deleteItem(e, index)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";

import DialogEditSimple from "@/components/Dialogs/DialogEditSimple.vue";

export default {
    name: "Posts",
    computed: {
        ...mapGetters({
            posts: "posts",
        }),
    },
    methods: {
        editItem(index) {
            this.$q.dialog({
                component: DialogEditSimple,
                componentProps: {
                    dialogTitle: "Должность",
                    itemForEdit: index === null ? "" : this.posts[index].title,
                },
            })
                .onOk((post) => {
                    this.$store.dispatch(
                        "savePost",
                        {
                            id: index === null ? null : this.posts[index].id,
                            title: post,
                        },
                    )
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
        deleteItem(event, index) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить должность "${this.posts[index].title}"?`,
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deletePost", this.posts[index].id)
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
    },
};
</script>
