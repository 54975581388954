import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        toolsModels: [],
        toolsModelsFilter: {},
    },
    getters: {
        toolsModels: (state) => (
            /*
            id:
            manufacturer_id:
            model:
            tool_kind_id:
            */
            state.toolsModels
                .filter((model) => (
                    state.toolsModelsFilter.manufacturer_id
                        ? model.manufacturer_id === state.toolsModelsFilter.manufacturer_id
                        : true
                ))
                .filter((model) => (
                    state.toolsModelsFilter.tool_kind_id
                        ? model.tool_kind_id === state.toolsModelsFilter.tool_kind_id
                        : true
                ))
                .filter((model) => (
                    state.toolsModelsFilter.model
                        ? model.model
                            .toLowerCase()
                            .includes(state.toolsModelsFilter.model.trim().toLowerCase())
                        : true
                ))
                .filter((model) => (
                    state.toolsModelsFilter.is_verified !== null &&
                        state.toolsModelsFilter.is_verified !== undefined
                        ? !!model.is_verified === state.toolsModelsFilter.is_verified
                        : true
                ))
        ),
        getToolModelName: (state) => (toolModelId) => {
            const toolModel = state.toolsModels.find((tm) => tm.id === toolModelId);
            return toolModel
                ? toolModel.model
                : "";
        },
        toolsModelsFilter: (state) => state.toolsModelsFilter,
    },
    actions: {
        getToolsModelsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/tool_model`)
                    .then((res) => {
                        // console.log(res.data.tools_models);
                        commit("setToolsModels", res.data.tools_models);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveToolModel({ dispatch }, { toolModel, filesListDocs, filesListPictures }) {
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                formData.append("toolModel", JSON.stringify(toolModel));

                formData.append("section", "tool_docs");
                formData.append("filesListDocs", JSON.stringify(filesListDocs));
                filesListDocs.forEach((file) => {
                    if (file.blob) {
                        formData.append("tool_docs", file.blob);
                    }
                });

                formData.append("section", "tool_model_pictures");
                formData.append("filesListPictures", JSON.stringify(filesListPictures));
                filesListPictures.forEach((file) => {
                    if (file.blob) {
                        formData.append("tool_model_pictures", file.blob);
                    }
                });

                const url = toolModel.id
                    ? `${siteHost}/api/tool_model/${toolModel.id}`
                    : `${siteHost}/api/tool_model`;

                const method = toolModel.id
                    ? "put"
                    : "post";

                axios[method](
                    url,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    },
                )
                    .then(() => {
                        resolve("Модель оборудования сохранена");
                        dispatch("getToolsModelsFromDB");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        deleteToolModel({ dispatch }, modelId) {
            return new Promise((resolve, reject) => {
                const url = `${siteHost}/api/tool_model/${modelId}`;

                axios.delete(url)
                    .then(() => {
                        resolve("Модель оборудования удалена");
                        dispatch("getToolsModelsFromDB");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        setToolsModelsFilter({ commit }, toolsModelsFilter) {
            commit("setToolsModelsFilter", toolsModelsFilter);
        },
    },
    mutations: {
        setToolsModels(state, toolsModels) {
            state.toolsModels = toolsModels;
        },
        setToolsModelsFilter(state, toolsModelsFilter) {
            state.toolsModelsFilter = toolsModelsFilter;
        },
    },
};
