<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-circular-progress
                    showValue
                    class="text-light-blue q-ma-md"
                    :value="valueProgress"
                    size="5.5em"
                    color="light-blue"
                />
            </div>

            <q-card-section>
                <div class="text-h6">
                    Создание отчета о количестве единиц ПТС ожидающих ремонта
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section style="max-height: 70vh;">
                <SelectFilter
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    class="q-mb-sm"
                />
                <PoeDatePicker
                    ref="refDateStart"
                    v-model="dateStart"
                    labelTitle="Дата начала отчета"
                    :futureDateAllowed="false"
                />
                <PoeDatePicker
                    ref="refDateFinsh"
                    v-model="dateFinsh"
                    labelTitle="Дата окончания отчета"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сформировать"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { date } from "quasar";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import { createXls, saveXls } from "@/helpers/createXls";
import { getDataForReportTollsWaitingRepair } from "@/helpers/getDataForReports";
import { dateDMYtoYMD } from "@/helpers/helpers";

export default {
    name: "DialogCreateReportWaitingRepair",
    components: {
        SelectFilter,
        PoeDatePicker,
    },
    data() {
        return {
            regionId: null,
            dateStart: date.formatDate(date.startOfDate(new Date(), "year"), "DD.MM.YYYY"),
            dateFinsh: date.formatDate(date.endOfDate(new Date(), "year"), "DD.MM.YYYY"),
            showSpinner: false,
            valueProgress: 0,
        };
    },
    computed: {
        ...mapGetters({
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getOrgProperty: "getOrgProperty",
            getRegionName: "getRegionName",
            postTitle: "postTitle",
            getToolKindName: "getToolKindName",
            getToolModelName: "getToolModelName",
            toolsSetName: "toolsSetName",
            getToolSetName: "getToolSetName",
            getManufacturerName: "getManufacturerName",
            currentUser: "currentUser",
            regions: "regions",
        }),
    },
    methods: {
        show() {
            this.regionId = this.currentUser.region_id;
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            // const bTest = true;

            const reportData = await getDataForReportTollsWaitingRepair(
                this.regionId,
                dateDMYtoYMD(this.dateStart),
                dateDMYtoYMD(this.dateFinsh),
            );

            // console.log(reportData);

            // if (bTest) {
            //     return;
            // }

            this.showSpinner = true;
            this.valueProgress = 0;

            this.$refs.refDateStart.validate();
            this.$refs.refDateFinsh.validate();

            if (this.$refs.refDateStart.hasError ||
                this.$refs.refDateFinsh.hasError) {
                return;
            }

            const regionName = this.getRegionName(this.regionId);
            const outFileName = `${regionName}. Отчет по ремонтам №4 за период с ${this.dateStart} по ${this.dateFinsh}.xlsx`;

            const replacement = {
                region_name: regionName,
                tServers: reportData.tServers,
                tSysBlocksMonitors: reportData.tSysBlocksMonitors,
                tTerminalsVks: reportData.tTerminalsVks,
                tInfoBooth: reportData.tInfoBooth,
                tAvfs: reportData.tAvfs,
                tAps: reportData.tAps,
                tServersIvk: reportData.tServersIvk,
                tAudiosVideosFix: reportData.tAudiosVideosFix,
                tAudiosFix: reportData.tAudiosFix,
                tComms: reportData.tComms,
                tPrinters: reportData.tPrinters,
                tMfus: reportData.tMfus,
                tKmas: reportData.tKmas,
                tWitness: reportData.tWitness,
                tOthers: reportData.tOthers,
                all:
                    +reportData.tServers +
                    +reportData.tSysBlocksMonitors +
                    +reportData.tTerminalsVks +
                    +reportData.tInfoBooth +
                    +reportData.tAvfs +
                    +reportData.tAps +
                    +reportData.tServersIvk +
                    +reportData.tAudiosVideosFix +
                    +reportData.tAudiosFix +
                    +reportData.tComms +
                    +reportData.tPrinters +
                    +reportData.tMfus +
                    +reportData.tKmas +
                    +reportData.tWitness +
                    +reportData.tOthers,
                // rep_date_start: this.dateStart,
                // rep_date_finsh: this.dateFinsh,
            };

            createXls(
                this.siteHost,
                "report-4-tools-waiting-repair.xlsx",
                outFileName,
                replacement,
            )
                .then((repairreport) => {
                    saveXls(repairreport);
                });
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
