<template>
    <q-btn
        class="cell-span-col-2"
        filled
        color="primary"
        label="Назначить исполнителя"
        :disable="request.request_status_id == REQUEST_STATUS_VALUES.CLOSED || !canBeEdited"
        @click="chooseEngineer"
    />
    <q-btn
        class="cell-span-col-2"
        filled
        color="primary"
        label="Заявка выполнена"
        :disable="request.request_status_id !== REQUEST_STATUS_VALUES.PERFORMED || !canBeEdited"
        @click="() => $emit('setStatus', REQUEST_STATUS_VALUES.CLOSED)"
    />
    <q-btn
        class="cell-span-col-2"
        filled
        color="primary"
        label="На повторное исполнение"
        :disable="!canBeEdited"
        @click="requestRevive"
    />
</template>

<script>
import { mapGetters } from "vuex";
import DialogSearchEngineer from "@/components/Dialogs/DialogSearchEngineer.vue";
import { REQUEST_STATUS_VALUES } from "@/store/constants";

export default {
    name: "DialogEditRequestMovingApprove",
    props: {
        canBeEdited: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["addEngineer", "delEngineer", "setStatus"],
    data() {
        return {
            REQUEST_STATUS_VALUES,
        };
    },
    computed: {
        ...mapGetters({
            request: "request",
        }),
    },
    methods: {
        chooseEngineer() {
            this.$q.dialog({
                component: DialogSearchEngineer,
                componentProps: {
                    dialogTitle: "Выбор исполнителя",
                    itemForEdit: "",
                },
            })
                .onOk((id) => {
                    this.$emit("delEngineer", 0);
                    this.$emit("addEngineer", id);
                });
        },
        requestRevive() {
            this.$emit("setStatus", REQUEST_STATUS_VALUES.WAITING_ENGINEER);
            this.$store.dispatch(
                "setRequestField",
                {
                    field: "result",
                    value: null,
                },
            );
        },
    },
};
</script>
