<template>
    <div class="users-filter">
        <div
            v-if="showFilter"
            class="users-filter"
        >
            <!-- TODO: check sizes -->
            <SelectFilter
                v-model="regionId"
                :options="regions"
                label="Регион"
                clearable
                class="users-filter--input"
            />
            <SelectFilter
                v-model="hostId"
                :options="hosts"
                optionLabel="name"
                label="Балансодержатель"
                clearable
                class="users-filter--input"
            />
            <q-input
                v-model="name"
                outlined
                dense
                clearable
                label="Наименование"
                class="users-filter--input"
            />
            <q-input
                v-model="vncode"
                outlined
                dense
                clearable
                label="VNCODE"
                class="users-filter--input"
            />
        </div>

        <q-btn
            round
            :icon="matSearch"
            @click="showFilter = !showFilter"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectFilter from "@/components/Common/SelectFilter.vue";

export default {
    name: "FilterOrganizations",
    components: {
        SelectFilter,
    },
    data() {
        return {
            showFilter: false,
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            hosts: "hosts",
            filterOrg: "filterOrg",
        }),
        regionId: {
            get() {
                return this.filterOrg.region_id;
            },
            set(regionId) {
                this.$store.dispatch("setFilterOrg",
                    {
                        ...this.filterOrg,
                        region_id: regionId,
                    });
            },
        },
        hostId: {
            get() {
                return this.filterOrg.host_id;
            },
            set(hostId) {
                this.$store.dispatch("setFilterOrg",
                    {
                        ...this.filterOrg,
                        host_id: hostId,
                    });
            },
        },
        name: {
            get() {
                return this.filterOrg.name;
            },
            set(name) {
                this.$store.dispatch("setFilterOrg",
                    {
                        ...this.filterOrg,
                        name,
                    });
            },
        },
        vncode: {
            get() {
                return this.filterOrg.vncode;
            },
            set(vncode) {
                this.$store.dispatch("setFilterOrg",
                    {
                        ...this.filterOrg,
                        vncode,
                    });
            },
        },
    },
};
</script>
