<template>
    <div class="footer">
        {{ stringForShow }}
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "Footer",
    computed: {
        ...mapGetters({
            appSettings: "appSettings",
        }),
        stringForShow() {
            const sPhone = this.appSettings.phone
                ? `, Контактный телефон ${this.appSettings.phone}`
                : "";

            return `${this.appSettings.title}${sPhone}`;
        },
    },
};
</script>
