<template>
    <div class="filter-requests-inner">
        <q-select
            v-model="requestInnerKindId"
            :options="REQUEST_INNER_KINDS"
            mapOptions
            emitValue
            outlined
            dense
            clearable
            label="Вид запроса"
        />
        <q-input
            v-model="requestNumber"
            outlined
            dense
            clearable
            type="number"
            label="Номер запроса"
        />
        <PoeDatePicker
            v-model="date"
            labelTitle="Дата"
            emptyDateAllowed
            classOuter=""
            :doValidate="false"
        />
        <q-select
            v-model="author"
            outlined
            dense
            :options="users"
            optionValue="id"
            :optionLabel="emplFio"
            mapOptions
            emitValue
            label="Автор"
            clearable
        >
            <template #option="scope">
                <q-item v-bind="scope.itemProps">
                    <q-item-section>
                        <q-item-label>
                            {{ scope.opt.surname }}
                            {{ scope.opt.name }}
                            {{ scope.opt.patronymic || "" }}
                        </q-item-label>
                        <q-item-label caption>
                            {{ postTitle(scope.opt.post_id) }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </template>
        </q-select>
        <q-select
            v-model="state"
            outlined
            dense
            :options="states"
            mapOptions
            emitValue
            label="Состояние"
            clearable
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import { REQUEST_INNER_KINDS } from "@/store/constants";

export default {
    name: "FilterRequestsInner",
    components: {
        PoeDatePicker,
    },
    setup() {
        return {
            states: [
                { value: 1, label: "Активно" },
                { value: 2, label: "Выполнено" },
                { value: 3, label: "Отложено" },
            ],
            REQUEST_INNER_KINDS,
        };
    },
    computed: {
        ...mapGetters({
            reqsInnerFilter: "reqsInnerFilter",
            users: "users",
            postTitle: "postTitle",
        }),
        requestInnerKindId: {
            get() {
                return this.reqsInnerFilter.requestInnerKindId;
            },
            set(value) {
                this.$store.dispatch(
                    "setReqsInnerFilter",
                    {
                        ...this.reqsInnerFilter,
                        requestInnerKindId: value,
                    },
                );
            },
        },
        requestNumber: {
            get() {
                return this.reqsInnerFilter.number;
            },
            set(value) {
                this.$store.dispatch(
                    "setReqsInnerFilter",
                    {
                        ...this.reqsInnerFilter,
                        number: value,
                    },
                );
            },
        },
        date: {
            get() {
                return this.reqsInnerFilter.date;
            },
            set(value) {
                this.$store.dispatch(
                    "setReqsInnerFilter",
                    {
                        ...this.reqsInnerFilter,
                        date: value,
                    },
                );
            },
        },
        author: {
            get() {
                return this.reqsInnerFilter.author;
            },
            set(value) {
                this.$store.dispatch(
                    "setReqsInnerFilter",
                    {
                        ...this.reqsInnerFilter,
                        author: value,
                    },
                );
            },
        },
        state: {
            get() {
                return this.reqsInnerFilter.state;
            },
            set(value) {
                this.$store.dispatch(
                    "setReqsInnerFilter",
                    {
                        ...this.reqsInnerFilter,
                        state: value,
                    },
                );
            },
        },
    },
    methods: {
        emplFio(empl) {
            const postTitle = this.postTitle(empl.post_id);
            return empl && empl.surname
                ? `${empl.surname} ${empl.name} ${empl.patronymic || ""}
                    ${postTitle ? `(${postTitle})` : ""}`
                : null;
        },
    },
};
</script>
