<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <div class="dialog-card--act-co-7">
            <div class="text-h6">
                {{ dialogTitle }}
            </div>

            <q-input
                v-if="actType == 'CO-7'"
                v-model="noteBefore"
                outlined
                dense
                type="textarea"
                :inputStyle="{ resize: 'none', height: '300px' }"
                label="Текст Акта СО7"
            />

            <q-input
                v-if="actType == 'CO-8'"
                v-model="noteAfter"
                outlined
                dense
                type="textarea"
                :inputStyle="{ resize: 'none', height: '300px' }"
                label="Текст Акта СО8"
            />

            <q-input
                v-if="actType == 'CO-4.1'"
                v-model="noteCO41"
                outlined
                dense
                type="textarea"
                :inputStyle="{ resize: 'none', height: '300px' }"
                label="Текст Акта СО4.1"
            />

            <q-input
                v-if="actType == 'CO-4.2'"
                v-model="noteCO42"
                outlined
                dense
                type="textarea"
                :inputStyle="{ resize: 'none', height: '300px' }"
                label="Текст Акта СО4.2"
            />

            <div>
                <PoeDatePicker
                    v-model="actDate"
                    labelTitle="Дата акта"
                    :futureDateAllowed="true"
                    style="flex-grow: 0;"
                />
            </div>

            <div v-if="['CO-7', 'CO-8'].includes(actType)">
                <q-radio
                    v-model="signersCount"
                    :val="2"
                    label="2 подписанта"
                />
                <q-radio
                    v-model="signersCount"
                    :val="3"
                    label="3 подписанта"
                />
            </div>

            <DialogSignersSigner
                v-for="signerIndex in +signersCount"
                :key="signerIndex"
                ref="refSigner"
                v-model="signers[signerIndex - 1]"
                :signers="signers"
                :rightSigningDocs="rights[signerIndex - 1]"
                :marginBottom="false"
            />

            <q-space />

            <div class="d-act-co7--controls">
                <q-field
                    outlined
                    label="Вид подписания документов"
                    stackLabel
                >
                    <q-radio
                        v-model="signType"
                        val="paper"
                        label="На бумажном носителе"
                    />
                    <q-radio
                        v-model="signType"
                        val="electron"
                        label="Посредством ЭЦП"
                    />
                </q-field>

                <q-space />

                <q-btn
                    flat
                    label="Продолжить"
                    color="primary"
                    :icon="matPlayArrow"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Выйти"
                    color="primary"
                    :icon="matCancel"
                />
            </div>
        </div>
    </q-dialog>
</template>

<script>
import { date } from "quasar";
import {
    OPTIONS_VIEW_BEFORE_VALUES,
} from "@/store/constants";

import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import DialogSignersSigner from "./DialogSignersSigner.vue";

export default {
    name: "DialogCreateActCO7",
    components: {
        PoeDatePicker,
        DialogSignersSigner,
    },
    props: {
        actType: {
            type: String,
            default: "CO-7",
        },
        toolManufacturer: {
            type: String,
            default: "",
        },
        toolKind: {
            type: String,
            default: "",
        },
        toolModel: {
            type: String,
            default: "",
        },
        toolSerialNumber: {
            type: String,
            default: "",
        },
        toolInventarNumber: {
            type: String,
            default: "",
        },
        toolDescription: {
            type: String,
            default: "",
        },
        toolWorksList: {
            type: String,
            default: "",
        },
        toolGuaranty: {
            type: String,
            default: "",
        },
        troublesDisplay: {
            type: String,
            default: "",
        },
        troublesList: {
            type: String,
            default: "",
        },
        viewBefore: {
            type: Number,
            default: 0,
        },
        signersCountByOrgKind: {
            type: Number,
            required: true,
        },
    },
    emits: ["ok"],
    data() {
        return {
            actDate: date.formatDate(new Date(), "DD.MM.YYYY"),
            signersCount: this.signersCountByOrgKind,
            signers: [
                { id: null },
                { id: null },
                { id: null },
            ],
            rights: [
                "RIGHT_SIGNING_DOCS_IAC",
                "RIGHT_SIGNING_DOCS_UNIT",
                "RIGHT_SIGNING_DOCS_FINAL",
            ],
            noteBefore: [
                "В результате проверки установлено:\n",
                "1. Комплектность проверяемого ПТС:",
                " шнуры отсутствуют, документация отсутствует, упаковка отсутствует.\n",
                "2. Внешние повреждения отсутствуют, пломбы отсутствуют (не предусмотрены).\n",
                "3. Электропитание, схема включения, условия эксплуатации соответствовали.\n",
                `4. Описание проявления неисправности: ${this.troublesDisplay}.\n\n`,
                "Заключение:\n",
                `1. Установлены следующие неисправности ПТС: ${this.troublesList}.\n`,
                "2. Установленные причины неисправности (физический и моральный износ, ",
                "нарушение условий эксплуатации, аварии, стихийные бедствия и иные ",
                "чрезвычайные ситуации, длительное неиспользование объекта, заводской брак ",
                "или иное): физический износ.\n",
                "3. Рекомендации о целесообразности (пригодности) дальнейшего использования ",
                "(эксплуатации), ПТС, возможности и эффективности его восстановления: ",
                `Вид оборудования - ${this.toolKind}, Производитель - ${this.toolManufacturer}, `,
                `Наименование - ${this.toolModel}, серийный номер ${this.toolSerialNumber}, `,
                `инвентарный номер ${this.toolInventarNumber}, `,
                " (передача в ремонт, невозможность восстановительного ремонта, экономическая",
                " нецелесообразность ремонта, ввод в эксплуатацию после ремонта,",
                " вывод из эксплуатации) ",
                this.viewBefore === OPTIONS_VIEW_BEFORE_VALUES.TO_DISMISS
                    ? [
                        "вывод из эксплуатации",
                        "невозможность восстановительного ремонта",
                        "экономическая нецелесообразность ремонта",
                    ].join(", ")
                    : "передача в ремонт",
                ".\n",
                "4. Возможность использования отдельных узлов, деталей,",
                " материалов выбывающего ПТС:\n",
                "_______________________________________________________________________\n",
                "_______________________________________________________________________\n",
            ].join(""),
            noteAfter: [
                "Проведен контроль функционирования отремонтированного/восстановленного ",
                "оборудования.\n\n",
                "В результате проверки установлено, что отремонтированное/восстановленное ",
                "оборудование и развернутые на автоматизированных рабочих местах ",
                "пользователей ОПО и СПО функционируют нормально.\n\n",
                "Отремонтированное оборудование передано в полном составе.\n\n",
                "Результаты работ внесены в таблицу 6.1 формуляра КСА и в паспорт комплекта ТС.",
            ].join(""),
            noteCO41: [
                `1. ${this.toolDescription}.\n`,
                "2. Комплектность передаваемых ПТС из состава КСА ",
                "(шнуры, документация, упаковка производителя и т.д.): ",
                "шнуры отсутствуют, документация отсутствует, упаковка отсутствует.\n",
                "3. Внешние повреждения (нет/есть/какие) нет,",
                " пломбы (при наличии) (нарушены/не нарушены) не нарушены.\n",
                `4. Вид ремонта (гарантийный / негарантийный): ${this.toolGuaranty}.`,
            ].join(""),
            noteCO42: [
                `1. ${this.toolDescription}.\n`,
                `2. Вид ремонта (гарантийный / негарантийный): ${this.toolGuaranty}.\n`,
                "3. Краткое описание результатов выполненных ремонтных работ",
                " (замена деталей, невозможность восстановительного ремонта,",
                " экономическая нецелесообразность ремонта)",
                this.toolWorksList ? ` ${this.toolWorksList}.\n` : ".\n",
                "4. Комплектность передаваемых ПТС из состава КСА",
                " (шнуры, документация, упаковка производителя и т.д.)",
                " шнуры отсутствуют, документация отсутствует, упаковка отсутствует.\n",
                "5. Внешние повреждения (нет/есть/какие) нет,",
                " пломбы (при наличии) (нарушены/не нарушены) не нарушены.\n",
                "6. Указанное в п. 1 оборудование передано в",
                " (исправном/неисправном) исправном состоянии.\n",
                "7. Произведено восстановление настроек и информации, ПТС введено(ы) в действие.\n",
            ].join(""),
            signType: "electron",
        };
    },
    computed: {
        dialogTitle() {
            if (this.actType === "CO-7") {
                return "Модуль формирования Акта СО-7";
            }
            if (this.actType === "CO-8") {
                return "Модуль формирования Акта СО-8";
            }
            if (this.actType === "CO-4.1") {
                return "Модуль формирования Акта СО-4.1";
            }
            if (this.actType === "CO-4.2") {
                return "Модуль формирования Акта СО-4.2";
            }

            return "Модуль формирования Акта";
        },
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            let bError = false;
            for (let i = 0; i < +this.signersCount; i++) {
                bError = this.$refs.refSigner[i].validate() || bError;
            }
            if (bError) {
                return;
            }
            this.$emit(
                "ok",
                {
                    actDate: this.actDate,
                    signers: this.signers,
                    noteBefore: this.noteBefore,
                    noteAfter: this.noteAfter,
                    noteCO41: this.noteCO41,
                    noteCO42: this.noteCO42,
                    signType: this.signType,
                },
            );
            this.hide();
        },
    },
};
</script>
