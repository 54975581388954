<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 1500px; max-width: 80vw;">
            <div class="text-h6 dialog-title">
                Карточка учетной единицы
            </div>
            <div class="row">
                <div class="col-4 image-container">
                    <!-- pictures -->
                    <q-carousel
                        v-model="slide"
                        transitionPrev="slide-right"
                        transitionNext="slide-left"
                        animated
                        controlColor="primary"
                        navigation
                        arrows
                        infinite
                        :padding="false"
                        height="100%"
                    >
                        <q-carousel-slide
                            v-for="(picture, index) in filesListPictures"
                            :key="index"
                            :name="index + 1"
                        >
                            <q-img
                                :src="`${siteHost}/${picture.path}`"
                                spinnerColor="white"
                                style="width: 100%"
                            />
                        </q-carousel-slide>
                    </q-carousel>
                </div>
                <div class="col-8">
                    <q-tabs
                        :modelValue="tabCurrent"
                        dense
                        activeColor="primary"
                        indicatorColor="primary"
                        noCaps
                        align="justify"
                        @update:modelValue="tabChange"
                    >
                        <q-tab
                            name="info"
                            label="Общее"
                        />
                        <q-tab
                            name="details"
                            label="Характеристики"
                        />
                        <q-tab
                            v-if="toolKind.soft_updatable"
                            name="programs"
                            label="ПО"
                        />
                        <q-tab
                            name="docs"
                            label="Документы"
                        />
                        <q-tab
                            name="pictures"
                            label="Изображения"
                        />
                        <q-tab
                            name="moving"
                            label="Движение"
                        />
                        <!-- <q-tab
                            name="repairs"
                            label="Ремонты"
                        /> -->
                        <q-tab
                            name="place"
                            label="Местоположение"
                        />
                        <q-tab
                            name="history"
                            label="История"
                        />
                    </q-tabs>

                    <q-separator />

                    <q-tab-panels
                        v-model="tabCurrent"
                        animated
                        keepAlive
                    >
                        <q-tab-panel name="info">
                            <!-- common info -->
                            <DialogEditToolCommon
                                ref="tabCommon"
                                v-model:tool="tool"
                                @setTraitValues="setTraitValues"
                                @setFilesListDocs="setFilesListDocs"
                                @setFilesListPictures="setFilesListPictures"
                            />
                        </q-tab-panel>
                        <q-tab-panel name="details">
                            <!-- traits -->
                            <q-card-section
                                style="max-height: 70vh; height: 60vh;"
                                class="scroll no-padding"
                            >
                                <q-select
                                    v-for="(tkf, index) in toolKindTraitsValues"
                                    :key="index"
                                    v-model="toolKindTraitsValues[index].trait_value_id"
                                    outlined
                                    dense
                                    :options="getTraitValues(tkf.tool_trait_id)"
                                    optionValue="value_id"
                                    optionLabel="value_title"
                                    mapOptions
                                    emitValue
                                    :label="tkf.title"
                                    class="q-mb-xs"
                                />
                            </q-card-section>
                        </q-tab-panel>
                        <q-tab-panel name="programs">
                            <!-- programs -->
                            <q-card-section
                                style="max-height: 70vh; height: 60vh;"
                                class="scroll no-padding"
                            >
                                <DialogEditToolSoftware v-model="toolSoftware" />
                            </q-card-section>
                        </q-tab-panel>
                        <q-tab-panel name="docs">
                            <!-- documents -->
                            <q-card-section
                                style="max-height: 70vh; height: 60vh;"
                                class="scroll no-padding"
                            >
                                <Documents
                                    fileSection="tool_docs"
                                    :filesList="filesListDocs"
                                    :addDocToList="addDoc"
                                    :deleteDoc="delDoc"
                                />
                            </q-card-section>
                        </q-tab-panel>
                        <q-tab-panel name="pictures">
                            <!-- programs -->
                            <q-card-section
                                style="max-height: 70vh; height: 60vh;"
                                class="scroll no-padding"
                            >
                                <Documents
                                    fileSection="tool_pictures"
                                    :filesList="filesListPictures"
                                    :addDocToList="addPicture"
                                    :deleteDoc="delPicture"
                                    :fileTypes="['.png', '.jpeg', '.jpg']"
                                />
                            </q-card-section>
                        </q-tab-panel>
                        <q-tab-panel name="moving">
                            <!-- moving -->
                            <q-card-section
                                style="max-height: 70vh; height: 60vh;"
                                class="scroll no-padding"
                            >
                                <DialogEditToolMoving v-model="toolMoving" />
                            </q-card-section>
                        </q-tab-panel>
                        <q-tab-panel name="repairs">
                            <!-- repairs -->
                            <q-card-section
                                style="max-height: 70vh; height: 60vh;"
                                class="scroll no-padding"
                            >
                                Ремонты
                            </q-card-section>
                        </q-tab-panel>
                        <q-tab-panel name="place">
                            <!-- place -->
                            <q-card-section
                                style="max-height: 70vh; height: 60vh;"
                                class="scroll no-padding"
                            >
                                <DialogEditToolPlace v-model="toolPlace" />
                            </q-card-section>
                        </q-tab-panel>
                        <q-tab-panel name="history">
                            <!-- history -->
                            <q-card-section
                                style="max-height: 70vh; height: 60vh;"
                                class="scroll no-padding"
                            >
                                <DialogEditToolHistory :toolHistory="tool.tool_histories" />
                            </q-card-section>
                        </q-tab-panel>
                    </q-tab-panels>

                    <q-separator />

                    <q-card-actions align="right">
                        <q-btn
                            v-if="canWriteTool"
                            flat
                            label="Сохранить"
                            color="primary"
                            :icon="matSave"
                            @click="onOkClick"
                        />
                        <q-btn
                            flat
                            label="Отменить"
                            color="primary"
                            :icon="matCancel"
                            @click="onCancelClick"
                        />
                    </q-card-actions>
                </div>
            </div>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Documents from "@/components/Documents/Documents.vue";
import {
    RIGHTS_CONSTANTS,
    TOOLS_STATES_VALUES,
    TOOLS_OWNERS_TYPES_VALUES,
} from "@/store/constants";
import DialogEditToolCommon from "./DialogEditToolCommon.vue";
import DialogEditToolPlace from "./DialogEditToolPlace.vue";
import DialogEditToolHistory from "./DialogEditToolHistory.vue";
import DialogEditToolSoftware from "./DialogEditToolSoftware.vue";
import DialogEditToolMoving from "./DialogEditToolMoving.vue";

const defaultTool = {
    description: null,
    tool_kind_id: null,
    id: null,
    in_set: null,
    inventar_number: null,
    manufacturer_id: null,
    model_id: null,
    serial_number: null,
    state: TOOLS_STATES_VALUES.WAITING,
    unit_id: null,
    work_in_dt: null,
    work_out_dt: null,
    guaranty_dt: null,
    set_id: null,
    owner_type_id: TOOLS_OWNERS_TYPES_VALUES.BASE,
    tool_softwares: [],
    tool_movings: [],
    tool_places: [],
};

export default {
    name: "DialogEditTool",
    components: {
        Documents,
        DialogEditToolCommon,
        DialogEditToolPlace,
        DialogEditToolHistory,
        DialogEditToolSoftware,
        DialogEditToolMoving,
    },
    props: {
        itemForEdit: {
            type: Object,
            required: true,
        },
        createNew: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["ok"],
    data() {
        return {
            tool: {},
            tabCurrent: "info",
            toolKindTraitsValues: [],
            filesListDocs: [],
            filesListPictures: [],
            slide: 1,
            toolSoftware: [],
            toolMoving: [],
            toolPlace: [],
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            getTraitValues: "getTraitValues",
            getToolKind: "getToolKind",
        }),
        pictures() {
            return this.filesListDocs.filter(
                (file) => file.mimetype.startsWith("image/") && file.path,
            );
        },
        canWriteTool() {
            return !!(this.currentUser.rightsObject.RIGHT_TOOLS_WRITE & RIGHTS_CONSTANTS.FULL);
        },
        toolKind() {
            return this.getToolKind(this.tool.tool_kind_id);
        },
    },
    watch: {
        "tool.tool_kind_id": function ttk(value) {
            if (value) {
                this.getTraitsList();
            }
        },
    },
    methods: {
        /*
        checkErrorsInfo() {
            const hasErrors = Object.keys(this.$refs).reduce(
                (acc, key) => {
                    if (Object.prototype.hasOwnProperty.call(this.$refs[key], "validate")) {
                        this.$refs[key].validate();
                        return acc || this.$refs[key].hasError;
                    }
                    return acc;
                },
                false,
            );

            return hasErrors;
        },
        */
        getTraitsList() {
            this.$store
                .dispatch("getToolKindTraitsValues", {
                    toolId: this.tool.id ? this.tool.id : null,
                    kindId: this.tool.tool_kind_id
                        ? this.tool.tool_kind_id
                        : null,
                })
                .then((res) => {
                    this.toolKindTraitsValues = res;
                })
                .catch(() => this.$q.notify({
                    type: "notify-failure",
                    message: "Ошибка",
                }));
        },
        addDoc(file) {
            this.filesListDocs.push(file);
        },
        delDoc(index) {
            this.filesListDocs.splice(index, 1);
            this.slide = 1;
        },
        addPicture(file) {
            this.filesListPictures.push(file);
        },
        delPicture(index) {
            this.filesListPictures.splice(index, 1);
        },
        tabChange(value) {
            if (this.tabCurrent === "info" && this.$refs.tabCommon.checkErrorsInfo()) {
                return;
            }

            this.tabCurrent = value;
        },
        setTraitValues(traitValues) {
            this.toolKindTraitsValues = traitValues;
        },
        setFilesListDocs(filesList) {
            this.filesListDocs = filesList;
        },
        setFilesListPictures(filesList) {
            this.filesListPictures = filesList;
        },
        //----------------------------------------------------------------------
        async show() {
            this.tool = {
                ...defaultTool,
                ...this.itemForEdit,
            };
            this.getTraitsList();
            this.toolSoftware = window.structuredClone(this.tool.tool_softwares);
            this.toolMoving = window.structuredClone(this.tool.tool_movings);
            this.toolPlace = window.structuredClone(this.tool.tool_places)
                .sort((a, b) => (a.date_time < b.date_time ? 1 : -1));
            this.tabCurrent = "info";

            if (this.itemForEdit && this.itemForEdit.id) {
                this.filesListDocs = await this.$store.dispatch(
                    "getFilesListFromDB",
                    {
                        section: "tool_docs",
                        id: this.itemForEdit.id,
                    },
                );

                this.filesListPictures = await this.$store.dispatch(
                    "getFilesListFromDB",
                    {
                        section: "tool_pictures",
                        id: this.itemForEdit.id,
                    },
                );
            }
            else {
                this.filesListDocs = [];
                this.filesListPictures = [];
            }

            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            if (this.tabCurrent === "info" && this.$refs.tabCommon.checkErrorsInfo()) {
                return;
            }

            this.$store.dispatch(
                "saveTool",
                {
                    tool: {
                        ...this.tool,
                        traits: this.toolKindTraitsValues,
                        id: this.createNew ? null : this.tool.id,
                        toolSoftware: this.toolSoftware,
                        toolMoving: this.toolMoving,
                        toolPlace: this.toolPlace,
                    },
                    filesList: this.filesListDocs,
                    filesListPictures: this.filesListPictures,
                },
            )
                .then(() => {
                    this.hide();
                })
                .catch(() => { });
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
