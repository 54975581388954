import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        reqWorks: [],
    },
    getters: {
        reqWorks: (state) => state.reqWorks,
        reqWorksCost: (state) => state.reqWorks.reduce(
            (acc, work) => (
                acc + (Number.isNaN(Number(work.cost)) ? 0 : +work.cost)
            ),
            0,
        ),
    },
    actions: {
        getRequestWorks({ commit }, reqId) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/request_works/${reqId}`)
                    .then((res) => {
                        commit("setRequestWorks", res.data.request_works);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        addRequestWork({ commit }) {
            commit("addRequestWork");
        },
        delRequestWork({ commit }, index) {
            commit("delRequestWork", index);
        },
        setRequestWork({ commit }, payload) {
            commit("setRequestWork", payload);
        },
    },
    mutations: {
        setRequestWorks(state, works) {
            state.reqWorks = works;
        },
        addRequestWork(state) {
            state.reqWorks.push({
                title: "",
                cost: 0,
            });
        },
        setRequestWork(state, { index, work }) {
            state.reqWorks = state.reqWorks
                .map((oldWork, ind) => (
                    ind === index
                        ? work
                        : oldWork
                ));
        },
        delRequestWork(state, index) {
            state.reqWorks.splice(index, 1);
        },
    },
};
