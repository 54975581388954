import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        manufacturers: [],
    },
    getters: {
        manufacturers: (state) => state.manufacturers,
        getManufacturerName: (state) => (manufacturerId) => {
            const manufacturer = state.manufacturers.find((m) => m.id === manufacturerId);
            return manufacturer
                ? manufacturer.title
                : null;
        },
    },
    actions: {
        getManufacturersFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/manufacturer`)
                    .then((res) => {
                        commit("setManufacturers", res.data.manufacturer);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveManufacturer({ dispatch }, manufacturer) {
            const method = manufacturer.id === null
                ? "post"
                : "put";
            const url = manufacturer.id === null
                ? `${siteHost}/api/manufacturer`
                : `${siteHost}/api/manufacturer/${manufacturer.id}`;

            return new Promise((resolve, reject) => {
                axios[method](
                    url,
                    manufacturer,
                )
                    .then(() => {
                        // TODO: do not request again
                        dispatch("getManufacturersFromDB");
                        resolve("Производитель оборудования сохранен");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        deleteManufacturer({ commit }, manufacturerId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/manufacturer/${manufacturerId}`)
                    .then(() => {
                        commit("deleteManufacturer", manufacturerId);
                        resolve("Производитель оборудования удален");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
    mutations: {
        setManufacturers(state, manufacturers) {
            state.manufacturers = manufacturers;
        },
        deleteManufacturer(state, manufacturerId) {
            const index = state.manufacturers.findIndex((m) => m.id === manufacturerId);
            if (index >= 0) {
                state.manufacturers.splice(index, 1);
            }
        },
    },
};
