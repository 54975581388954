<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-circular-progress
                    :value="valueProgress"
                    class="text-light-blue q-ma-md"
                    color="light-blue"
                    showValue
                    size="5.5em"
                />
            </div>

            <q-card-section>
                <div class="text-h6">
                    Форма отчета по отказам ПТС за период
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section style="max-height: 70vh;">
                <PoeDatePicker
                    ref="refDateStart"
                    v-model="dateStart"
                    :futureDateAllowed="false"
                    labelTitle="Дата начала отчета"
                />
                <PoeDatePicker
                    ref="refDateFinsh"
                    v-model="dateFinsh"
                    labelTitle="Дата окончания отчета"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    :icon="matSave"
                    color="primary"
                    flat
                    label="Сформировать"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    :icon="matCancel"
                    color="primary"
                    flat
                    label="Отменить"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import { createXls, saveXls } from "@/helpers/createXls";
import {
    REQUEST_KINDS_VALUES,
    REQUEST_STATUS_VALUES,
} from "@/store/constants";

export default {
    name: "DialogCreateFailureReportForm",
    components: {
        PoeDatePicker,
    },
    data() {
        return {
            dateStart: null,
            dateFinsh: null,
            showSpinner: false,
            valueProgress: 0,
        };
    },
    computed: {
        ...mapGetters({
            getOrgIdOfUnit: "getOrgIdOfUnit",
            getOrgProperty: "getOrgProperty",
            getRegionName: "getRegionName",
            getToolKindName: "getToolKindName",
            postTitle: "postTitle",
            currentUser: "currentUser",
            regions: "regions",
        }),
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            this.showSpinner = true;
            this.valueProgress = 0;

            this.$refs.refDateStart.validate();
            this.$refs.refDateFinsh.validate();

            if (this.$refs.refDateStart.hasError ||
                this.$refs.refDateFinsh.hasError) {
                return;
            }

            const { requests } = (await this.$store.dispatch(
                "getRequestsRepairAllRegions",
                {
                    date_beg: this.dateStart,
                    date_end: this.dateFinsh,
                },
            ));

            const requestItems = await this.getFailureReportFormItems(requests);
            const requestCounters = await this.getRequestCounters(requestItems);
            const outFileName = `Форма отчета по отказам ПТС за период с ${this.dateStart} по ${this.dateFinsh}.xlsx`;

            const replacement = {
                rep_date_start: this.dateStart,
                rep_date_finsh: this.dateFinsh,
                items: requestItems,
                arm_all: requestCounters.get("ARM"),
                server_all: requestCounters.get("SERVER"),
                vks_all: requestCounters.get("VKS"),
                femida_all: requestCounters.get("FEMIDA"),
                ibp_all: requestCounters.get("IBP"),
                kiosk_all: requestCounters.get("KIOSK"),
                printer_all: requestCounters.get("PRINTER"),
                net_all: requestCounters.get("NET"),
                other_all: requestCounters.get("OTHER"),
            };

            createXls(
                this.siteHost,
                "failure-report-form.xlsx",
                outFileName,
                replacement,
            )
                .then((failureReport) => {
                    saveXls(failureReport);
                });
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        async getFailureReportFormItems(requests) {
            const dataItems = new Map();
            // no need region code because region name contains region code
            // const zeroPad = (num, places) => String(num).padStart(places, "0");
            for (let i = 0; i < this.regions.length; i++) {
                const regionCode = this.regions[i].code;
                if (regionCode < 99) {
                    const item = {
                        region_id: regionCode,
                        region_name: this.regions[i].title,
                        arm_region: 0,
                        server_region: 0,
                        vks_region: 0,
                        femida_region: 0,
                        ibp_region: 0,
                        kiosk_region: 0,
                        printer_region: 0,
                        net_region: 0,
                        other_region: 0,
                    };
                    dataItems.set(regionCode, item);
                }
            }
            for (let i = 0; i < requests.length; i++) {
                this.valueProgress = Math.round((i / requests.length) * 100);
                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD &&
                    requests[i].request_status_id === REQUEST_STATUS_VALUES.CLOSED) {
                    const orgId = this.getOrgIdOfUnit(requests[i].unit_id);
                    const regionId = this.getOrgProperty(orgId, "region_id");
                    if (regionId < 99) {
                        const item = dataItems.get(regionId);
                        const tool = await this.$store.dispatch(
                            "getSingleToolFromDB",
                            requests[i].tool_id,
                        );
                        const toolKindName = this.getToolKindName(tool.tool_kind_id);
                        if (toolKindName.includes("Системный блок компьютера")) {
                            item.arm_region += 1;
                        }
                        else if (toolKindName.includes("Сервер")) {
                            item.server_region += 1;
                        }
                        else if (toolKindName.includes("Видеокодек")) {
                            item.vks_region += 1;
                        }
                        else if (toolKindName.includes("Микшер")) {
                            item.femida_region += 1;
                        }
                        else if (toolKindName.includes("Источник бесперебойного питания")) {
                            item.ibp_region += 1;
                        }
                        else if (toolKindName.includes("Информационный киоск")) {
                            item.kiosk_region += 1;
                        }
                        else if (toolKindName.includes("Принтер") || toolKindName.includes("МФУ") ||
                            toolKindName.includes("Копировально-множительный аппарат") || toolKindName.includes("Сканер")) {
                            item.printer_region += 1;
                        }
                        else if (toolKindName.includes("Коммутатор сетевой") || toolKindName.includes("Маршрутизатор")) {
                            item.net_region += 1;
                        }
                        else {
                            item.other_region += 1;
                        }
                        dataItems.set(regionId, item);
                    }
                }
            }
            const items = Array.from(dataItems.values());
            items.sort((a, b) => a.region_id - b.region_id);
            return items;
        },
        async getRequestCounters(requestItems) {
            let arm_count = 0;
            let server_count = 0;
            let vks_count = 0;
            let femida_count = 0;
            let ibp_count = 0;
            let kiosk_count = 0;
            let printer_count = 0;
            let other_count = 0;
            for (let i = 0; i < requestItems.length; i++) {
                arm_count += requestItems[i].arm_region;
                server_count += requestItems[i].server_region;
                vks_count += requestItems[i].vks_region;
                femida_count += requestItems[i].femida_region;
                ibp_count += requestItems[i].ibp_region;
                kiosk_count += requestItems[i].kiosk_region;
                printer_count += requestItems[i].printer_region;
                other_count += requestItems[i].other_region;
            }
            const counters = new Map();
            counters.set("ARM", arm_count);
            counters.set("SERVER", server_count);
            counters.set("VKS", vks_count);
            counters.set("FEMIDA", femida_count);
            counters.set("IBP", ibp_count);
            counters.set("KIOSK", kiosk_count);
            counters.set("PRINTER", printer_count);
            counters.set("NET", 0);
            counters.set("OTHER", other_count);
            return counters;
        },
    },
};
</script>
