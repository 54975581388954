<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Сервисный центр
                </div>
            </q-card-section>

            <q-separator />

            <div class="service-repiar-dialog-content">
                <q-select
                    v-model="item.region_id"
                    outlined
                    dense
                    :options="regions"
                    optionValue="id"
                    optionLabel="title"
                    mapOptions
                    emitValue
                    label="Регион"
                />
                <q-input
                    ref="refName"
                    v-model="item.title"
                    outlined
                    dense
                    label="Наименование"
                    :rules="[val => val && val.length > 0 || 'Укажите наименование']"
                    hideBottomSpace
                />
                <q-input
                    v-model="item.address"
                    outlined
                    dense
                    label="Адрес"
                />
                <q-input
                    v-model="item.telephone"
                    outlined
                    dense
                    label="Телефон"
                />
                <q-input
                    ref="refVncode"
                    v-model="item.chief_fio"
                    outlined
                    dense
                    label="ФИО руководителя"
                />
                <q-field
                    outlined
                    dense
                    class="q-gutter-sm"
                    label="Признак объекта"
                    stackLabel
                >
                    <q-radio
                        v-model="item.service_type"
                        :val="1"
                        label="Филиал ФГБУ ИАЦ Судебного департамента"
                    />
                    <q-radio
                        v-model="item.service_type"
                        :val="2"
                        label="Сервисный центр"
                    />
                </q-field>
            </div>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "DialogEditServiceRepair",
    props: {
        itemForEdit: {
            type: Object,
            required: true,
        },
    },
    emits: ["ok"],
    data() {
        return {
            item: {},
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
        }),
    },
    methods: {
        show() {
            this.item = { ...this.itemForEdit };
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            this.$refs.refName.validate();
            if (this.$refs.refName.hasError) {
                return;
            }

            this.$emit(
                "ok",
                this.item,
            );

            this.hide();
        },
    },
};
</script>
