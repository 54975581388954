<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Создание отчета по ремонтам №1
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section style="max-height: 70vh;">
                <SelectFilter
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    class="q-mb-sm"
                />
                <PoeDatePicker
                    ref="refDateStart"
                    v-model="dateStart"
                    labelTitle="Дата начала отчета"
                    :futureDateAllowed="false"
                />
                <PoeDatePicker
                    ref="refDateFinsh"
                    v-model="dateFinsh"
                    labelTitle="Дата окончания отчета"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сформировать"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import { createDoc, saveDoc } from "@/helpers/createDoc";
import {
    REQUEST_KINDS_VALUES,
    REQUEST_STATUS_VALUES,
    REPAIRS_TYPES_VALUES,
} from "@/store/constants";

export default {
    name: "DialogCreateRepairReport1",
    components: {
        SelectFilter,
        PoeDatePicker,
    },
    data() {
        return {
            regionId: null,
            dateStart: null,
            dateFinsh: null,
        };
    },
    computed: {
        ...mapGetters({
            appSettings: "appSettings",
            currentUser: "currentUser",
            getRegionName: "getRegionName",
            regions: "regions",
        }),
    },
    methods: {
        show() {
            this.regionId = this.currentUser.region_id;
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async onOkClick() {
            this.$refs.refDateStart.validate();
            this.$refs.refDateFinsh.validate();

            if (this.$refs.refDateStart.hasError ||
                this.$refs.refDateFinsh.hasError) {
                return;
            }

            const { requests } = (await this.$store.dispatch(
                "getRequestsRepairReport",
                {
                    region_id: this.regionId,
                    date_beg: this.dateStart,
                    date_end: this.dateFinsh,
                },
            ));

            const regionName = this.getRegionName(this.regionId);
            const outFileName = `${regionName}. Отчет по ремонтам №1 за период с ${this.dateStart} по ${this.dateFinsh}.docx`;

            const replacement = {
                rep_date_start: this.dateStart,
                rep_date_finsh: this.dateFinsh,
                rep_org_name: `${this.appSettings.title}`,
                service_contract_count: await this.getServiceContractCount(requests),
                service_money_count: await this.getServiceMoneyCount(requests),
                filial_money_count: await this.getFilialMoneyCount(requests),
                filial_spare_count: await this.getFilialSpareCount(requests),
                filial_hand_count: await this.getFilialHandCount(requests),
                no_guaranty_count: await this.getNoGuarantyCount(requests),
                guaranty_count: await this.getGuarantyCount(requests),
            };

            createDoc(
                "repairreport-1.docx",
                outFileName,
                replacement,
            )
                .then((repairreport) => {
                    saveDoc(repairreport);
                });
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        async getServiceContractCount(requests) {
            let count = 0;
            for (let i = 0; i < requests.length; i++) {
                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD) {
                    if (requests[i].request_status_id === REQUEST_STATUS_VALUES.PERFORMED ||
                        requests[i].request_status_id === REQUEST_STATUS_VALUES.CLOSED) {
                        if (requests[i].repair_type_id === REPAIRS_TYPES_VALUES.SERVICE_CONTRACT) {
                            count += 1;
                        }
                    }
                }
            }
            return count;
        },
        async getServiceMoneyCount(requests) {
            let count = 0;
            for (let i = 0; i < requests.length; i++) {
                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD) {
                    if (requests[i].request_status_id === REQUEST_STATUS_VALUES.PERFORMED ||
                        requests[i].request_status_id === REQUEST_STATUS_VALUES.CLOSED) {
                        if (requests[i].repair_type_id === REPAIRS_TYPES_VALUES.SERVICE_MONEY) {
                            count += 1;
                        }
                    }
                }
            }
            return count;
        },
        async getFilialMoneyCount(requests) {
            let count = 0;
            for (let i = 0; i < requests.length; i++) {
                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD) {
                    if (requests[i].request_status_id === REQUEST_STATUS_VALUES.PERFORMED ||
                        requests[i].request_status_id === REQUEST_STATUS_VALUES.CLOSED) {
                        if (requests[i].repair_type_id === REPAIRS_TYPES_VALUES.FILIAL_MONEY) {
                            count += 1;
                        }
                    }
                }
            }
            return count;
        },
        async getFilialSpareCount(requests) {
            let count = 0;
            for (let i = 0; i < requests.length; i++) {
                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD) {
                    if (requests[i].request_status_id === REQUEST_STATUS_VALUES.PERFORMED ||
                        requests[i].request_status_id === REQUEST_STATUS_VALUES.CLOSED) {
                        if (requests[i].repair_type_id === REPAIRS_TYPES_VALUES.FILIAL_SPARE) {
                            count += 1;
                        }
                    }
                }
            }
            return count;
        },
        async getFilialHandCount(requests) {
            let count = 0;
            for (let i = 0; i < requests.length; i++) {
                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD) {
                    if (requests[i].request_status_id === REQUEST_STATUS_VALUES.PERFORMED ||
                        requests[i].request_status_id === REQUEST_STATUS_VALUES.CLOSED) {
                        if (requests[i].repair_type_id === REPAIRS_TYPES_VALUES.FILIAL_HAND) {
                            count += 1;
                        }
                    }
                }
            }
            return count;
        },
        async getNoGuarantyCount(requests) {
            let count = 0;
            for (let i = 0; i < requests.length; i++) {
                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD) {
                    if (requests[i].request_status_id === REQUEST_STATUS_VALUES.PERFORMED ||
                        requests[i].request_status_id === REQUEST_STATUS_VALUES.CLOSED) {
                        if (requests[i].repair_type_id === REPAIRS_TYPES_VALUES.SERVICE_CONTRACT ||
                            requests[i].repair_type_id === REPAIRS_TYPES_VALUES.SERVICE_MONEY ||
                            requests[i].repair_type_id === REPAIRS_TYPES_VALUES.FILIAL_MONEY ||
                            requests[i].repair_type_id === REPAIRS_TYPES_VALUES.FILIAL_SPARE ||
                            requests[i].repair_type_id === REPAIRS_TYPES_VALUES.FILIAL_HAND) {
                            count += 1;
                        }
                    }
                }
            }
            return count;
        },
        async getGuarantyCount(requests) {
            let count = 0;
            for (let i = 0; i < requests.length; i++) {
                if (requests[i].request_kind_id === REQUEST_KINDS_VALUES.REPAIR_HARD) {
                    if (requests[i].request_status_id === REQUEST_STATUS_VALUES.PERFORMED ||
                        requests[i].request_status_id === REQUEST_STATUS_VALUES.CLOSED) {
                        if (requests[i].repair_type_id === REPAIRS_TYPES_VALUES.GUARANTY) {
                            count += 1;
                        }
                    }
                }
            }
            return count;
        },
    },
};
</script>
