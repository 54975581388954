export default {
    state: {
        toolsPagesCount: 1,
        toolsPageNumber: 1,
        reqsPagesCount: 1,
        reqsPageNumber: 1,
    },
    getters: {
        toolsPagesCount: (state) => state.toolsPagesCount,
        toolsPageNumber: (state) => state.toolsPageNumber,
        reqsPagesCount: (state) => state.reqsPagesCount,
        reqsPageNumber: (state) => state.reqsPageNumber,
    },
    actions: {
        setToolsPageNumber({ commit, dispatch }, pageNumber) {
            return new Promise((resolve, reject) => {
                commit("setToolsPageNumber", pageNumber);
                dispatch("getToolsFromDB")
                    .then(() => {
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        setReqsPageNumber({ commit, dispatch, getters }, pageNumber) {
            if (getters.reqsPageNumber !== pageNumber) {
                commit("setReqsPageNumber", pageNumber);
                dispatch("getRequestsFromDB");
            }
        },
    },
    mutations: {
        setToolsPagesCount(state, pagesCount) {
            state.toolsPagesCount = pagesCount;
        },
        setToolsPageNumber(state, pageNumber) {
            state.toolsPageNumber = pageNumber;
        },
        setReqsPagesCount(state, pagesCount) {
            state.reqsPagesCount = pagesCount;
        },
        setReqsPageNumber(state, pageNumber) {
            state.reqsPageNumber = pageNumber;
        },
    },
};
