<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Запросы к администратору
            <q-space />
            <FilterRequestsInner />
        </div>

        <div class="content-body">
            <div class="requests-inner-grid">
                <div class="cell cell-header cell-center">
                    №
                </div>
                <div class="cell cell-header cell-center">
                    Номер
                </div>
                <div class="cell cell-header cell-center">
                    Дата создания
                </div>
                <div class="cell cell-header cell-center">
                    Автор
                </div>
                <div class="cell cell-header cell-center">
                    Вид запроса
                </div>
                <div class="cell cell-header cell-center">
                    Запрос
                </div>
                <div class="cell cell-header cell-center">
                    Состояние
                </div>
                <div class="cell cell-header cell-center">
                    Комментарий
                </div>
                <div class="cell cell-header cell-center">
                    Файлы
                </div>
                <div class="cell cell-header" />
                <div
                    v-for="(request, requestIndex) in requestsInner"
                    :key="requestIndex"
                    class="row-wrapper"
                >
                    <div class="cell cell-center">
                        {{ requestIndex + 1 }}
                    </div>
                    <div class="cell cell-center">
                        {{ request.id }}
                    </div>
                    <div class="cell cell-center">
                        {{ $moment(request.date_create).format("DD.MM.YYYY HH:mm") }}
                    </div>
                    <div class="cell">
                        {{ getUserFIO(request.author_id) }}
                    </div>
                    <div class="cell">
                        {{ requestInnerKindTitle(request.request_inner_kind_id) }}
                    </div>
                    <div
                        class="cell"
                        style="overflow-wrap: anywhere;"
                        v-html="requestMessageFormat(request.message)"
                    />
                    <div class="cell cell-center">
                        {{ requestStateToString(request.state) }}
                    </div>
                    <div
                        class="cell"
                        style="overflow-wrap: anywhere;"
                    >
                        {{ request.response_message }}
                    </div>
                    <div
                        class="cell"
                        style="flex-direction: column;"
                    >
                        <template
                            v-for="(f, index) in request.files"
                            :key="index"
                        >
                            <q-img
                                v-if="f.mimetype.startsWith('image')"
                                :src="`${siteHost}/${f.path}`"
                                spinnerColor="white"
                                style="width: 90px"
                                @click="saveFile(f)"
                            />
                            <div
                                v-else
                                style="color: blue; text-decoration: underline; cursor: pointer;"
                                @click="saveFile(f)"
                            >
                                {{ f.file_name }}
                            </div>
                        </template>
                    </div>
                    <div class="cell cell-center">
                        <q-btn
                            round
                            :icon="matEdit"
                            size="sm"
                            class="poeso-table--control-button"
                        >
                            <q-menu autoClose>
                                <q-list
                                    v-for="(status, index) in requestStatuses"
                                    :key="index"
                                    style="min-width: 200px"
                                >
                                    <q-separator v-if="status.state == -1" />
                                    <q-item
                                        v-else
                                        clickable
                                        @click="updateRequest(request, status.state)"
                                    >
                                        <q-item-section>{{ status.label }}</q-item-section>
                                    </q-item>
                                </q-list>
                            </q-menu>
                        </q-btn>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { saveAs } from "file-saver";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";
import DialogSystemRequestResponse from "@/components/Dialogs/DialogSystemRequestResponse.vue";
import FilterRequestsInner from "@/components/Filters/FilterRequestsInner.vue";
import { REQUEST_INNER_KINDS } from "@/store/constants";

export default {
    name: "RequestInner",
    components: {
        FilterRequestsInner,
    },
    setup() {
        return {
            requestStatuses: [
                { state: 1, label: "Активно" },
                { state: 2, label: "Выполнено" },
                { state: 3, label: "Отложено" },
                { state: -1 },
                { state: 4, label: "Удалить" },
            ],
        };
    },
    computed: {
        ...mapGetters({
            requestsInner: "requestsInner",
            getUserFIO: "getUserFIO",
        }),
    },
    methods: {
        updateRequest(request, requestState) {
            if (requestState === 2) {
                this.$q.dialog({
                    component: DialogSystemRequestResponse,
                })
                    .onOk((response) => {
                        this.$store.dispatch(
                            "updateRequestInner",
                            {
                                ...response,
                                requestId: request.id,
                                state: requestState,
                            },
                        );
                    });
            }
            if (requestState === 4) {
                this.$q.dialog({
                    component: DialogConfirm,
                    componentProps: {
                        dialogMessage: `Удалить запрос от 
                            ${this.$moment(request.date_create).format("DD.MM.YYYY HH:mm")}?`,
                    },
                })
                    .onOk(() => {
                        this.$store.dispatch(
                            "deleteRequestInner",
                            {
                                requestId: request.id,
                            },
                        );
                    });
            }
            if (requestState === 1 || requestState === 3) {
                this.$store.dispatch(
                    "updateRequestInner",
                    {
                        requestId: request.id,
                        state: requestState,
                    },
                );
            }
        },
        requestStateToString(requestState) {
            const retVal = this.requestStatuses.find((s) => s.state === requestState);
            return retVal ? retVal.label : "???";
        },
        requestMessageFormat(message) {
            return message.replace(/\n/g, "<br />");
        },
        saveFile(picture) {
            saveAs(`${this.siteHost}/${picture.path}`, picture.file_name);
        },
        requestInnerKindTitle(reqId) {
            const reqKind = REQUEST_INNER_KINDS.find((rk) => rk.value === reqId);
            return reqKind
                ? reqKind.label
                : "";
        },
    },
};
</script>
