import axios from "axios";

import { RIGHTS_CONSTANTS, siteHost } from "./constants";

export default {
    state: {
        roles: [],
        hoverRoleIndex: -1,
        hoverRightIndex: -1,
    },
    getters: {
        roles: (state) => state.roles,
        roleName: (state) => (roleId) => {
            const role = state.roles.find((r) => r.id === roleId);
            return role ? role.title : "";
        },
        rolesWithRightIds: (state) => (rightSigner) => state.roles
            .filter((r) => r.rightsObject[rightSigner] === RIGHTS_CONSTANTS.FULL)
            .map((r) => r.id),
        hoverRoleIndex: (state) => state.hoverRoleIndex,
        hoverRightIndex: (state) => state.hoverRightIndex,
    },
    actions: {
        getRolesListFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/roles`)
                    .then((res) => {
                        // console.log(res.data.roles);
                        commit(
                            "setRoles",
                            res.data.roles.map((role) => ({
                                ...role,
                                rightsObject: role.rights_object
                                    ? JSON.parse(role.rights_object)
                                    : {},
                            })),
                        );
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        createRole({ dispatch }, role) {
            return new Promise((resolve, reject) => {
                axios.post(
                    `${siteHost}/api/roles`,
                    role,
                )
                    .then((res) => {
                        dispatch("getRolesListFromDB");
                        resolve(res.data.message);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        deleteRole({ dispatch }, roleId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/roles/${roleId}`)
                    .then((res) => {
                        dispatch("getRolesListFromDB");
                        resolve(res.data.message);
                    })
                    .catch((err) => {
                        reject(err.response.data.message);
                    });
            });
        },
        updateRole({ dispatch }, role) {
            return new Promise((resolve, reject) => {
                axios.put(
                    `${siteHost}/api/roles/${role.id}`,
                    role,
                )
                    .then((res) => {
                        dispatch("getRolesListFromDB");
                        resolve(res.data.message);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        setRoleAccessLevel({ dispatch, getters }, { id, value }) {
            const role = getters.roles.find((r) => r.id === id);
            if (role) {
                axios.put(
                    `${siteHost}/api/roles/${id}`,
                    {
                        access_level: value,
                    },
                )
                    .then(() => {
                        dispatch("getRolesListFromDB");
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        setRoleRights({ dispatch, getters }, { id, key, value }) {
            return new Promise((resolve, reject) => {
                const role = getters.roles.find((r) => r.id === id);
                if (role) {
                    role.rightsObject[key] = value;

                    axios.put(
                        `${siteHost}/api/roles/${id}`,
                        {
                            rights_object: JSON.stringify(role.rightsObject),
                        },
                    )
                        .then(async () => {
                            await dispatch("getRolesListFromDB");
                            resolve();
                        })
                        .catch((err) => {
                            reject(err);
                        });
                }
                else {
                    resolve();
                }
            });
        },
        setHovers({ commit }, payload) {
            commit("setHovers", payload);
        },
    },
    mutations: {
        setRoles(state, roles) {
            state.roles = roles;
        },
        setHovers(state, { rightIndex, roleIndex }) {
            state.hoverRightIndex = rightIndex;
            state.hoverRoleIndex = roleIndex;
        },
    },
};
