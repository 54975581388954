<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Создание группы заявок
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <SelectFilter
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    clearable
                    class="q-pb-sm"
                />
                <q-select
                    ref="refUnits"
                    v-model="unitsIds"
                    outlined
                    dense
                    :options="unitsFiltered"
                    optionValue="id"
                    optionLabel="name"
                    mapOptions
                    emitValue
                    label="Объект"
                    multiple
                    clearable
                    class="q-pb-sm"
                    hideBottomSpace
                    :rules="[val => val.length > 0 || 'Укажите объекты']"
                    @clear="() => unitsIds = []"
                >
                    <template #option="{ itemProps, opt, selected, toggleOption }">
                        <q-item v-bind="itemProps">
                            <q-item-section>
                                <q-item-label>
                                    {{ opt.name }}
                                </q-item-label>
                                <q-item-label caption>
                                    {{ opt.address }}
                                </q-item-label>
                            </q-item-section>
                            <q-item-section side>
                                <q-checkbox
                                    :modelValue="selected"
                                    @update:model-value="toggleOption(opt)"
                                />
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>

                <q-input
                    ref="refText"
                    v-model="requestText"
                    outlined
                    dense
                    class="q-mb-sm"
                    label="Описание неисправности (проблемы)"
                    type="textarea"
                    hideBottomSpace
                    lazyRules
                    :rules="[val => (val && val.length > 5) || 'Введите описание']"
                />

                <Documents
                    fileSection="request"
                    :filesList="filesList"
                    :addDocToList="addDoc"
                    :deleteDoc="delDoc"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {
    RIGHTS_CONSTANTS,
} from "@/store/constants";
// import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import Documents from "@/components/Documents/Documents.vue";

export default {
    name: "DialogCreateMultipleRequests",
    components: {
        // PoeDatePicker,
        SelectFilter,
        Documents,
    },
    emits: ["ok"],
    data() {
        return {
            // requestsDate: null,
            regionId: null,
            unitsIds: [],
            requestText: "",
            filesList: [],
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            unitsFull: "unitsFull",
            currentUser: "currentUser",
        }),
        unitsFiltered() {
            const uf = this.regionId
                ? this.unitsFull.filter((u) => u.region_id === this.regionId)
                : this.unitsFull;

            return !!(this.currentUser.rightsObject.RIGHT_REQUEST_READ & RIGHTS_CONSTANTS.ALL)
                ? uf
                : uf.filter((u) => this.currentUser.units.includes(u.id));
        },
    },
    watch: {
        regionId() {
            this.unitsIds = [];
        },
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            this.$refs.refUnits.validate();
            this.$refs.refText.validate();

            if (
                this.$refs.refUnits.hasError ||
                this.$refs.refText.hasError
            ) {
                return;
            }

            this.$store.dispatch(
                "saveMultipleRequests",
                {
                    // requestsDate: this.requestsDate,
                    unitsIds: this.unitsIds,
                    requestText: this.requestText,
                    filesList: this.filesList,
                },
            );

            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        addDoc(file) {
            this.filesList.push(file);
        },
        delDoc(index) {
            this.filesList.splice(index, 1);
        },
    },
};
</script>
