<template>
    <div class="cell cell-center">
        {{ index + 1 }}
    </div>
    <div class="cell">
        {{ spare.title }}
    </div>
    <div
        v-if="allowViewCost"
        class="cell"
    >
        {{ spare.cost }}
    </div>
    <div class="cell">
        <q-input
            v-model="amount"
            borderless
            dense
            class="col"
            type="number"
            :disable="!canBeEdited"
        />
    </div>
    <div class="cell cell-center">
        <q-btn
            round
            :icon="matDelete"
            size="sm"
            class="poeso-table--control-button"
            :disable="!canBeEdited"
            @click="() => removeSpare(index)"
        />
    </div>
</template>

<script>
export default {
    name: "RequestSpareRow",
    props: {
        spare: {
            type: Object,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
        canBeEdited: {
            type: Boolean,
            required: true,
        },
        allowViewCost: {
            type: Boolean,
            required: true,
        },
    },
    computed: {
        amount: {
            get() {
                return this.spare.amount;
            },
            set(value) {
                this.$store.dispatch(
                    "setRequestSpare",
                    {
                        index: this.index,
                        spare: {
                            ...this.spare,
                            amount: value,
                        },
                    },
                );
            },
        },
    },
    methods: {
        removeSpare(index) {
            this.$store.dispatch("delRequestSpare", index);
        },
    },
};
</script>
