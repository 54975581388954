<template>
    <q-dialog
        v-model="showFilter"
        position="top"
    >
        <q-card style="width: 70%; max-width: 70%;">
            <div class="filter-grid--tool">
                <SelectFilter
                    v-model="regionId"
                    :options="regions"
                    label="Регион"
                    clearable
                />
                <SelectFilter
                    v-model="unitId"
                    :options="unitsFiltered"
                    optionLabel="name"
                    label="Объект"
                    clearable
                    extendedScope
                    scopeAttr1="name"
                    scopeAttr2="not_needed"
                    scopeAttr3="address"
                />
                <SelectFilter
                    v-model="toolSetNameId"
                    :options="toolsSetsNamesCodes"
                    label="Вид комплекта оборудования"
                    optionLabel="name"
                    clearable
                    outlined
                    dense
                />
                <q-select
                    v-model="toolKindId"
                    outlined
                    dense
                    clearable
                    :options="toolsKinds"
                    optionValue="id"
                    optionLabel="title"
                    mapOptions
                    emitValue
                    label="Вид оборудования"
                />
                <q-select
                    v-model="manufacturerId"
                    outlined
                    dense
                    clearable
                    :options="manufacturers"
                    optionValue="id"
                    optionLabel="title"
                    mapOptions
                    emitValue
                    label="Производитель оборудования"
                />
                <q-input
                    v-model="toolModel"
                    outlined
                    dense
                    clearable
                    label="Наименование"
                />

                <PoeDatePicker
                    v-model="toolWorkInDtFrom"
                    labelTitle="Дата ввода в эксплуатацию от"
                    emptyDateAllowed
                    classOuter=""
                    :doValidate="false"
                />
                <PoeDatePicker
                    v-model="toolWorkInDtTill"
                    labelTitle="Дата ввода в эксплуатацию по"
                    emptyDateAllowed
                    classOuter=""
                    :doValidate="false"
                />
                <q-select
                    v-model="toolAge"
                    outlined
                    dense
                    clearable
                    :options="toolsAges"
                    optionValue="value"
                    optionLabel="title"
                    mapOptions
                    emitValue
                    label="Срок использования"
                />
                <q-select
                    ref="refUnit"
                    v-model="toolState"
                    outlined
                    dense
                    :options="TOOLS_STATES"
                    optionValue="id"
                    optionLabel="title"
                    mapOptions
                    emitValue
                    label="Состояние"
                    clearable
                />

                <q-input
                    v-model="toolSerialNumber"
                    outlined
                    dense
                    clearable
                    label="Серийный номер"
                >
                    <template #append>
                        <q-checkbox v-model="tollSerialExact">
                            <q-tooltip
                                :delay="250"
                                class="bg-amber text-black text-body2"
                            >
                                Точное совпадение
                            </q-tooltip>
                        </q-checkbox>
                    </template>
                </q-input>
                <q-input
                    v-model="toolInventarNumber"
                    outlined
                    dense
                    clearable
                    label="Инвентарный номер"
                >
                    <template #append>
                        <q-checkbox v-model="tollInventarExact">
                            <q-tooltip
                                :delay="250"
                                class="bg-amber text-black text-body2"
                            >
                                Точное совпадение
                            </q-tooltip>
                        </q-checkbox>
                    </template>
                </q-input>
                <q-select
                    v-model="toolOwnerTypeId"
                    outlined
                    dense
                    :options="TOOLS_OWNERS_TYPES"
                    mapOptions
                    emitValue
                    clearable
                    label="Принадлежность оборудования"
                />
                <q-input
                    v-model="toolDescription"
                    outlined
                    dense
                    clearable
                    label="Примечание"
                />
                <q-toggle
                    v-model="toolInSet"
                    label="Входит в комплект"
                />
            </div>

            <div class="filter-controls">
                <q-btn
                    label="Очистить"
                    @click="$store.dispatch('setToolsFilter', null)"
                />
                <q-btn
                    label="Закрыть"
                    @click="showFilter = false"
                />
            </div>
        </q-card>
    </q-dialog>

    <q-btn
        round
        :icon="matSearch"
        @click="showFilter = !showFilter"
    />
</template>

<script>
import { mapGetters } from "vuex";
import {
    RIGHTS_CONSTANTS,
    TOOLS_STATES,
    TOOLS_OWNERS_TYPES,
} from "@/store/constants";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import SelectFilter from "@/components/Common/SelectFilter.vue";

function generateProperty(property) {
    return {
        get() {
            return this.toolsFilter[property];
        },
        set(value) {
            const v = typeof value === "string"
                ? value.trim()
                : value;

            this.$store.dispatch(
                "setToolsFilter",
                {
                    ...this.toolsFilter,
                    [property]: v,
                },
            );
        },
    };
}

export default {
    name: "FilterTools",
    components: {
        PoeDatePicker,
        SelectFilter,
    },
    setup() {
        return {
            TOOLS_STATES,
            TOOLS_OWNERS_TYPES,
        };
    },
    data() {
        return {
            showFilter: false,
        };
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            unitsFull: "unitsFull",
            toolsSetsNamesCodes: "toolsSetsNamesCodes",
            toolsKinds: "toolsKinds",
            toolsAges: "toolsAges",
            toolsFilter: "toolsFilter",
            currentUser: "currentUser",
            manufacturers: "manufacturers",
        }),
        unitsFiltered() {
            const uf = this.regionId
                ? this.unitsFull.filter((u) => u.region_id === this.regionId)
                : this.unitsFull;

            return !!(this.currentUser.rightsObject.RIGHT_TOOLS_READ & RIGHTS_CONSTANTS.ALL)
                ? uf
                : uf.filter((u) => this.currentUser.units.includes(u.id));
        },
        regionId: {
            get() {
                return this.toolsFilter.region_id;
            },
            set(value) {
                this.$store.dispatch(
                    "setToolsFilter",
                    {
                        ...this.toolsFilter,
                        region_id: value,
                        unit_id: value ? null : this.unitId,
                    },
                );
            },
        },
        unitId: generateProperty("unit_id"),
        toolSetNameId: generateProperty("tool_set_name_id"),
        toolSerialNumber: generateProperty("serial_number"),
        tollSerialExact: generateProperty("serial_exact"),
        toolInventarNumber: generateProperty("inventar_number"),
        tollInventarExact: generateProperty("inventar_exact"),
        toolAge: generateProperty("tool_age"),
        manufacturerId: generateProperty("manufacturer_id"),
        toolModel: generateProperty("tool_model"),
        toolModelExact: generateProperty("tool_model_exact"),
        toolInSet: generateProperty("tool_in_set"),
        toolWorkInDtFrom: generateProperty("tool_work_in_dt_from"),
        toolWorkInDtTill: generateProperty("tool_work_in_dt_till"),
        toolState: generateProperty("tool_state"),
        toolOwnerTypeId: generateProperty("tool_owner_type_id"),
        toolDescription: generateProperty("tool_description"),
        toolKindId: {
            get() {
                return this.toolsFilter.tool_kind_ids
                    ? this.toolsFilter.tool_kind_ids[0]
                    : null;
            },
            set(value) {
                this.$store.dispatch(
                    "setToolsFilter",
                    {
                        ...this.toolsFilter,
                        tool_kind_ids: value ? [value] : null,
                    },
                );
            },
        },
    },
};
</script>
