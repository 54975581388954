<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Типы организаций
            ({{ orgKinds.length }})
        </div>
        <div class="content-body organizations-kinds-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Обозначение КСА
            </div>
            <div class="cell cell-header cell-center">
                Тип организации
            </div>
            <div class="cell cell-header cell-center">
                Количество подписантов
            </div>
            <div class="cell cell-header cell-center">
                Филиал
            </div>
            <div class="cell cell-header">
                <q-btn
                    round
                    :icon="matAdd"
                    color="primary"
                    size="0.7rem"
                    @click="editItem(null)"
                />
            </div>
            <!-- data -->
            <div
                v-for="(orgKind, index) in orgKinds"
                :key="index"
                class="row-wrapper"
                @click="editItem(index)"
            >
                <div class="cell cell-center">
                    {{ index + 1 }}
                </div>
                <div class="cell">
                    {{ orgKind.ksa_title }}
                </div>
                <div class="cell">
                    {{ orgKind.title }}
                </div>
                <div class="cell cell-center">
                    {{ orgKind.signers_count }}
                </div>
                <div class="cell cell-center">
                    <q-icon
                        v-if="orgKind.is_filial"
                        :name="matCheck"
                        size="1.2rem"
                    />
                </div>
                <div class="cell">
                    <q-btn
                        round
                        :icon="matDelete"
                        size="0.7rem"
                        @click="(e) => delItem(e, index)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";
import DialogEditOrgKind from "@/components/Dialogs/DialogEditOrgKind.vue";

export default {
    name: "OrganizationsKinds",
    computed: {
        ...mapGetters({
            orgKinds: "orgKinds",
        }),
    },
    methods: {
        editItem(index) {
            this.$q.dialog({
                component: DialogEditOrgKind,
                componentProps: {
                    itemForEdit: {
                        ksa_title: `${index !== null ? this.orgKinds[index].ksa_title : ""}`,
                        title: `${index !== null ? this.orgKinds[index].title : ""}`,
                        is_filial: index !== null ? !!this.orgKinds[index].is_filial : false,
                        signers_count: index !== null ? this.orgKinds[index].signers_count : 2,
                    },
                },
            })
                .onOk((value) => {
                    this.$store.dispatch("saveOrgKind", {
                        ...value,
                        id: index !== null ? this.orgKinds[index].id : null,
                    })
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
        delItem(event, index) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить тип организации
                         "${this.orgKinds[index].title}"?`,
                },
            })
                .onOk(() => {
                    this.$store.dispatch("delOrgKind", this.orgKinds[index].id);
                });
        },
    },
};
</script>
