<template>
    <div class="cell-span-col-2">
        Для заявок по вопросам ОПО / СПО
    </div>
    <q-select
        v-model="softTypeId"
        outlined
        dense
        :options="programs"
        optionValue="id"
        optionLabel="title"
        mapOptions
        emitValue
        label="Программное обеспечение"
        clearable
        class="cell-start-row"
    />
</template>

<script>
import { mapGetters } from "vuex";

function generateProperty(property) {
    return {
        get() {
            return this.filterRequestsSoft[property];
        },
        set(value) {
            const v = typeof value === "string"
                ? value.trim()
                : value;

            this.$store.dispatch(
                "setFilterRequestsSoft",
                {
                    ...this.filterRequestsSoft,
                    [property]: v,
                },
            );
        },
    };
}

export default {
    name: "FilterRequestsSoft",
    computed: {
        ...mapGetters({
            programs: "programs",
            filterRequestsSoft: "filterRequestsSoft",
        }),
        softTypeId: generateProperty("program_id"),
    },
};
</script>
