<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div
                v-if="showSpinner"
                class="req-dialog-cover"
            >
                <q-spinner-grid
                    color="primary"
                    size="5.5em"
                />
            </div>
            <q-card-section>
                <div class="text-h6">
                    Проверка моделей учетных единиц
                </div>
            </q-card-section>

            <q-separator />

            <q-btn
                flat
                label="Проверить"
                color="primary"
                @click="checkToolsModels"
            />

            <div
                v-if="result"
                class="dialog-common-grid-col-2"
            >
                <div class="cell cell-right">
                    Всего учетных единиц:
                </div>
                <div class="cell">
                    {{ result.toolsAll }}
                </div>
                <div class="cell cell-right">
                    Количество учетных единиц без моделей:
                </div>
                <div class="cell">
                    {{ result.toolsWithoutModel }}
                </div>
                <div class="cell cell-right">
                    Количество учетных единиц с незаполненными характеристиками:
                </div>
                <div class="cell">
                    {{ result.toolsWithoutTraits }}
                </div>
                <div class="cell cell-span-col-2 cell-center">
                    <q-btn
                        flat
                        label="Копировать характеристики"
                        color="primary"
                        @click="fixToolsModels"
                    />
                </div>
            </div>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-close-popup
                    flat
                    label="Закрыть"
                    color="primary"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import axios from "axios";

export default {
    name: "DialogCheckTools",
    data() {
        return {
            result: null,
            showSpinner: false,
        };
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async checkToolsModels() {
            this.showSpinner = true;
            const res = await axios.get(`${this.siteHost}/api/tools_models_check`);
            console.log(res.data);
            this.result = res.data;
            this.showSpinner = false;
        },
        async fixToolsModels() {
            this.showSpinner = true;
            await axios.get(`${this.siteHost}/api/tools_models_fix`);
            this.result = null;
            this.showSpinner = false;
        },
    },
};
</script>
