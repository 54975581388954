<template>
    <div class="cell-span-col-2">
        Для заявок на обучение пользователей
    </div>
    <q-select
        v-model="learningTypeId"
        outlined
        dense
        :options="LEARNING_TYPES"
        mapOptions
        emitValue
        label="Вид обучения"
        clearable
        class="cell-start-row"
    />
</template>

<script>
import { mapGetters } from "vuex";
import {
    LEARNING_TYPES,
} from "@/store/constants";

function generateProperty(property) {
    return {
        get() {
            return this.filterRequestsLearning[property];
        },
        set(value) {
            const v = typeof value === "string"
                ? value.trim()
                : value;

            this.$store.dispatch(
                "setFilterRequestsLearning",
                {
                    ...this.filterRequestsLearning,
                    [property]: v,
                },
            );
        },
    };
}

export default {
    name: "FilterRequestsLearning",
    setup() {
        return {
            LEARNING_TYPES,
        };
    },
    computed: {
        ...mapGetters({
            filterRequestsLearning: "filterRequestsLearning",
        }),
        learningTypeId: generateProperty("program_id"),
    },
};
</script>
