<template>
    <div
        class="row-wrapper"
        @click="$emit('startEdit', toolIndex)"
    >
        <div class="cell cell-center">
            {{ toolIndex + 1 }}
        </div>
        <div class="cell">
            {{ toolKind.title }}
        </div>
        <div class="cell">
            {{ getManufacturerName(toolModel.manufacturer_id) }}
        </div>
        <div class="cell">
            {{ toolModel.model }}
        </div>
        <div :class="[
            'cell cell-center',
            needTraits ? 'cell-warning' : '',
        ]">
            {{ toolKind.traits ? toolKind.traits.length : "нет" }} /
            {{ toolModelTraits.length }}
        </div>
        <div class="cell cell-center">
            {{ toolModelDocsLength }} / {{ toolModelPicsLength }}
        </div>
        <div class="cell">
            <q-btn
                round
                :icon="matDelete"
                size="0.7rem"
                @click="(e) => deleteItem(e, toolIndex)"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "ToolsModelsRow",
    props: {
        toolIndex: {
            type: Number,
            required: true,
        },
        toolModel: {
            type: Object,
            required: true,
        },
    },
    emits: ["startEdit", "deleteItem"],
    computed: {
        ...mapGetters({
            getToolKind: "getToolKind",
            getManufacturerName: "getManufacturerName",
        }),
        toolKind() {
            return this.getToolKind(this.toolModel.tool_kind_id);
        },
        toolModelTraits() {
            return this.toolModel.tool__tool_trait__values
                ? this.toolModel.tool__tool_trait__values.filter((ttv) => !!ttv.trait_value_id)
                : [];
        },
        needTraits() {
            if (!this.toolKind.traits) {
                return false;
            }

            return this.toolKind.traits.length !== this.toolModelTraits.length;
        },
        toolModelDocsLength() {
            return this.toolModel.toolModelFiles
                ? this.toolModel.toolModelFiles.filter((f) => f.section === "tool_docs").length
                : "";
        },
        toolModelPicsLength() {
            return this.toolModel.toolModelFiles
                ? this.toolModel.toolModelFiles.filter((f) => f.section === "tool_model_pictures").length
                : "";
        },
    },
    methods: {
        deleteItem(event) {
            event.stopPropagation();

            this.$emit("deleteItem", this.toolIndex);
        },
    },
};
</script>
