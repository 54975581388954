<template>
    <div class="content content__flex">
        <img
            src="../assets/logo.jpg"
            alt="Информационная система ФГБУ ИАЦ Судебного департамента (ИС ИАЦ)"
            style="width: 351px; height: 351px;"
        >
        <h1 style="margin-bottom: 0;">
            Информационная система ФГБУ ИАЦ Судебного департамента (ИС ИАЦ)
        </h1>
        <h3>{{ IAC_VERSION }} ({{ IAC_DATE }})</h3>
    </div>
</template>
<script>
import { IAC_DATE } from "@/assets/version";
import { version } from "../../package.json";

export default {
    name: "Home",
    setup() {
        return {
            IAC_VERSION: version,
            IAC_DATE,
        };
    },
};
</script>
