<template>
    <div class="content">
        <div class="content-title">
            Исполнители Техподдержки
            ({{ usersHotline.length }})
            <q-space />
            <FilterUsersHotline />
        </div>
        <div class="users-hotline-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Исполнитель
            </div>
            <div class="cell cell-header cell-center">
                Телефон
            </div>
            <div class="cell cell-header cell-center">
                Email
            </div>
            <div class="cell cell-header cell-center">
                ПО
            </div>
            <div class="cell cell-header cell-center">
                <q-btn
                    v-if="canBeEdited"
                    round
                    :icon="matAdd"
                    size="0.7rem"
                    color="primary"
                    @click="startEdit(null)"
                />
            </div>
            <!-- Литера,Организация,Адрес, Реквизиты, , Примечание -->
            <!-- data -->
            <div
                v-for="(user, index) in usersHotline"
                :key="index"
                class="row-wrapper"
                @click="startEdit(index)"
            >
                <div class="cell cell-center">
                    {{ index + 1 }}
                </div>
                <div class="cell">
                    {{ user.surname }}
                </div>
                <div class="cell">
                    {{ user.phone }}
                </div>
                <div class="cell">
                    {{ user.email }}
                </div>
                <div class="cell">
                    {{ userProgramsList(user.users_programs) }}
                </div>
                <div class="cell">
                    <q-btn
                        v-if="canBeEdited"
                        round
                        :icon="matDelete"
                        size="0.7rem"
                        @click="(e) => deleteItem(e, user)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RIGHTS_CONSTANTS } from "@/store/constants";
import DialogEditUserHotline from "@/components/Dialogs/DialogEditUserHotline.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";
import FilterUsersHotline from "@/components/Filters/FilterUsersHotline.vue";

export default {
    name: "UsersHotline",
    components: {
        FilterUsersHotline,
    },
    computed: {
        ...mapGetters({
            usersHotline: "usersHotline",
            programs: "programs",
            currentUser: "currentUser",
        }),
        canBeEdited() {
            return this.currentUser.rightsObject.RIGHT_USERS_HL === RIGHTS_CONSTANTS.FULL;
        },
    },
    methods: {
        startEdit(index) {
            this.$q.dialog({
                component: DialogEditUserHotline,
                componentProps: {
                    user: index === null
                        ? { users_programs: [] }
                        : { ...this.usersHotline[index] },
                },
            });
        },
        deleteItem(event, user) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить учетную запись 
                        "${user.surname} ${user.name} ${user.patronymic}"?`,
                },
            })
                .onOk(() => {
                    // TODO: move notifications into store
                    this.$store.dispatch("deleteUser", user.id)
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
        userProgramsList(pids) {
            return pids
                .map((pid) => {
                    const program = this.programs.find((p) => p.id === pid.program_id);

                    return program ? program.title : "";
                })
                .sort((a, b) => (a.toLowerCase() < b.toLowerCase() ? -1 : 1))
                .join(", ");
        },
    },
};
</script>
