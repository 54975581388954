import PizZip from "pizzip";
import JSzip from "jszip";
import JSzipUtils from "jszip-utils";
import Docxtemplater from "docxtemplater";
import { saveAs } from "file-saver";
import { siteHost } from "@/store/constants";

export function createDoc(
    templateName,
    fileName,
    replacement,
) {
    const mimeType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";

    return new Promise((resolve, reject) => {
        JSzipUtils.getBinaryContent(
            `${siteHost}/public/templates/${templateName}?${Date.now()}`,
            async (error, content) => {
                if (error) {
                    reject(error);
                }

                const zip = new PizZip(content);
                const doc = new Docxtemplater(zip, { linebreaks: true });

                try {
                    doc.render(replacement);
                }
                catch (err) {
                    /*
                    const e = {
                        message: err.message,
                        name: err.name,
                        stack: err.stack,
                        properties: err.properties,
                    };
                    */
                    // The error thrown here contains additional information
                    // when logged with JSON.stringify (it contains a property object).
                    reject(error);
                }

                const blob = doc.getZip().generate({
                    type: "blob",
                    mimeType,
                    compression: "DEFLATE",
                });
                const fileDoc = new File(
                    [blob],
                    fileName,
                    {
                        type: mimeType,
                    },
                );
                resolve(fileDoc);
            },
        );
    });
}

export function saveDoc(docForSave) {
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // If IE, you must uses a different method.
        window.navigator.msSaveOrOpenBlob(docForSave, docForSave.name);
    }
    else {
        const url2 = window.URL.createObjectURL(docForSave);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url2;
        a.download = docForSave.name;
        a.click();
        window.URL.revokeObjectURL(url2);
        document.body.removeChild(a);
    }
}

const pixUnitX = 9509.125;
const pixUnitY = 9512.30;
const imageWidth = 400;

function picTag(embedId) {
    return `
<w:p><w:r><w:drawing>
    <wp:inline distT="0" distB="0" distL="0" distR="0">
        <wp:extent cx="${pixUnitX * imageWidth}" cy="${pixUnitY * 150}"/>
        <wp:effectExtent l="0" t="0" r="0" b="0"/>
        <wp:docPr id="1" name="Picture 1" descr="imageSign"/>
        <wp:cNvGraphicFramePr>
            <a:graphicFrameLocks xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main" noChangeAspect="1"/>
        </wp:cNvGraphicFramePr>
        <a:graphic xmlns:a="http://schemas.openxmlformats.org/drawingml/2006/main">
            <a:graphicData uri="http://schemas.openxmlformats.org/drawingml/2006/picture">
                <pic:pic xmlns:pic="http://schemas.openxmlformats.org/drawingml/2006/picture">
                    <pic:nvPicPr>
                        <pic:cNvPr id="1" name="Picture 1" descr="imageSign"/>
                        <pic:cNvPicPr>
                            <a:picLocks noChangeAspect="1"/>
                        </pic:cNvPicPr>
                    </pic:nvPicPr>
                    <pic:blipFill>
                        <a:blip r:embed="${embedId}"/>
                        <a:stretch>
                            <a:fillRect/>
                        </a:stretch>
                    </pic:blipFill>
                    <pic:spPr>
                        <a:xfrm>
                            <a:off x="0" y="0"/>
                            <a:ext cx="${pixUnitX * imageWidth}" cy="${pixUnitY * 150}"/>
                        </a:xfrm>
                        <a:prstGeom prst="rect">
                            <a:avLst/>
                        </a:prstGeom>
                    </pic:spPr>
                </pic:pic>
            </a:graphicData>
        </a:graphic>
    </wp:inline>
</w:drawing></w:r></w:p>
    `;
}

function getImage(
    thumbprint = "XXXX",
    owner = "Jhon Doe",
    dateStart = "01.01.2000",
    dateFinsh = "02.02.2000",
) {
    return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        canvas.width = imageWidth;
        canvas.height = 150;
        const ctx = canvas.getContext("2d");

        ctx.strokeStyle = "blue";
        ctx.fillStyle = "blue";
        ctx.beginPath();
        ctx.roundRect(5, 5, imageWidth - 10, 140, [5]);
        ctx.stroke();

        // header
        const headerLines = ["ДОКУМЕНТ ПОДПИСАН", "ЭЛЕКТРОННОЙ ПОДПИСЬЮ"];
        ctx.font = "16px serif";
        // ctx.textAlign = "center";
        ctx.textBaseline = "top";

        let top = 16;
        for (let i = 0; i < headerLines.length; i++) {
            const hl = headerLines[i];

            const ts = ctx.measureText(hl);
            ctx.fillText(hl, (imageWidth - ts.width) / 2, top);
            top += 24;
        }

        const dataLines = [
            `Сертификат: ${thumbprint}`,
            `Владелец: ${owner}`,
            `Действителен с ${dateStart} до ${dateFinsh}`,
        ];

        top += 16;
        ctx.font = "12px serif";
        for (let i = 0; i < dataLines.length; i++) {
            const dl = dataLines[i];

            ctx.fillText(dl, 10, top);
            top += 16;
        }

        canvas.toBlob((blob) => {
            resolve(blob);
        });
    });
}

export async function saveDocAsFileWithSignImage(
    filePath,
    fileName,
    file,
) {
    // TODO: remove duplicating info (in loop)
    JSzipUtils.getBinaryContent(
        `${siteHost}/${filePath}`,
        async (error, content) => {
            if (error) {
                console.log(error);
                return;
            }

            const jszip = new JSzip();
            await jszip.loadAsync(content);

            for (let iSign = 0; iSign < file.request_file_signers.length; iSign++) {
                const signImage = await getImage(
                    file.request_file_signers[iSign].thumbprint,
                    file.request_file_signers[iSign].signer_name,
                    file.request_file_signers[iSign].valid_from,
                    file.request_file_signers[iSign].valid_to,
                );
                const imageContent = await signImage.arrayBuffer();
                jszip.file(`word/media/imageSign_${iSign + 1}.png`, imageContent);

                const filesNames = Object.keys(jszip.files);

                for (let i = 0; i < filesNames.length; i++) {
                    const fileNameL = filesNames[i];

                    if (fileNameL === "[Content_Types].xml") {
                        let xmlDoc = await jszip.files[fileNameL].async("string");

                        let closeIndPos = xmlDoc.indexOf("<Override PartName=");

                        xmlDoc = [
                            xmlDoc.substring(0, closeIndPos),
                            `
                            <Default Extension="png" ContentType="image/png"/>
                            <Default Extension="jpeg" ContentType="image/jpeg"/>
                            `,
                            xmlDoc.substring(closeIndPos),
                        ].join("");

                        closeIndPos = xmlDoc.indexOf("</Types>");

                        xmlDoc = [
                            xmlDoc.substring(0, closeIndPos),
                            `
                            <Override
                                PartName="/_rels/.rels"
                                ContentType="application/vnd.openxmlformats-package.relationships+xml"/>
                            <Override
                                PartName="/docProps/custom.xml"
                                ContentType="application/vnd.openxmlformats-officedocument.custom-properties+xml"/>
                            <Override
                                PartName="/word/_rels/document.xml.rels"
                                ContentType="application/vnd.openxmlformats-package.relationships+xml"/>
                            <Override
                                PartName="/word/media/imageSign_${iSign + 1}.png"
                                ContentType="image/png"/>
                            `,
                            xmlDoc.substring(closeIndPos),
                        ].join("");
                        jszip.file(fileNameL, xmlDoc);
                    }
                    if (fileNameL === "word/_rels/document.xml.rels") {
                        const xmlDoc = await jszip.files[fileNameL].async("string");
                        const closeIndPos = xmlDoc.indexOf("</Relationships>");

                        const rels2 = [
                            xmlDoc.substring(0, closeIndPos),
                            `<Relationship
                            Id="rId${444 + iSign}"
                            Type="http://schemas.openxmlformats.org/officeDocument/2006/relationships/image"
                            Target="media/imageSign_${iSign + 1}.png"
                        />`,
                            xmlDoc.substring(closeIndPos),
                        ].join("");
                        jszip.file(fileNameL, rels2);
                    }
                    if (fileNameL === "word/document.xml") {
                        const xmlDoc = await jszip.files[fileNameL].async("string");
                        // console.log(xmlDoc);
                        const closeIndPos = xmlDoc.indexOf("</w:body>");

                        const rels2 = [
                            xmlDoc.substring(0, closeIndPos),
                            picTag(`rId${444 + iSign}`),
                            xmlDoc.substring(closeIndPos),
                        ].join("");
                        jszip.file(fileNameL, rels2);
                    }
                }
            }

            jszip.generateAsync(
                { type: "blob" },
            )
                .then((contentNew) => {
                    saveAs(contentNew, `${fileName}_signs_images.docx`);
                });
        },
    );
}
