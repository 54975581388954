<template>
    <div class="filter-users-hotline">
        <q-input
            v-model="surname"
            outlined
            dense
            clearable
            label="Исполнитель"
        />
        <q-input
            v-model="phone"
            outlined
            dense
            clearable
            label="Телефон"
        />
        <q-input
            v-model="email"
            outlined
            dense
            clearable
            label="Email"
        />
        <q-select
            v-model="programsIds"
            outlined
            dense
            clearable
            :options="programsSpecial"
            optionValue="id"
            optionLabel="title"
            mapOptions
            emitValue
            label="ПО"
            multiple
            :displayValue="programsList"
            @clear="programsIds = []"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

function generateProperty(property) {
    return {
        get() {
            return this.filterUsersHotline[property];
        },
        set(value) {
            this.$store.dispatch(
                "setFilterUsersHotline",
                {
                    ...this.filterUsersHotline,
                    [property]: value,
                },
            );
        },
    };
}

export default {
    name: "FilterUsersHotline",
    computed: {
        ...mapGetters({
            programs: "programs",
            filterUsersHotline: "filterUsersHotline",
        }),
        programsSpecial() {
            return this.programs.filter((p) => p.type === 2);
        },
        surname: generateProperty("surname"),
        phone: generateProperty("phone"),
        email: generateProperty("email"),
        programsIds: generateProperty("programs_ids"),
        programsList() {
            if (this.programsIds.length === 0) {
                return "";
            }
            if (this.programsIds.length > 2) {
                return `Выбрано ${this.programsIds.length} значения(ий)`;
            }

            return this.programsIds
                .map((pid) => this.programs.find((p) => p.id === pid).title)
                .join(", ");
        },
    },
};
</script>
