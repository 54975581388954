<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Учетные записи
            ({{ usersFiltered.length }})
            <q-space />
            <FilterUsers />
        </div>
        <div class="content-body users-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Фамилия
            </div>
            <div class="cell cell-header cell-center">
                Имя
            </div>
            <div class="cell cell-header cell-center">
                Отчество
            </div>
            <div class="cell cell-header cell-center">
                Должность
            </div>
            <div class="cell cell-header cell-center">
                Телефон
            </div>
            <div class="cell cell-header cell-center">
                EMail
            </div>
            <div class="cell cell-header cell-center">
                <q-btn
                    round
                    :icon="matAdd"
                    color="primary"
                    size="0.7rem"
                    @click="startEdit(null)"
                />
            </div>
            <!-- data -->
            <div
                v-for="(user, index) in usersFiltered"
                :key="index"
                :class="[
                    'row-wrapper',
                    currentUser.accessLevel < user.accessLevel ? 'row-readonly' : '',
                    user.is_inactive ? 'row-inactive' : '',
                ]"
                @click="startEdit(index)"
            >
                <div class="cell cell-center">
                    {{ index + 1 }}
                </div>
                <div class="cell">
                    {{ user.surname }}
                </div>
                <div class="cell">
                    {{ user.name }}
                </div>
                <div class="cell">
                    {{ user.patronymic }}
                </div>
                <div class="cell">
                    {{ getUserPostTitle(user.id) }}
                </div>
                <div class="cell">
                    {{ user.phone }}
                </div>
                <div class="cell">
                    {{ user.email }}
                </div>
                <div class="cell cell-center">
                    <q-btn
                        v-if="currentUser.id != user.id && currentUser.accessLevel >= user.accessLevel"
                        round
                        :icon="matDelete"
                        size="0.7rem"
                        @click="(e) => deleteItem(e, user)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import FilterUsers from "@/components/Filters/FilterUsers.vue";
import DialogEditUser from "@/components/Dialogs/DialogEditUser.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";

export default {
    name: "Users",
    components: {
        FilterUsers,
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            usersFiltered: "usersFiltered",
            getUserPostTitle: "getUserPostTitle",
        }),
    },
    methods: {
        startEdit(index) {
            if (index !== null && this.currentUser.accessLevel < this.usersFiltered[index].accessLevel) {
                return;
            }
            this.$q.dialog({
                component: DialogEditUser,
                componentProps: {
                    user: index === null
                        ? { users_roles: [] }
                        : { ...this.usersFiltered[index] },
                },
            });
        },
        deleteItem(event, user) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить работника 
                        "${user.surname} ${user.name}${user.patronymic ? ` ${user.patronymic}` : ""}"?`,
                },
            })
                .onOk(() => {
                    // TODO: move notifications into store
                    this.$store.dispatch("deleteUser", user.id)
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
    },
};
</script>
