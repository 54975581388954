import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        traits: [],
    },
    getters: {
        traits: (state) => state.traits,
        getTraitValues: (state) => (traitId) => {
            const trait = state.traits.find((t) => t.id === traitId);
            return trait.values;
        },
    },
    actions: {
        getToolsKindsTraitsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/tools_traits`)
                    .then((res) => {
                        commit("setTraits", res.data.tools_traits);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getToolKindTraitsValues(context, { toolId, kindId }) {
            return new Promise((resolve, reject) => {
                // get values of traits
                const jsonS = JSON.stringify({ toolId, kindId });
                axios.get(`${siteHost}/api/tool_kind_traits/${jsonS}`)
                    .then((res) => {
                        const kindTraits = res.data.kind_traits;
                        resolve(kindTraits);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveToolKindTrait(context, trait) {
            const url = trait.id
                ? `${siteHost}/api/tool_traits/${trait.id}`
                : `${siteHost}/api/tool_traits`;

            const method = trait.id
                ? "put"
                : "post";

            return new Promise((resolve, reject) => {
                axios[method](url, trait)
                    .then(() => {
                        resolve("Параметр сохранен");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        deleteToolKindTrait(context, traitId) {
            const url = `${siteHost}/api/tool_traits/${traitId}`;
            return new Promise((resolve, reject) => {
                axios.delete(url)
                    .then(() => {
                        resolve("Параметр удален");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        addToolKindTraitValue(context, payload) {
            // payload - traitId, newValue
            return new Promise((resolve, reject) => {
                axios.post(`${siteHost}/api/tool_traits_add_value`, payload)
                    .then(() => {
                        resolve("Значение параметра добавлено");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
    mutations: {
        setTraits(state, traits) {
            state.traits = traits;
        },
    },
};
