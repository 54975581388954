<template>
    <div class="user-menu">
        <template v-if="currentUser.isAuthenticated">
            <span class="user-fio">
                {{ currentUser.surname }}
                {{ currentUser.name }}
                {{ currentUser.patronymic }},
            </span>
            {{ postTitle(currentUser.post_id) }},
            {{ getOrgProperty(currentUser.organization_id, "name") }}
        </template>

        <q-btn
            round
            color="secondary"
            :icon="currentUser.isAuthenticated ? matEngineering : matPermIdentity"
        >
            <q-menu autoClose>
                <q-list style="min-width: 200px">
                    <q-item
                        v-if="currentUser.isAuthenticated"
                        clickable
                        @click="logOut"
                    >
                        <q-item-section>Выйти из программы</q-item-section>
                    </q-item>
                    <q-item
                        v-if="!currentUser.isAuthenticated"
                        clickable
                        @click="logIn"
                    >
                        <q-item-section>Войти в программу</q-item-section>
                    </q-item>
                </q-list>
            </q-menu>
        </q-btn>
    </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
    name: "UserMenu",
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            postTitle: "postTitle",
            getOrgProperty: "getOrgProperty",
        }),
    },
    methods: {
        logIn() {
            if (this.$router.currentRoute.fullPath !== "/login") {
                this.$router.push("/login");
            }
        },
        logOut() {
            this.$store.dispatch("logout");
            this.$router.push("/");
            // this.logIn();
        },
    },
};
</script>
