<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Сервисные центры
            ({{ servicesRepairs.length }})
            <q-space />
            <SelectFilter
                v-model="regionId"
                :options="regions"
                label="Регион"
                clearable
                class="users-filter--input"
            />
        </div>
        <div class="services-repairs-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Наименование
            </div>
            <div class="cell cell-header cell-center">
                Регион
            </div>
            <div class="cell cell-header cell-center">
                Адрес
            </div>
            <div class="cell cell-header cell-center">
                Телефон
            </div>
            <div class="cell cell-header cell-center">
                ФИО руководителя
            </div>
            <div class="cell cell-header cell-center">
                Тип
            </div>
            <div class="cell cell-header cell-center">
                <q-btn
                    round
                    :icon="matAdd"
                    size="0.7rem"
                    color="primary"
                    @click="createServiceRepair"
                />
            </div>
            <!-- data -->
            <div
                v-for="(service, index) in servicesRepairs"
                :key="index"
                class="row-wrapper"
                @click="editServiceRepair(index)"
            >
                <div class="cell cell-center">
                    {{ index + 1 }}
                </div>
                <div class="cell">
                    {{ service.title }}
                </div>
                <div class="cell">
                    {{ getRegionName(service.region_id) }}
                </div>
                <div class="cell">
                    {{ service.address }}
                </div>
                <div class="cell">
                    {{ service.telephone }}
                </div>
                <div class="cell">
                    {{ service.chief_fio }}
                </div>
                <div class="cell">
                    {{ service.service_type === 1 ? "Филиал ФГБУ ИАЦ" : "Сервисный центр" }}
                </div>
                <div class="cell cell-center">
                    <q-btn
                        round
                        :icon="matDelete"
                        size="0.7rem"
                        @click="(e) => deleteServiceRepair(e, index)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";
import DialogEditServiceRepair from "@/components/Dialogs/DialogEditServiceRepair.vue";

export default {
    name: "ServiceRepairs",
    components: {
        SelectFilter,
    },
    computed: {
        ...mapGetters({
            regions: "regions",
            getRegionName: "getRegionName",
            servicesRepairs: "servicesRepairs",
            servicesRepairsFilter: "servicesRepairsFilter",
        }),
        regionId: {
            get() {
                return this.servicesRepairsFilter.region_id;
            },
            set(v) {
                this.$store.dispatch(
                    "setServicesRepairsFilter",
                    {
                        ...this.servicesRepairsFilter,
                        region_id: v,
                    },
                );
            },
        },
    },
    methods: {
        createServiceRepair() {
            this.$q.dialog({
                component: DialogEditServiceRepair,
                componentProps: {
                    itemForEdit: {},
                },
            })
                .onOk((serviceRepair) => {
                    this.$store.dispatch("addServiceRepair", serviceRepair);
                });
        },
        editServiceRepair(itemIndex) {
            this.$q.dialog({
                component: DialogEditServiceRepair,
                componentProps: {
                    itemForEdit: this.servicesRepairs[itemIndex],
                },
            })
                .onOk((serviceRepair) => {
                    this.$store.dispatch("updateServiceRepair", serviceRepair);
                });
        },
        deleteServiceRepair(event, itemIndex) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить сервисный центр
                         "${this.servicesRepairs[itemIndex].title}"?`,
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteServiceRepair", this.servicesRepairs[itemIndex].id);
                });
        },
    },
};
</script>
