<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Производители оборудования
            ({{ manufacturersFiltered.length }})
            <q-space />
            <q-input
                v-model="filterTitle"
                outlined
                dense
                clearable
                label="Наименование"
                style="width: 250px;"
            />
        </div>
        <div class="content-body simple-three-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Производитель оборудования
            </div>
            <div class="cell cell-header cell-center">
                <q-btn
                    round
                    :icon="matAdd"
                    size="0.7rem"
                    color="primary"
                    @click="editItem(null)"
                />
            </div>
            <!-- data -->
            <div
                v-for="(m, index) in manufacturersFiltered"
                :key="index"
                class="row-wrapper"
                @click="editItem(index)"
            >
                <div class="cell cell-center">
                    {{ index + 1 }}
                </div>
                <div class="cell">
                    {{ m.title }}
                </div>
                <div class="cell cell-center">
                    <q-btn
                        round
                        :icon="matDelete"
                        size="0.7rem"
                        @click="(e) => deleteItem(e, index)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import DialogEditSimple from "@/components/Dialogs/DialogEditSimple.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";

export default {
    name: "Manufacturers",
    data() {
        return {
            filterTitle: "",
        };
    },
    computed: {
        ...mapGetters({
            manufacturers: "manufacturers",
        }),
        manufacturersFiltered() {
            return this.filterTitle
                ? this.manufacturers.filter((m) => m.title.toLowerCase().includes(this.filterTitle.toLowerCase()))
                : this.manufacturers;
        },
    },
    methods: {
        editItem(index) {
            this.$q.dialog({
                component: DialogEditSimple,
                componentProps: {
                    dialogTitle: "Производитель оборудования",
                    itemForEdit: `${index === null ? "" : this.manufacturersFiltered[index].title}`,
                },
            })
                .onOk((value) => {
                    this.$store.dispatch("saveManufacturer", {
                        id: index === null ? null : this.manufacturersFiltered[index].id,
                        title: value,
                    })
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
        deleteItem(event, index) {
            event.stopPropagation();

            const { title } = this.manufacturersFiltered[index];
            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить производителя оборудования "${title}"?`,
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteManufacturer", this.manufacturersFiltered[index].id)
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
    },
};
</script>
