<template>
    <div class="tool-moving-grid">
        <div class="cell cell-header cell-center">
            №
        </div>
        <div class="cell cell-header cell-center">
            Дата
        </div>
        <div class="cell cell-header cell-center">
            Событие
        </div>
        <div class="cell cell-header cell-center">
            Примечание
        </div>
        <div class="cell cell-header cell-center">
            <q-btn
                round
                :icon="matAdd"
                size="0.7rem"
                color="primary"
                @click="editItem(null)"
            />
        </div>

        <div
            v-for="(tm, index) in modelValue"
            :key="index"
            class="row-wrapper"
            @click="editItem(index)"
        >
            <div class="cell cell-center">
                {{ index + 1 }}
            </div>
            <div class="cell cell-center">
                {{ dateYMDtoDMY(tm.date_time) }}
            </div>
            <div class="cell cell-center">
                {{ getEventName(tm.event_id) }}
            </div>
            <div class="cell cell-center">
                {{ tm.comment }}
            </div>
            <div class="cell cell-center">
                <q-btn
                    round
                    :icon="matDelete"
                    size="0.7rem"
                    @click="(e) => deleteItem(e, index)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { TOOLS_EVENTS } from "@/store/constants";
import { dateYMDtoDMY } from "@/helpers/helpers";
import DialogEditToolMovingDialogEdit from "./DialogEditToolMovingDialogEdit.vue";

export default {
    name: "DialogEditToolMoving",
    props: {
        modelValue: {
            type: Array,
            required: true,
        },
    },
    emits: ["update:modelValue"],
    setup() {
        return {
            dateYMDtoDMY,
        };
    },
    methods: {
        editItem(index) {
            this.$q.dialog({
                component: DialogEditToolMovingDialogEdit,
                componentProps: {
                    itemForEdit: index === null
                        ? {}
                        : { ...this.modelValue[index] },
                },
            })
                .onOk((ts) => {
                    if (index === null) {
                        this.$emit(
                            "update:modelValue",
                            [
                                ...this.modelValue,
                                ts,
                            ].sort((a, b) => (a.date_time < b.date_time ? 1 : -1)),
                        );
                    }
                    else {
                        this.$emit(
                            "update:modelValue",
                            [
                                ...this.modelValue.slice(0, index),
                                ts,
                                ...this.modelValue.slice(index + 1),
                            ].sort((a, b) => (a.date_time < b.date_time ? 1 : -1)),
                        );
                    }
                });
        },
        deleteItem(event, index) {
            event.stopPropagation();
            this.$emit(
                "update:modelValue",
                [
                    ...this.modelValue.slice(0, index),
                    ...this.modelValue.slice(index + 1),
                ].sort((a, b) => (a.date_time < b.date_time ? 1 : -1)),
            );
        },
        getEventName(eventId) {
            const toolEvent = TOOLS_EVENTS.find((te) => te.value === eventId);
            return toolEvent ? toolEvent.label : "Событие не найдено";
        },
    },
};
</script>
