<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section style="min-height: 750px;">
                <div class="text-h6">
                    Выбор типичной неисправности
                </div>
                <div style="display: flex; gap: 8px; margin-bottom: 8px;">
                    <q-select
                        v-model="filterRequestKindId"
                        outlined
                        dense
                        :options="REQUEST_KINDS"
                        optionValue="id"
                        optionLabel="title"
                        mapOptions
                        emitValue
                        label="Тип заявки"
                        clearable
                        style="flex-basis: 50%;"
                    />
                    <q-select
                        v-model="filterToolKindId"
                        outlined
                        dense
                        :options="toolsKinds"
                        optionValue="id"
                        optionLabel="title"
                        mapOptions
                        emitValue
                        label="Вид оборудования"
                        clearable
                        style="flex-basis: 50%;"
                    />
                </div>
                <div
                    v-if="[
                        REQUEST_KINDS_VALUES.SOFT_SPECIAL,
                        REQUEST_KINDS_VALUES.SOFT_COMMON,
                    ].includes(filterRequestKindId)"
                    style="display: flex; gap: 8px; margin-bottom: 8px;"
                >
                    <q-select
                        v-model="programObj"
                        outlined
                        dense
                        :options="programsFilter"
                        optionValue="id"
                        optionLabel="title"
                        mapOptions
                        label="Програмное обеспечение"
                        clearable
                        style="flex-basis: 100%;"
                    />
                </div>

                <q-separator />

                <div class="content-body disrepairs-grid-2">
                    <!-- header -->
                    <div class="cell cell-header cell-center">
                        №
                    </div>
                    <div class="cell cell-header cell-center">
                        Тип заявки
                    </div>
                    <!-- <div class="cell cell-header cell-center">
                        Вид оборудования
                    </div> -->
                    <div class="cell cell-header cell-center">
                        Текст неисправности
                    </div>
                    <!-- <div class="cell cell-header cell-center" /> -->
                    <!-- data -->
                    <div
                        v-for="(m, index) in commonDisrepairsFiltered"
                        :key="index"
                        class="row-wrapper"
                        @click="selectDisrepair(index)"
                    >
                        <div class="cell cell-center">
                            {{ index + 1 }}
                        </div>
                        <div class="cell">
                            {{ getRequestKindTitle(m.request_kind_id) }}
                        </div>
                        <!-- <div class="cell">
                            {{ getToolsKindsList(m.common_disrepair_tool_kinds) }}
                        </div> -->
                        <div class="cell">
                            {{ m.description }}
                        </div>
                        <!-- <div class="cell cell-center" /> -->
                    </div>
                </div>
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { REQUEST_KINDS_VALUES, REQUEST_KINDS, PROGRAM_TYPES_VALUES } from "@/store/constants";

export default {
    name: "DialogDisrepairSearch",
    props: {
        toolKindId: {
            type: [Number, null],
            default: null,
        },
    },
    emits: ["ok"],
    setup() {
        return {
            REQUEST_KINDS_VALUES,
            REQUEST_KINDS,
            PROGRAM_TYPES_VALUES,
        };
    },
    data() {
        return {
            filterRequestKindId: null,
            filterToolKindId: null,
            programObj: null,
        };
    },
    computed: {
        ...mapGetters({
            commonDisrepairs: "commonDisrepairs",
            toolsKinds: "toolsKinds",
            programs: "programs",
        }),
        commonDisrepairsFiltered() {
            return this.commonDisrepairs
                .filter((d) => (this.filterRequestKindId
                    ? d.request_kind_id === this.filterRequestKindId
                    : true
                ))
                .filter((d) => (this.filterToolKindId
                    ? d.common_disrepair_tool_kinds.map((k) => k.tool_kind_id).includes(this.filterToolKindId)
                    : true
                ));
        },
        programsFilter() {
            return this.programs.filter((p) => (
                (this.filterRequestKindId === REQUEST_KINDS_VALUES.SOFT_COMMON &&
                    p.type === this.PROGRAM_TYPES_VALUES.SOFT_COMMON) ||
                (this.filterRequestKindId === REQUEST_KINDS_VALUES.SOFT_SPECIAL &&
                    p.type === this.PROGRAM_TYPES_VALUES.SOFT_SPECIAL)
            ));
        },
    },
    watch: {
        filterRequestKindId() {
            this.programObj = null;
        },
    },
    methods: {
        show() {
            this.filterToolKindId = this.toolKindId;
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        selectDisrepair(index) {
            this.$emit(
                "ok",
                this.programObj
                    ? `${this.commonDisrepairsFiltered[index].description} ${this.programObj.title}`
                    : this.commonDisrepairsFiltered[index].description,
            );

            this.hide();
        },
        getRequestKindTitle(requestKindId) {
            const rKind = REQUEST_KINDS.find((rk) => rk.id === requestKindId);
            return rKind ? rKind.title : "";
        },
        /*
        getToolKindTitle(toolKindId) {
            const tKind = this.toolsKinds.find((tk) => tk.id === toolKindId);
            return tKind ? tKind.title : "";
        },
        getToolsKindsList(kindsIds) {
            return kindsIds
                .map((k) => this.getToolKindTitle(k.tool_kind_id))
                .join(", ");
        },
        */
    },
};
</script>
