<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Регионы
            ({{ regions.length }})
        </div>
        <div class="regions-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header">
                Наименование
            </div>
            <div class="cell cell-header">
                Код
            </div>
            <div class="cell cell-header">
                <q-btn
                    round
                    :icon="matAdd"
                    size="0.7rem"
                    color="primary"
                    @click="createRegion"
                />
            </div>
            <!-- data -->
            <div
                v-for="(region, index) in regions"
                :key="index"
                class="row-wrapper"
                @click="editRegion(index)"
            >
                <div class="cell cell-center">
                    {{ index + 1 }}
                </div>
                <div class="cell">
                    {{ region.title }}
                </div>
                <div class="cell">
                    {{ region.code }}
                </div>
                <div class="cell cell-center">
                    <q-btn
                        round
                        :icon="matDelete"
                        size="0.7rem"
                        @click="(e) => deleteRegion(e, index)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";
import DialogEditRegion from "@/components/Dialogs/DialogEditRegion.vue";

export default {
    name: "Regions",
    computed: {
        ...mapGetters({
            regions: "regions",
        }),
    },
    methods: {
        createRegion() {
            this.$q.dialog({
                component: DialogEditRegion,
                componentProps: {
                    itemForEdit: {},
                },
            })
                .onOk((region) => {
                    this.$store.dispatch("addRegion", region);
                });
        },
        editRegion(itemIndex) {
            this.$q.dialog({
                component: DialogEditRegion,
                componentProps: {
                    itemForEdit: this.regions[itemIndex],
                },
            })
                .onOk((region) => {
                    this.$store.dispatch("updateRegion", region);
                });
        },
        deleteRegion(event, itemIndex) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить регион "${this.regions[itemIndex].title}"?`,
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteRegion", this.regions[itemIndex].id);
                });
        },
    },
};
</script>
