<template>
    <q-input
        ref="refDate"
        v-model="dateLocal"
        outlined
        dense
        mask="##.##.####"
        clearable
        :label="labelTitle"
        :class="[
            classOuter,
            hasError ? 'date-wrong-format' : '',
        ]"
        hideBottomSpace
        :rules="[() => !hasError || errorMessage]"
        :disable="disable"
    >
        <template #prepend>
            <q-icon
                :name="matEvent"
                class="cursor-pointer"
            >
                <q-popup-proxy
                    ref="qDateProxy"
                    transitionShow="scale"
                    transitionHide="scale"
                >
                    <q-date
                        v-model="dateLocal"
                        mask="DD.MM.YYYY"
                        minimal
                        :locale="DATE_LOCALE"
                        @update:modelValue="(value, reason) => {
                            if (reason == 'add-day') {
                                $refs.qDateProxy.hide();
                            }
                        }"
                    />
                </q-popup-proxy>
            </q-icon>
        </template>
    </q-input>
</template>

<script>
import moment from "@/helpers/moment";
import { DATE_LOCALE } from "@/store/constants";

export default {
    name: "PoeDatePicker",
    props: {
        labelTitle: {
            type: String,
            default: "Дата",
        },
        classOuter: {
            type: String,
            default: "q-mb-sm",
        },
        modelValue: {
            type: String,
            default: "",
        },
        emptyDateAllowed: {
            type: Boolean,
            default: false,
        },
        futureDateAllowed: {
            type: Boolean,
            default: true,
        },
        disable: {
            type: Boolean,
            default: false,
        },
        doValidate: {
            type: Boolean,
            default: true,
        },
    },
    // TODO: change to update:modelValue
    emits: ["update:modelValue"],
    setup() {
        return {
            DATE_LOCALE,
        };
    },
    computed: {
        dateLocal: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit("update:modelValue", value || "");
            },
        },
        formatIsValid() {
            const regExpDate = /[0-3]\d.[01]\d.\d\d\d\d/;
            return this.dateLocal !== null && !!this.dateLocal.match(regExpDate);
        },
        dateIsValid() {
            return this.formatIsValid &&
                (this.futureDateAllowed || moment(this.dateLocal, "DD.MM.YYYY").isBefore(moment()));
        },
        hasError() {
            return this.errorMessage !== "";
        },
        errorMessage() {
            if (this.emptyDateAllowed && (
                this.dateLocal === null ||
                this.dateLocal === ""
            )) {
                return "";
            }

            if (!this.formatIsValid) {
                return "Введите дату в формате DD.MM.YYYY";
            }

            return this.dateIsValid
                ? ""
                : "Укажите дату не позднее сегодняшней";
        },
    },
    methods: {
        validate() {
            this.$refs.refDate.validate();
        },
    },
};
</script>
