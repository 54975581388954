<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 90vw; max-width: 90vw;">
            <q-card-section>
                <div class="text-h6">
                    PARSE XLSX DATA
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <q-input
                    v-model="rawData"
                    outlined
                    dense
                    type="textarea"
                />
                <table>
                    <th>
                        №
                    </th>
                    <th>
                        Вид оборудования
                    </th>
                    <th>
                        Количество
                    </th>
                    <th>
                        Менее 5 лет
                    </th>
                    <th>
                        5-10 лет
                    </th>
                    <th>
                        Более 10 лет
                    </th>
                    <tr
                        v-for="(t, index) in types"
                        :key="index"
                    >
                        <td style="padding: 4px; text-align: center;">
                            {{ index + 1 }}
                        </td>
                        <td style="padding: 4px; text-align: center;">
                            {{ t.title }}
                        </td>
                        <td style="padding: 4px; text-align: center;">
                            {{ t.less_5 + t.between_5_10 + t.greater_10 }}
                        </td>
                        <td style="padding: 4px; text-align: center;">
                            {{ t.less_5 }}
                        </td>
                        <td style="padding: 4px; text-align: center;">
                            {{ t.between_5_10 }}
                        </td>
                        <td style="padding: 4px; text-align: center;">
                            {{ t.greater_10 }}
                        </td>
                    </tr>
                </table>
            </q-card-section>

            <q-btn
                flat
                label="Расчет"
                color="primary"
                @click="calcToolsByGroupAndAge"
            />

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-close-popup
                    flat
                    label="Закрыть"
                    color="primary"
                    @click="hide"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { date } from "quasar";

export default {
    name: "DialogParseXlsx",
    data() {
        return {
            rawData: "",
            types: [],
        };
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        async calcToolsByGroupAndAge() {
            const dateToday = date.formatDate(new Date(), "YYYY-MM-DD");
            const date5years = date.formatDate(
                date.subtractFromDate(
                    new Date(),
                    { years: 5 },
                ),
                "YYYY-MM-DD",
            );
            const date10years = date.formatDate(
                date.subtractFromDate(
                    new Date(),
                    { years: 10 },
                ),
                "YYYY-MM-DD",
            );
            console.log(dateToday);
            console.log(date5years);
            console.log(date10years);

            const lines = this.rawData.split("\n");
            console.log(lines.length);

            let types = [];

            lines.forEach((l) => {
                const cellsData = l.split("\t");

                if (cellsData.length > 7) {
                    types.push(cellsData[3]);
                }
            });

            console.log(types);

            types = Array.from(new Set(types)).map((t) => ({
                title: t,
                less_5: 0,
                between_5_10: 0,
                greater_10: 0,
            }));

            lines.forEach((l) => {
                const cellsData = l.split("\t");

                if (cellsData.length > 7) {
                    const sT = date.formatDate(
                        date.extractDate(cellsData[5], "DD.MM.YYYY"),
                        "YYYY-MM-DD",
                    );

                    const type = types.find((t) => t.title === cellsData[3]);

                    if (type) {
                        if (sT < date10years) {
                            type.greater_10++;
                        }
                        else if (sT < date5years) {
                            type.between_5_10++;
                        }
                        else {
                            type.less_5++;
                        }
                    }
                }
            });

            console.log(types);
            this.types = types;
        },
    },
};
</script>
