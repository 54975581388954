<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Роли и права
            <q-space />
            <q-btn
                round
                :icon="matAdd"
                size="0.7rem"
                color="primary"
                @click="createRole(null)"
            />
        </div>
        <div
            class="rights-roles-grid"
            :style="rolesRightsGridStyle"
        >
            <!-- column of numbers -->
            <div class="cell cell--header cell--row-header-1 cell--z-index-top">
                №
            </div>
            <div class="cell cell--row-header-1" />
            <div
                v-for="indexR in RIGHTS.length"
                :key="indexR"
                :class="[
                    'cell cell__center cell--row-header-1',
                    hoverRightIndex === indexR - 1 ? 'cell--highlight-header' : '',
                ]"
                @mouseover="onMouseOver(indexR)"
            >
                {{ indexR }}
            </div>
            <!-- column of categories -->
            <div class="cell cell--header cell--row-header-2 cell--z-index-top" />
            <div class="cell cell--row-header-2" />
            <div
                v-for="(right, indexR) in RIGHTS"
                :key="indexR"
                :class="[
                    'cell cell--row-header-2',
                    hoverRightIndex === indexR ? 'cell--highlight-header' : '',
                ]"
                @mouseover="onMouseOver(indexR)"
            >
                {{ right.category }}
            </div>
            <!-- column of headers -->
            <div class="cell cell--header cell--row-header-3 cell--z-index-top" />
            <div class="cell cell--row-header-3" />
            <div
                v-for="(right, indexR) in RIGHTS"
                :key="indexR"
                :class="[
                    'cell cell--row-header-3',
                    hoverRightIndex === indexR ? 'cell--highlight-header' : '',
                ]"
                @mouseover="onMouseOver(indexR)"
            >
                {{ right.title }}
            </div>
            <!-- roles -->
            <RolesRightsRole
                v-for="(role, index) in roles"
                :key="index"
                :role="role"
                :roleIndex="index"
                :accessLevels="accessLevels"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ACCESS_LEVELS, RIGHTS } from "@/store/constants";
import DialogEditRole from "@/components/Dialogs/DialogEditRole.vue";
import RolesRightsRole from "./RolesRightsRole.vue";

export default {
    name: "Roles",
    components: {
        RolesRightsRole,
    },
    setup() {
        return {
            ACCESS_LEVELS,
            RIGHTS,
        };
    },
    computed: {
        ...mapGetters({
            roles: "roles",
            currentUser: "currentUser",
            hoverRightIndex: "hoverRightIndex",
        }),
        accessLevels() {
            return this.ACCESS_LEVELS.filter((al) => (al.value <= this.currentUser.accessLevel));
        },
        rolesRightsGridStyle() {
            return [
                `grid-template-columns: 40px 180px 450px repeat(${this.roles.length}, 1fr);`,
                `grid-template-rows: 50px min-content repeat(${this.RIGHTS.length}, 1fr);`,
                "grid-auto-flow: column;",
            ].join(" ");
        },
    },
    methods: {
        onMouseOver(rightIndex) {
            this.$store.dispatch(
                "setHovers",
                {
                    rightIndex,
                    roleIndex: -1,
                },
            );
        },
        createRole() {
            this.$q.dialog({
                component: DialogEditRole,
            })
                .onOk((roleTitle) => {
                    this.$store.dispatch(
                        "createRole",
                        roleTitle,
                    )
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
    },
};
</script>
