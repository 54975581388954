<template>
    <q-dialog
        ref="dialogEditToolSoftware"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Программное обеспечение
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <q-field
                    ref="programType"
                    v-model="item.type"
                    outlined
                    label="Тип программного обеспечения"
                    stackLabel
                    :rules="[val => !!val || 'Укажите тип программного обеспечения']"
                    hideBottomSpace
                    class="q-pb-sm"
                >
                    <q-option-group
                        v-model="item.type"
                        :options="PROGRAM_TYPES"
                        color="primary"
                    />
                </q-field>
                <q-input
                    ref="programName"
                    v-model="item.title"
                    outlined
                    dense
                    label="Наименование"
                    class="q-mb-sm"
                    :rules="[val => val && val.length > 0 || 'Укажите наименование']"
                    hideBottomSpace
                />
                <q-input
                    ref="programVersion"
                    v-model="item.version"
                    outlined
                    dense
                    label="Версия"
                    class="q-mb-sm"
                    :rules="[val => val && val.length > 0 || 'Укажите версию']"
                    hideBottomSpace
                />
                <q-input
                    v-model="item.description"
                    outlined
                    dense
                    label="Описание ПО"
                    class="q-mb-sm"
                    autogrow=""
                />
                <q-input
                    v-model="item.comment"
                    outlined
                    dense
                    label="Примечание"
                    class="q-mb-sm"
                    autogrow=""
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { PROGRAM_TYPES } from "@/store/constants";

export default {
    name: "DialogEditToolSoftwareDialogEdit",
    props: {
        itemForEdit: {
            type: Object,
            default: () => ({
                title: "",
                type: 1,
                version: "",
                description: "",
                comment: "",
            }),
        },
    },
    emits: ["ok"],
    setup() {
        return {
            PROGRAM_TYPES,
        };
    },
    data() {
        return {
            item: {},
        };
    },
    methods: {
        show() {
            this.item = {
                ...this.itemForEdit,
            };
            this.$refs.dialogEditToolSoftware.show();
        },
        hide() {
            this.$refs.dialogEditToolSoftware.hide();
        },
        onOkClick() {
            this.$refs.programName.validate();
            this.$refs.programVersion.validate();
            this.$refs.programType.validate();

            if (!this.$refs.programName.hasError &&
                !this.$refs.programVersion.hasError &&
                !this.$refs.programType.hasError) {
                this.$emit("ok", this.item);

                this.hide();
            }
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
