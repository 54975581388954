import date from 'quasar/src/utils/date/date.js';;
import { createDoc, saveDoc } from "./createDoc";
import { tCellP } from "./tableCell";
import { guarantyRemains } from "./helpers";

function getTableToolsList(
    tools,
    {
        unitsFull,
        toolsSetName,
        getToolKindName,
        getManufacturerName,
        getToolModelName,
        toolsStateName,
    },
) {
    const tableWidth = 14400;
    const header = [
        `
        <w:tblPr>
            <w:jc w:val="center"/>
            <w:tblW w:type="dxa" w:w="${tableWidth}"/>
            <w:tblBorders>
                <w:top w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
                <w:start w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
                <w:bottom w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
                <w:end w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
                <w:insideH w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
                <w:insideV w:val="single" w:sz="1" w:space="1" w:color="D0D0D0" />
            </w:tblBorders>
            <w:tblCellMar>
                <w:top w:w="100" w:type="dxa"/>
                <w:start w:w="100" w:type="dxa"/>
                <w:bottom w:w="20" w:type="dxa"/>
                <w:end w:w="100" w:type="dxa"/>
            </w:tblCellMar>
        </w:tblPr>
        <w:tblGrid>
            <w:gridCol w:w="${Math.round(tableWidth * 0.032)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.120)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.082)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.090)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.096)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.094)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.082)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.088)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.081)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.093)}" />
            <w:gridCol w:w="${Math.round(tableWidth * 0.142)}" />
        </w:tblGrid>
        `,
        "<w:tr>",
        tCellP("№", { fontSize: "20", pAlign: "center" }),
        tCellP("Объект", { fontSize: "20", pAlign: "center" }),
        tCellP("Вид комплекта", { fontSize: "20", pAlign: "center" }),
        tCellP("Вид оборудования", { fontSize: "20", pAlign: "center" }),
        tCellP("Производитель", { fontSize: "20", pAlign: "center" }),
        tCellP("Наименование (Модель)", { fontSize: "20", pAlign: "center" }),
        tCellP("Серийный номер", { fontSize: "20", pAlign: "center" }),
        tCellP("Инвентарный номер", { fontSize: "20", pAlign: "center" }),
        tCellP("Дата ввода в экспл.", { fontSize: "20", pAlign: "center" }),
        tCellP("Состояние", { fontSize: "20", pAlign: "center" }),
        tCellP("Остаток гарантийного срока", { fontSize: "20", pAlign: "center" }),
        "</w:tr>",
    ].join("");

    let data = "";

    for (let i = 0; i < tools.length; i++) {
        const tool = tools[i];

        const unit = unitsFull.find((u) => u.id === tool.unit_id);

        const guarantyS = guarantyRemains(tool.guaranty_dt);

        const line = [
            "<w:tr>",
            tCellP(`${i + 1}`, { fontSize: "16", pAlign: "center" }),
            tCellP(unit ? unit.name : "", { fontSize: "16", pAlign: "center" }),
            tCellP(toolsSetName(tool.set_id) || "", { fontSize: "16", pAlign: "center" }),
            tCellP(getToolKindName(tool.tool_kind_id) || "", { fontSize: "16", pAlign: "center" }),
            tCellP(getManufacturerName(tool.manufacturer_id) || "", { fontSize: "16", pAlign: "center" }),
            tCellP(getToolModelName(tool.model_id) || "", { fontSize: "16", pAlign: "center" }),
            tCellP(tool.serial_number, { fontSize: "16", pAlign: "center" }),
            tCellP(tool.inventar_number, { fontSize: "16", pAlign: "center" }),
            tCellP(date.formatDate(tool.work_in_dt, "DD.MM.YYYY"), { fontSize: "16", pAlign: "center" }),
            tCellP(toolsStateName(tool.state) || "", { fontSize: "16", pAlign: "center" }),
            tCellP(guarantyS, { fontSize: "16", pAlign: "center" }),
            "</w:tr>",
        ].join("");

        data = `${data}${line}`;
    }

    return `<w:tbl>${header}${data}</w:tbl>`;
}

export async function createDocToolsList(
    tools,
    storeGetters,
) {
    const replacement = {
        dateToolsList: date.formatDate(new Date(), "DD.MM.YYYY"),
        tableToolsList: getTableToolsList(tools, storeGetters),
    };

    const toolsList = await createDoc(
        "equipment-lst.docx",
        "equipment-lst-2.docx",
        replacement,
    );
    saveDoc(toolsList);
}
