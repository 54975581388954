<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Неисправности ПТС
            ({{ commonDisrepairs.length }})
            <q-space />

            <q-select
                v-model="filterRequestKindId"
                outlined
                dense
                :options="REQUEST_KINDS"
                optionValue="id"
                optionLabel="title"
                mapOptions
                emitValue
                label="Тип заявки"
                clearable
                style="width: 350px;"
            />
            <q-select
                v-model="filterToolKindId"
                outlined
                dense
                :options="toolsKinds"
                optionValue="id"
                optionLabel="title"
                mapOptions
                emitValue
                label="Вид оборудования"
                clearable
                style="width: 350px;"
            />
        </div>
        <div class="content-body disrepairs-grid">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Тип заявки
            </div>
            <div class="cell cell-header cell-center">
                Вид оборудования
            </div>
            <div class="cell cell-header cell-center">
                Текст неисправности
            </div>
            <div class="cell cell-header cell-center">
                <q-btn
                    v-if="canBeEdited"
                    round
                    :icon="matAdd"
                    size="0.7rem"
                    color="primary"
                    @click="editItem(null)"
                />
            </div>
            <!-- data -->
            <div
                v-for="(m, index) in commonDisrepairsFiltered"
                :key="index"
                class="row-wrapper"
                @click="editItem(index)"
            >
                <div class="cell cell-center">
                    {{ index + 1 }}
                </div>
                <div class="cell">
                    {{ getRequestKindTitle(m.request_kind_id) }}
                </div>
                <div class="cell">
                    {{ getToolsKindsList(m.common_disrepair_tool_kinds) }}
                </div>
                <div class="cell">
                    {{ m.description }}
                </div>
                <div class="cell cell-center">
                    <q-btn
                        v-if="canBeEdited"
                        round
                        :icon="matDelete"
                        size="0.7rem"
                        @click="(e) => deleteItem(e, index)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { REQUEST_KINDS, RIGHTS_CONSTANTS } from "@/store/constants";
import DialogEditCommonDisrepair from "@/components/Dialogs/DialogEditCommonDisrepair.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";

export default {
    name: "ProblemsCategories",
    setup() {
        return {
            REQUEST_KINDS,
        };
    },
    data() {
        return {
            filterRequestKindId: null,
            filterToolKindId: null,
        };
    },
    computed: {
        ...mapGetters({
            commonDisrepairs: "commonDisrepairs",
            toolsKinds: "toolsKinds",
            currentUser: "currentUser",
        }),
        canBeEdited() {
            return !!(this.currentUser.rightsObject.RIGHT_PROBLEMS_CATEGORIES & RIGHTS_CONSTANTS.FULL);
        },
        commonDisrepairsFiltered() {
            return this.commonDisrepairs
                .filter((d) => (this.filterRequestKindId
                    ? d.request_kind_id === this.filterRequestKindId
                    : true
                ))
                .filter((d) => (this.filterToolKindId
                    ? d.common_disrepair_tool_kinds.map((k) => k.tool_kind_id).includes(this.filterToolKindId)
                    : true
                ));
        },
    },
    methods: {
        getRequestKindTitle(requestKindId) {
            const rKind = REQUEST_KINDS.find((rk) => rk.id === requestKindId);
            return rKind ? rKind.title : "";
        },
        getToolKindTitle(toolKindId) {
            const tKind = this.toolsKinds.find((tk) => tk.id === toolKindId);
            return tKind ? tKind.title : "";
        },
        getToolsKindsList(kindsIds) {
            return kindsIds
                .map((k) => this.getToolKindTitle(k.tool_kind_id))
                .join(", ");
        },
        editItem(index) {
            if (!this.canBeEdited) {
                return;
            }

            this.$q.dialog({
                component: DialogEditCommonDisrepair,
                componentProps: {
                    itemForEdit: index === null ? {} : this.commonDisrepairs[index],
                },
            })
                .onOk((disrepair) => {
                    this.$store.dispatch(
                        "saveCommonDisrepair",
                        {
                            ...disrepair,
                            id: index === null ? null : this.commonDisrepairs[index].id,
                        },
                    )
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
        deleteItem(event, index) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: "Удалить типичную неисправность?",
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteCommonDisrepair", this.commonDisrepairs[index].id)
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
    },
};
</script>
