<template>
    <div class="filter-requests-hot-line">
        <q-select
            v-model="requestPerformerKind"
            outlined
            dense
            :options="performersKinds"
            label="Тип исполнителя"
            emitValue
            mapOptions
            clearable
            class="item"
        />
        <q-select
            v-model="engineerId"
            outlined
            dense
            clearable
            :options="usersHotline"
            optionValue="id"
            :optionLabel="opt => `${opt.surname} ${opt.name} ${opt.patronymic}`"
            mapOptions
            emitValue
            label="Исполнитель ТП"
            class="item"
        >
            <template #option="scope">
                <q-item v-bind="scope.itemProps">
                    <q-item-section>
                        <q-item-label>
                            {{ scope.opt.surname }}
                            {{ scope.opt.name }}
                            {{ scope.opt.patronymic }}
                        </q-item-label>
                    </q-item-section>
                </q-item>
            </template>
        </q-select>
        <q-select
            v-model="contactKind"
            outlined
            dense
            :options="contactKinds"
            label="Тип заявителя"
            emitValue
            mapOptions
            clearable
            class="item"
        />
        <q-input
            v-if="contactKind === CONTACT_KIND.STRANGER"
            v-model="contactUnknownName"
            outlined
            dense
            clearable
            label="Заявитель"
            class="item"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
    PERFORM_KIND,
    CONTACT_KIND,
} from "@/store/constants";

function generateProperty(property) {
    return {
        get() {
            return this.filterRequests[property];
        },
        set(value) {
            this.$store.dispatch(
                "setFilterRequests",
                {
                    ...this.filterRequests,
                    [property]: value,
                },
            );
        },
    };
}

export default {
    name: "FilterRequests",
    setup() {
        return {
            CONTACT_KIND,
            performersKinds: [
                {
                    label: "Все",
                    value: null,
                },
                {
                    label: "Головной исполнитель",
                    value: PERFORM_KIND.SENIOR,
                },
                {
                    label: "Филиал",
                    value: PERFORM_KIND.FILIAL,
                },
            ],
            contactKinds: [
                {
                    label: "Пользователь системы определен",
                    value: CONTACT_KIND.FROM_IAC,
                },
                {
                    label: "Пользователь системы не определен",
                    value: CONTACT_KIND.STRANGER,
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            filterRequests: "filterRequests",
            usersHotline: "usersHotline",
        }),
        requestPerformerKind: generateProperty("request_performer_kind"),
        contactKind: generateProperty("contact_kind"),
        contactUnknownName: generateProperty("contact_unknown_name"),
        engineerId: generateProperty("engineer_id"),
    },
};
</script>
