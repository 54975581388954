<template>
    <div class="tool-software-grid">
        <div class="cell cell-header cell-center">
            №
        </div>
        <div class="cell cell-header cell-center">
            Тип
        </div>
        <div class="cell cell-header cell-center">
            Наименование
        </div>
        <div class="cell cell-header cell-center">
            Версия
        </div>
        <div class="cell cell-header cell-center">
            Описание ПО
        </div>
        <div class="cell cell-header cell-center">
            Примечание
        </div>
        <div class="cell cell-header cell-center">
            <q-btn
                round
                :icon="matAdd"
                size="0.7rem"
                color="primary"
                @click="editItem(null)"
            />
        </div>

        <div
            v-for="(ts, index) in modelValue"
            :key="index"
            class="row-wrapper"
            @click="editItem(index)"
        >
            <div class="cell cell-center">
                {{ index + 1 }}
            </div>
            <div class="cell cell-center">
                {{ ts.type === PROGRAM_TYPES_VALUES.SOFT_COMMON ? "ОПО" : "СПО" }}
            </div>
            <div class="cell">
                {{ ts.title }}
            </div>
            <div class="cell cell-center">
                {{ ts.version }}
            </div>
            <div class="cell">
                {{ ts.description }}
            </div>
            <div class="cell">
                {{ ts.comment }}
            </div>
            <div class="cell cell-center">
                <q-btn
                    round
                    :icon="matDelete"
                    size="0.7rem"
                    @click="(e) => deleteItem(e, index)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { PROGRAM_TYPES_VALUES } from "@/store/constants";
import DialogEditToolSoftwareDialogEdit from "./DialogEditToolSoftwareDialogEdit.vue";

export default {
    name: "DialogEditToolSoftware",
    props: {
        modelValue: {
            type: Array,
            required: true,
        },
    },
    emits: ["update:modelValue"],
    setup() {
        return {
            PROGRAM_TYPES_VALUES,
        };
    },
    methods: {
        editItem(index) {
            this.$q.dialog({
                component: DialogEditToolSoftwareDialogEdit,
                componentProps: {
                    itemForEdit: index === null
                        ? {}
                        : { ...this.modelValue[index] },
                },
            })
                .onOk((ts) => {
                    if (index === null) {
                        this.$emit(
                            "update:modelValue",
                            [
                                ...this.modelValue,
                                ts,
                            ],
                        );
                    }
                    else {
                        this.$emit(
                            "update:modelValue",
                            [
                                ...this.modelValue.slice(0, index),
                                ts,
                                ...this.modelValue.slice(index + 1),
                            ],
                        );
                    }
                });
        },
        deleteItem(event, index) {
            event.stopPropagation();
            this.$emit(
                "update:modelValue",
                [
                    ...this.modelValue.slice(0, index),
                    ...this.modelValue.slice(index + 1),
                ],
            );
        },
    },
};
</script>
