<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Вид комплекта оборудования
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <q-input
                    ref="refItem"
                    v-model="item.name"
                    outlined
                    dense
                    class="q-mb-sm"
                    :rules="[val => val &&
                        0 < val.length &&
                        val.length < 100 || 'Значение от 1 до 100 символов']"
                    hideBottomSpace
                />
                <q-input
                    ref="refItem"
                    v-model="item.code"
                    outlined
                    dense
                    class="q-mb-sm"
                    type="number"
                />
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
export default {
    name: "DialogEditToolsSetName",
    props: {
        itemForEdit: {
            type: Object,
            required: true,
        },
    },
    emits: ["ok"],
    data() {
        return {
            item: {},
        };
    },
    methods: {
        show() {
            this.item = { ...this.itemForEdit };
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            this.$refs.refItem.validate();
            if (this.$refs.refItem.hasError) {
                return;
            }

            this.$emit("ok", this.item);

            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
