<template>
    <div class="header">
        <div class="toolbar">
            <div class="header-title">
                <!--
                ГАС Правосудие
                -->
            </div>

            <UserMenu />
        </div>
        <MainMenu v-if="currentUser.isAuthenticated" />
    </div>
</template>
<script>
import { mapGetters } from "vuex";

import MainMenu from "@/components/Common/MainMenu.vue";
import UserMenu from "@/components/Common/UserMenu.vue";

export default {
    name: "Header",
    components: {
        MainMenu,
        UserMenu,
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
        }),
    },
};
</script>
