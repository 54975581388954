<template>
    <div class="tool-history-grid">
        <div class="cell cell-header cell-center">
            №
        </div>
        <div class="cell cell-header cell-center">
            Дата время
        </div>
        <div class="cell cell-header cell-center">
            Пользователь
        </div>
        <div class="cell cell-header cell-center">
            Атрибут
        </div>
        <div class="cell cell-header cell-center">
            Старое значение
        </div>
        <div class="cell cell-header cell-center">
            Новое значение
        </div>
        <div
            v-for="(h, index) in toolHistory"
            :key="index"
            class="row-wrapper"
        >
            <div class="cell cell-center">
                {{ index + 1 }}
            </div>
            <div class="cell cell-center">
                {{ h.date_time }}
            </div>
            <div class="cell">
                {{ getUserFIO(h.user_id) }}
            </div>
            <div class="cell">
                {{ getAttrTitle(h.object_attr) }}
            </div>
            <div class="cell">
                {{ getValue(h.object_attr, h.value_old) }}
            </div>
            <div class="cell">
                {{ getValue(h.object_attr, h.value_new) }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

const attrsTitles = {
    tool_kind_id: "Тип оборудования",
    manufacturer_id: "Производитель",
    unit_id: "Объект",
    model_id: "Модель",
    serial_number: "Серийный номер",
    inventar_number: "Инвентарный номер",
    description: "Комментарий",
    work_in_dt: "Дата ввода в эксплуатацию",
    work_out_dt: "Дата вывода из эксплуатации",
    guaranty_dt: "Дата окончания гарантийного срока",
    in_set: "Входит в комплект",
    state: "Состораяние",
    set_id: "Комлект",
    owner_type_id: "Принадлежность",
};

export default {
    name: "DialotEditToolHistory",
    props: {
        toolHistory: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters({
            getUserFIO: "getUserFIO",
            getUnitTitle: "getUnitTitle",
        }),
    },
    methods: {
        getAttrTitle(attr) {
            return attrsTitles[attr] || attr;
        },
        getValue(attr, value) {
            if (attr === "unit_id") {
                return this.getUnitTitle(+value);
            }

            return value;
        },
    },
};
</script>
