<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Запрос к администратору Системы
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <q-select
                    v-model="request_inner_kind_id"
                    :options="REQUEST_INNER_KINDS"
                    mapOptions
                    emitValue
                    outlined
                    dense
                    label="Вид запроса"
                    class="q-mb-sm"
                />
                <q-input
                    ref="refItem"
                    v-model="message"
                    outlined
                    dense
                    class="q-mb-sm"
                    :rules="[val => val &&
                        0 < val.length &&
                        val.length < 3000 || 'Значение от 1 до 3000 символов']"
                    hideBottomSpace
                    type="textarea"
                />
                <q-input
                    ref="refEmail"
                    v-model="email"
                    outlined
                    dense
                    label="Email"
                    hideBottomSpace
                    :rules="[checkEmail]"
                />
            </q-card-section>

            <Documents
                fileSection="innerRequestDocs"
                :filesList="innerRequestDocs"
                :addDocToList="addDoc"
                :deleteDoc="delDoc"
            />

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import Documents from "@/components/Documents/Documents.vue";
import { REQUEST_INNER_KINDS } from "@/store/constants";

export default {
    name: "DialogSystemRequest",
    components: {
        Documents,
    },
    setup() {
        return {
            REQUEST_INNER_KINDS,
        };
    },
    data() {
        return {
            message: "",
            email: "",
            request_inner_kind_id: null,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: "currentUser",
            innerRequestDocs: "innerRequestDocs",
        }),
    },
    methods: {
        show() {
            this.email = this.currentUser.email;
            this.$store.dispatch("setInnerRequestDocs", []);
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            this.$refs.refItem.validate();
            this.$refs.refEmail.validate();
            if (this.$refs.refItem.hasError || this.$refs.refEmail.hasError) {
                return;
            }

            this.$store.dispatch(
                "createRequestToAdmin",
                {
                    author_id: this.currentUser.id,
                    author_email: this.email,
                    message: this.message,
                    request_inner_kind_id: this.request_inner_kind_id,
                },
            )
                .then(() => {
                    this.$q.notify({
                        type: "notify-success",
                        message: "Запрос создан",
                    });
                })
                .catch((err) => {
                    this.$q.notify({
                        type: "notify-failure",
                        message: err,
                    });
                });

            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
        addDoc(file) {
            this.$store.dispatch("addInnerRequestDoc", file);
        },
        delDoc(index) {
            this.$store.dispatch("delInnerRequestDoc", index);
        },
        checkEmail(email) {
            if (!email) {
                return "Поле email не может быть пустым";
            }
            return /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/.test(email) ||
                "Неверный формат поля email";
        },
    },
};
</script>
