<template>
    <div class="main-container">
        <Header />
        <router-view />
        <Footer />
        <div
            v-if="showMainSpinner"
            class="req-dialog-cover"
        >
            <q-spinner-rings
                color="secondary"
                size="8.5em"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import Header from "@/components/Common/Header.vue";
import Footer from "@/components/Common/Footer.vue";

export default {
    name: "App",
    components: {
        Header,
        Footer,
    },
    computed: {
        ...mapGetters({
            showMainSpinner: "showMainSpinner",
        }),
    },
    mounted() {
        this.$store.dispatch("checkUser");
    },
};
</script>
