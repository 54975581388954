<template>
    <q-select
        ref="refSelect"
        :modelValue="model"
        outlined
        dense
        useInput
        hideSelected
        fillInput
        inputDebounce="0"
        :options="optionsF"
        optionValue="id"
        :optionLabel="optionLabel"
        mapOptions
        emitValue
        :clearable="clearable"
        @filter="filterFn"
        @update:modelValue="updateModelValue"
        @popup-hide="popupHide"
    >
        <template #no-option>
            <q-item>
                <q-item-section class="text-grey">
                    No results
                </q-item-section>
            </q-item>
        </template>
        <template
            v-if="extendedScope"
            #option="scope"
        >
            <q-item v-bind="scope.itemProps">
                <q-item-section>
                    <q-item-label>
                        {{ scope.opt[scopeAttr1] }}
                        {{ scope.opt[scopeAttr2] }}
                    </q-item-label>
                    <q-item-label caption>
                        {{ scope.opt[scopeAttr3] }}
                    </q-item-label>
                </q-item-section>
            </q-item>
        </template>
    </q-select>
</template>

<script>
import { ref, toRef } from "vue";

export default {
    name: "SelectFilter",
    props: {
        options: {
            type: Array,
            required: true,
        },
        modelValue: {
            type: Number,
            default: null,
        },
        clearable: {
            type: Boolean,
            default: false,
        },
        extendedScope: {
            type: Boolean,
            default: false,
        },
        scopeAttr1: {
            type: String,
            default: "title",
        },
        scopeAttr2: {
            type: String,
            default: "title",
        },
        scopeAttr3: {
            type: String,
            default: "title",
        },
        optionLabel: {
            type: String,
            default: "title",
        },
    },
    emits: ["update:modelValue"],
    setup(props, context) {
        // const optionsF = toRef(props.options);
        const optionsF = ref(props.options);
        const model = toRef(props, "modelValue");

        return {
            model,
            optionsF,

            filterFn(val, update) {
                update(() => {
                    const needle = val.toLocaleLowerCase();
                    // optionsF.value = props.options
                    optionsF.value = props.options
                        .filter((v) => v[props.optionLabel].toLocaleLowerCase().indexOf(needle) > -1);
                });
            },

            updateModelValue(v) {
                context.emit("update:modelValue", v);
            },

            popupHide() {
                optionsF.value = props.options;
            },
        };
    },
    computed: {
        hasError() {
            return this.$refs.refSelect.hasError;
        },
    },
    methods: {
        validate() {
            return this.$refs.refSelect.validate();
        },
    },
};
</script>
