<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <div class="text-h6 dialog-title">
                Неисправность ПТС
            </div>
            <q-card-section
                style="max-height: 70vh;"
                class="scroll q-pa-xs"
            >
                <div class="dialog-common-grid-col-2">
                    <q-select
                        v-model="disrepair.request_kind_id"
                        outlined
                        dense
                        :options="REQUEST_KINDS"
                        optionValue="id"
                        optionLabel="title"
                        mapOptions
                        emitValue
                        label="Тип заявки"
                        class="cell cell-span-col-2"
                        clearable
                    />
                    <q-input
                        ref="refDescription"
                        v-model="disrepair.description"
                        outlined
                        dense
                        class="cell cell-span-col-2"
                        label="Текст неисправности"
                        autogrow
                        hideBottomSpace
                        :rules="[val => val && val.length > 0 || 'Укажите неисправность']"
                    />
                    <q-select
                        v-model="toolKindsIds"
                        outlined
                        dense
                        :options="toolsKinds"
                        optionValue="id"
                        optionLabel="title"
                        mapOptions
                        emitValue
                        label="Вид оборудования"
                        class="cell cell-span-col-2"
                        clearable
                        multiple
                    />
                </div>
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { REQUEST_KINDS } from "@/store/constants";

const defaultValue = {
    id: null,
    request_kind_id: null,
    tool_kind_id: null,
    description: "",
    common_disrepair_tool_kinds: [],
};

export default {
    name: "DialogEditCommonDisrepair",
    props: {
        itemForEdit: {
            type: Object,
            required: true,
        },
        createNew: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["ok"],
    setup() {
        return {
            REQUEST_KINDS,
        };
    },
    data() {
        return {
            disrepair: {},
            toolKindsIds: [],
        };
    },
    computed: {
        ...mapGetters({
            toolsKinds: "toolsKinds",
        }),
    },
    methods: {
        async show() {
            this.disrepair = {
                ...defaultValue,
                ...this.itemForEdit,
            };

            this.toolKindsIds = this.disrepair.common_disrepair_tool_kinds.map((t) => t.tool_kind_id);

            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            this.$refs.refDescription.validate();
            if (this.$refs.refDescription.hasError) {
                return;
            }

            this.$emit(
                "ok",
                {
                    ...this.disrepair,
                    common_disrepair_tool_kinds: this.toolKindsIds
                        ? this.toolKindsIds.map((k) => ({ tool_kind_id: k }))
                        : [],
                },
            );
            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
