import axios from "axios";
import Notify from 'quasar/src/plugins/notify/Notify.js';;
import { siteHost } from "./constants";

export default {
    state: {
        regions: [],
    },
    getters: {
        regions: (state) => state.regions,
        getRegionCode: (state) => (regionId) => {
            const region = state.regions.find((r) => r.id === regionId);
            return region
                ? region.code
                : "";
        },
        getRegionName: (state) => (regionId) => {
            const region = state.regions.find((r) => r.id === regionId);
            return region
                ? region.title
                : "";
        },
        appSettings: (state, getters) => {
            if (!getters.regions || getters.regions.length === 0) {
                return {
                    title: "",
                    phone: "",
                    immediate_email_to_engineer: false,
                };
            }

            const region = getters.regions.find((r) => r.id === getters.currentUser.region_id);

            return {
                title: region ? region.filial_title : "",
                phone: region ? region.filial_phone : "",
                immediate_email_to_engineer: region ? region.immediate_email_to_engineer : false,
            };
        },
    },
    actions: {
        getRegionsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/regions`)
                    .then((res) => {
                        commit("setRegions", res.data.region);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        addRegion({ dispatch }, region) {
            axios.post(`${siteHost}/api/regions`, { region })
                .then(() => {
                    dispatch("getRegionsFromDB");
                })
                .catch((err) => {
                    Notify.create({
                        type: "notify-failure",
                        message: err.response.data.message,
                    });
                });
        },
        updateRegion({ dispatch }, region) {
            axios.put(`${siteHost}/api/regions/${region.id}`, { region })
                .then(() => {
                    dispatch("getRegionsFromDB");
                })
                .catch((err) => {
                    Notify.create({
                        type: "notify-failure",
                        message: err.response.data.message,
                    });
                });
        },
        deleteRegion({ dispatch }, regionId) {
            axios.delete(`${siteHost}/api/regions/${regionId}`)
                .then(() => {
                    dispatch("getRegionsFromDB");
                })
                .catch((err) => {
                    Notify.create({
                        type: "notify-failure",
                        message: err.response.data.message,
                    });
                });
        },
    },
    mutations: {
        setRegions(state, regions) {
            state.regions = regions;
        },
    },
};
