import axios from "axios";
import { LEARNING_TYPES, siteHost } from "./constants";

export default {
    state: {
        programs: [],
        programsSubsystems: [],
    },
    getters: {
        programs: (state) => state.programs,
        programName: (state) => (pId) => {
            const program = state.programs.find((p) => p.id === pId);
            return program ? program.title : "";
        },
        learningTypeName: () => (ltValue) => {
            const lType = LEARNING_TYPES.find((lt) => lt.value === ltValue);
            return lType
                ? lType.label
                : "";
        },
        programsSubsystems: (state) => state.programsSubsystems,
    },
    actions: {
        getProgramsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/programs`)
                    .then((res) => {
                        commit("setPrograms", res.data.programs);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveProgram({ dispatch }, program) {
            const method = program.id === null
                ? "post"
                : "put";
            const url = program.id === null
                ? `${siteHost}/api/programs`
                : `${siteHost}/api/programs/${program.id}`;

            return new Promise((resolve, reject) => {
                axios[method](
                    url,
                    program,
                )
                    .then(() => {
                        // TODO: do not request again
                        dispatch("getProgramsFromDB");
                        resolve("Программное обеспечение сохранено");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        deleteProgram({ commit }, programId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/programs/${programId}`)
                    .then(() => {
                        commit("deleteProgram", programId);
                        resolve("Программное обеспечение удалено");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getProgramsSubsystemsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/programs_subsystems`)
                    .then((res) => {
                        commit("setProgramsSubsystems", res.data.programsSubsystems);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveProgramSubsystem({ dispatch }, programSubsystem) {
            const method = programSubsystem.id === null
                ? "post"
                : "put";
            const url = programSubsystem.id === null
                ? `${siteHost}/api/programs_subsystems`
                : `${siteHost}/api/programs_subsystems/${programSubsystem.id}`;

            return new Promise((resolve, reject) => {
                axios[method](
                    url,
                    {
                        programSubsystem,
                    },
                )
                    .then(() => {
                        dispatch("getProgramsSubsystemsFromDB");
                        resolve("Сохранено");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        deleteProgramSubsystem({ commit }, programSubsystemId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/programs_subsystems/${programSubsystemId}`)
                    .then(() => {
                        commit("deleteProgramSubsystem", programSubsystemId);
                        resolve("Программное обеспечение удалено");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
    mutations: {
        setPrograms(state, programs) {
            state.programs = programs;
        },
        deleteProgram(state, programId) {
            const index = state.programs.findIndex((p) => p.id === programId);
            if (index >= 0) {
                state.programs.splice(index, 1);
            }
        },
        setProgramsSubsystems(state, programsSubsystems) {
            state.programsSubsystems = programsSubsystems;
        },
        deleteProgramSubsystem(state, programSubsystemId) {
            const index = state.programsSubsystems.findIndex((p) => p.id === programSubsystemId);
            if (index >= 0) {
                state.programsSubsystems.splice(index, 1);
            }
        },
    },
};
