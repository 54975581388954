import date from 'quasar/src/utils/date/date.js';;

export function guarantyRemains(guaranty_dt, prefix = "") {
    if (!guaranty_dt) {
        return "Гарантийный срок не указан";
    }

    const guarantyDtYMD = date.extractDate(guaranty_dt, "YYYY-MM-DD");

    const diff = date.getDateDiff(
        guarantyDtYMD,
        new Date(),
        "days",
    );

    if (diff <= 0) {
        return "Гарантийный срок истек";
    }

    const years = Math.floor(diff / 365);
    const months = Math.floor((diff - years * 365) / 30);
    const days = diff - years * 365 - months * 30;

    return [
        prefix,
        years > 0 ? `${years} г. ` : "",
        months > 0 ? `${months} м. ` : "",
        days > 0 ? `${days} д.` : "",
    ].join("");
}

export function dateYMDtoDMY(d) {
    return d
        ? date.formatDate(
            date.extractDate(d, "YYYY-MM-DD"),
            "DD.MM.YYYY",
        )
        : date.formatDate(
            new Date(),
            "DD.MM.YYYY",
        );
}

export function dateDMYtoYMD(d) {
    return d
        ? date.formatDate(
            date.extractDate(d, "DD.MM.YYYY"),
            "YYYY-MM-DD",
        )
        : date.formatDate(
            new Date(),
            "YYYY-MM-DD",
        );
}
