import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        filesForSigner: [],
    },
    getters: {
        filesForSigner: (state) => state.filesForSigner,
    },
    actions: {
        getFilesForSignersFromDB({ commit }) {
            axios.get(`${siteHost}/api/files_for_signers`)
                .then((res) => {
                    // console.log(res.data.files);
                    commit("setFilesForSigners", res.data.files);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        saveSign(
            { dispatch },
            { sign, info },
        ) {
            const formData = new FormData();
            formData.append("section", "requestSigns");
            formData.append("requestSigns", sign);
            formData.append("info", JSON.stringify(info));

            axios.post(
                `${siteHost}/api/signs`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                },
            )
                .then(() => {
                    dispatch("getFilesForSignersFromDB");
                })
                .catch((err) => {
                    console.log(err);
                });
        },
    },
    mutations: {
        setFilesForSigners(state, files) {
            state.filesForSigner = files;
        },
    },
};
