import axios from "axios";
import {
    siteHost,
} from "@/store/constants";

export function getDataForReportTollsWaitingRepair(
    regionId,
    dateStart,
    dateFinsh,
) {
    return new Promise((resolve, reject) => {
        const filter = { regionId, dateStart, dateFinsh };
        axios.get(`${siteHost}/api/tools_waiting_repair/${JSON.stringify(filter)}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                reject(err);
            });
    });
}
