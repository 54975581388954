<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    {{ dialogTitle }}
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <SelectFilter
                    v-model="setType"
                    :options="toolsSetsNamesCodes"
                    label="Вид комплекта оборудования"
                    optionLabel="name"
                    clearable
                    outlined
                    dense
                    class="q-mb-sm"
                />
                <q-input
                    ref="refName"
                    v-model="setName"
                    outlined
                    dense
                    class="q-mb-sm"
                    :rules="[val => val &&
                        0 < val.length &&
                        val.length < 100 || 'Значение от 1 до 100 символов']"
                    hideBottomSpace
                    label="Наименование комплекта"
                />
            </q-card-section>

            <q-card-section class="scroll no-padding dialog-section">
                <div class="simple-three-grid">
                    <div class="cell cell-header cell-center">
                        №
                    </div>
                    <div class="cell cell-header cell-center">
                        Оборудование
                    </div>
                    <div class="cell cell-header cell-center">
                        <q-btn
                            round
                            :icon="matAdd"
                            size="sm"
                            color="primary"
                            @click="addTool"
                        >
                            <q-tooltip
                                :delay="300"
                                anchor="top right"
                                self="bottom middle"
                            >
                                Добавить оборудование
                            </q-tooltip>
                        </q-btn>
                    </div>
                    <div
                        v-for="(value, index) in trait.values"
                        :key="index"
                        class="row-wrapper"
                    >
                        <div class="cell cell-center">
                            {{ index + 1 }}
                        </div>
                        <div class="cell cell-center">
                            {{ getToolModelName(tool.tool.model_id) }}
                        </div>
                        <div class="cell cell-center">
                            <q-btn
                                round
                                :icon="matDelete"
                                size="sm"
                                class="poeso-table--control-button"
                                @click="() => delTool(toolIndex)"
                            />
                        </div>
                    </div>
                </div>
            </q-card-section>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    flat
                    label="Сохранить"
                    color="primary"
                    :icon="matSave"
                    @click="onOkClick"
                />
                <q-btn
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import SelectFilter from "@/components/Common/SelectFilter.vue";
import DialogEditTool from "@/components/Dialogs/DialogEditTool/DialogEditTool.vue";

export default {
    name: "DialogToolsSetEdit",
    components: {
        SelectFilter,
    },
    props: {
        itemForEdit: {
            type: String,
            default: "",
        },
        dialogTitle: {
            type: String,
            required: true,
        },
    },
    emits: ["ok"],
    data() {
        return {
            setType: null,
            setName: "",
            item: "",
            tools: [],
        };
    },
    computed: {
        ...mapGetters({
            toolsSetsNamesCodes: "toolsSetsNamesCodes",
            getToolModelName: "getToolModelName",
        }),
    },
    methods: {
        addTool() {
            this.$q.dialog({
                component: DialogEditTool,
                componentProps: {
                    itemForEdit: null,
                },
            })
                .onOk((tool) => {
                    this.tools.push(tool);
                });
        },
        delTool(toolIndex) {
            this.tools.splice(toolIndex, 1);
        },
        //----------------------------------------------------------------------
        show() {
            this.item = this.itemForEdit;
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            this.$refs.refName.validate();
            if (this.$refs.refName.hasError) {
                return;
            }

            this.$emit("ok", {
                type_id: this.setType,
                name: this.setName,
                tools: this.tools,
            });

            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
