import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        toolsSetsNames: [],

        toolsSets: [],
    },
    getters: {
        toolsSetsNames: (state) => state.toolsSetsNames,
        toolsSetsNamesCodes: (state) => state.toolsSetsNames.map((t) => ({
            ...t,
            name: `${t.code} ${t.name}`,
        })),
        toolsSets: (state) => state.toolsSets,
        toolsSetName: (state) => (toolsSetTypeId) => {
            const type = state.toolsSetsNames.find((t) => t.id === toolsSetTypeId);
            return type
                ? type.name
                : "";
        },
    },
    actions: {
        getToolsSetsNamesFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/tools_sets_names`)
                    .then((res) => {
                        commit("setToolsSetsNames", res.data.tools_sets_names);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveToolsSetName({ dispatch }, toolsSetName) {
            const method = toolsSetName.id
                ? "put"
                : "post";

            const url = toolsSetName.id
                ? `${siteHost}/api/tools_sets_names/${toolsSetName.id}`
                : `${siteHost}/api/tools_sets_names`;

            return new Promise((resolve, reject) => {
                axios[method](
                    url,
                    toolsSetName,
                )
                    .then(() => {
                        dispatch("getToolsSetsNamesFromDB");
                        resolve("Вид комплекта оборудования сохранен");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        deleteToolsSetName({ dispatch }, toolsSetNameId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/tools_sets_names/${toolsSetNameId}`)
                    .then(() => {
                        dispatch("getToolsSetsNamesFromDB");
                        resolve("Вид комплекта оборудования удален");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        getToolsSetsFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/tools_sets`)
                    .then((res) => {
                        commit("setToolsSets", res.data.tools_sets);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveToolsSet({ dispatch }, toolsSet) {
            const method = toolsSet.id
                ? "put"
                : "post";

            const url = toolsSet.id
                ? `${siteHost}/api/tools_sets/${toolsSet.id}`
                : `${siteHost}/api/tools_sets`;

            return new Promise((resolve, reject) => {
                axios[method](
                    url,
                    toolsSet,
                )
                    .then((res) => {
                        toolsSet.tools.forEach(({ tool, filesList }) => {
                            dispatch(
                                "saveTool",
                                {
                                    tool: {
                                        ...tool,
                                        set_id: res.data.newToolSetId,
                                    },
                                    filesList,
                                },
                            );
                        });

                        dispatch("getToolsSetsFromDB");
                        resolve("Комплект оборудования сохранен");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
    },
    mutations: {
        setToolsSetsNames(state, toolsSetsNames) {
            state.toolsSetsNames = toolsSetsNames;
        },
        setToolsSets(state, toolsSets) {
            state.toolsSets = toolsSets;
        },
    },
};
