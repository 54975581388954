<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Виды учетных единиц
            ({{ toolsKinds.length }})
        </div>
        <div class="content-body tools-models-kinds">
            <!-- header -->
            <div class="cell cell-header cell-center">
                №
            </div>
            <div class="cell cell-header cell-center">
                Вид оборудования
            </div>
            <div class="cell cell-header cell-center">
                Количество характеристик
            </div>
            <div class="cell cell-header cell-center">
                Содержит обновляемое ПО
            </div>
            <div class="cell cell-header">
                <q-btn
                    round
                    :icon="matAdd"
                    color="primary"
                    size="0.7rem"
                    @click="startEdit(null)"
                />
            </div>
            <!-- data -->
            <div
                v-for="(tKind, index) in toolsKinds"
                :key="index"
                class="row-wrapper"
                @click="startEdit(index)"
            >
                <div class="cell cell-center">
                    {{ index + 1 }}
                </div>
                <div class="cell">
                    {{ tKind.title }}
                </div>
                <div class="cell cell-center">
                    {{ tKind.traits.length }}
                </div>
                <div class="cell cell-center">
                    <q-icon
                        v-if="tKind.soft_updatable"
                        :name="matCheck"
                        size="1.2rem"
                    />
                </div>
                <div class="cell">
                    <q-btn
                        round
                        :icon="matDelete"
                        size="0.7rem"
                        @click="(e) => deleteItem(e, index)"
                    />
                </div>
            </div>
        </div>

        <DialogEditToolKind
            :showDialog="showDialog"
            :toolKindOriginal="toolKind"
            @close-dialog="closeDialog"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";
import DialogEditToolKind from "@/components/Dialogs/DialogEditToolKind/DialogEditToolKind.vue";

export default {
    name: "ToolsKinds",
    components: {
        DialogEditToolKind,
    },
    data() {
        return {
            showDialog: false,
            toolKind: {},
        };
    },
    computed: {
        ...mapGetters({
            toolsKinds: "toolsKinds",
        }),
    },
    methods: {
        startEdit(index) {
            this.toolKind =
                index === null
                    ? {
                        id: null,
                        title: "",
                        soft_updatable: false,
                        mandatory: null,
                        traits: [],
                    }
                    : this.toolsKinds[index];
            this.showDialog = true;
        },
        closeDialog(toolKind) {
            this.showDialog = false;

            if (toolKind) {
                this.$store.dispatch("saveToolKind", toolKind)
                    .then((message) => {
                        this.$store.dispatch("getToolsKindsFromDB");
                        this.$q.notify({
                            type: "notify-success",
                            message,
                        });
                    })
                    .catch((message) => {
                        this.$q.notify({
                            type: "notify-failure",
                            message,
                        });
                    });
            }
        },
        deleteItem(event, index) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить вид учетной единицы "${this.toolsKinds[index].title}"?`,
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteToolKind", this.toolsKinds[index].id)
                        .then((message) => {
                            this.$store.dispatch("getToolsKindsFromDB");
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
    },
};
</script>
