import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        problemsCategories: [],
    },
    getters: {
        problemsCategories: (state) => state.problemsCategories,
    },
    actions: {
        getProblemsCategoriesFromDB({ commit }) {
            return new Promise((resolve, reject) => {
                axios.get(`${siteHost}/api/problems_categories`)
                    .then((res) => {
                        commit("setProblemsCategories", res.data.problems_categories);
                        resolve();
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        saveProblemCategory({ dispatch }, problemCategory) {
            const method = problemCategory.id === null
                ? "post"
                : "put";
            const url = problemCategory.id === null
                ? `${siteHost}/api/problems_categories`
                : `${siteHost}/api/problems_categories/${problemCategory.id}`;

            return new Promise((resolve, reject) => {
                axios[method](
                    url,
                    problemCategory,
                )
                    .then(() => {
                        // TODO: do not request again
                        dispatch("getProblemsCategoriesFromDB");
                        resolve("Категория проблем сохранена");
                    })
                    .catch((err) => {
                        reject(err.response.data);
                    });
            });
        },
        deleteProblemCategory({ commit }, problemCategoryId) {
            return new Promise((resolve, reject) => {
                axios.delete(`${siteHost}/api/problems_categories/${problemCategoryId}`)
                    .then(() => {
                        commit("deleteProblemCategory", problemCategoryId);
                        resolve("Категория проблем  удалена");
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
    mutations: {
        setProblemsCategories(state, problemsCategories) {
            state.problemsCategories = problemsCategories;
        },
        deleteProblemCategory(state, problemCategoryId) {
            const index = state.problemsCategories.findIndex((m) => m.id === problemCategoryId);
            if (index >= 0) {
                state.problemsCategories.splice(index, 1);
            }
        },
    },
};
