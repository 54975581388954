import axios from "axios";
import { siteHost } from "./constants";

export default {
    state: {
        currentUser: {
            id: null,
            displayName: "",
            email: "",
            isAuthenticated: false,
            organizations: [],
            units: [],
            users_roles: [],
            rightsObject: {},
        },
    },
    getters: {
        currentUser: (state) => state.currentUser,
    },
    actions: {
        login({ commit, dispatch }, { userName, userPassword }) {
            commit("setMainSpinnerState", true);
            return new Promise((resolve, reject) => {
                axios.post(
                    `${siteHost}/api/auth/signin`,
                    {
                        username: userName,
                        password: userPassword,
                    },
                )
                    .then((res) => {
                        commit("setUser",
                            {
                                ...res.data,
                                isAuthenticated: true,
                            });

                        dispatch("loadDataFromDB");

                        resolve();
                    })
                    .catch((err) => {
                        reject(err.response.data);
                        commit("setMainSpinnerState", false);
                    });
            });
        },
        logout({ commit }) {
            commit(
                "setUser",
                {
                    id: null,
                    displayName: "",
                    email: "",
                    isAuthenticated: false,
                    organizations: [],
                    units: [],
                    users_roles: [],
                    rightsObject: {},
                },
            );
        },
        checkUser({ dispatch }) {
            let userO = {};
            // const userS = localStorage.getItem("201130_poeso_user");
            const userS = sessionStorage.getItem("201130_poeso_user");
            if (userS) {
                userO = JSON.parse(userS);
            }

            if (userO.id) {
                dispatch("setUser", userO);
            }
        },
        setUser({ commit, dispatch }, currentUser) {
            commit("setMainSpinnerState", true);
            return new Promise((resolve) => {
                // axios uses currentuser.id
                commit("setUser", currentUser);
                if (currentUser.id) {
                    axios.get(`${siteHost}/api/auth/rights`)
                        .then((res) => {
                            // TODO: set only rights
                            commit(
                                "setUser",
                                {
                                    ...currentUser,
                                    rights: res.data.rights,
                                    accessLevel: res.data.accessLevel,
                                    rightsObject: res.data.rightsObject,
                                },
                            );
                            dispatch("loadDataFromDB");
                        })
                        .catch((err) => {
                            // console.log(err);
                            console.log(err.response);
                            commit("setMainSpinnerState", false);
                        });
                }
                resolve();
            });
        },
        setUserToken({ commit }, token) {
            commit("setUserToken", token);
        },
    },
    mutations: {
        setUser(state, currentUser) {
            state.currentUser = currentUser;

            // localStorage.setItem("201130_poeso_user", JSON.stringify(currentUser));
            sessionStorage.setItem("201130_poeso_user", JSON.stringify(currentUser));
        },
        setUserToken(state, token) {
            state.currentUser.accessToken = token;
            // localStorage.setItem("201130_poeso_user", JSON.stringify(state.currentUser));
            sessionStorage.setItem("201130_poeso_user", JSON.stringify(state.currentUser));
        },
    },
};
