<template>
    <div class="users-filter">
        <div
            v-if="showFilter"
            class="users-filter"
        >
            <q-select
                v-model="manufacturerId"
                outlined
                dense
                clearable
                :options="manufacturers"
                optionValue="id"
                optionLabel="title"
                mapOptions
                emitValue
                label="Производитель"
                class="users-filter--input"
            />
            <q-select
                v-model="toolKindId"
                outlined
                dense
                clearable
                :options="toolsKinds"
                optionValue="id"
                optionLabel="title"
                mapOptions
                emitValue
                label="Вид оборудования"
                class="users-filter--input"
            />
            <q-input
                v-model="toolModel"
                outlined
                dense
                clearable
                label="Наименование"
            />
            <q-select
                v-model="isVerified"
                outlined
                dense
                clearable
                :options="isVerifiedValues"
                emitValue
                mapOptions
                label="Проверка оборудования"
                class="users-filter--input"
            />
        </div>

        <q-btn
            round
            :icon="matSearch"
            @click="showFilter = !showFilter"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "FilterToolsModels",
    setup() {
        return {
            isVerifiedValues: [
                {
                    label: "Выполнена",
                    value: true,
                },
                {
                    label: "Не выполнена",
                    value: false,
                },
            ],
        };
    },
    data() {
        return {
            showFilter: false,
        };
    },
    computed: {
        ...mapGetters({
            toolsKinds: "toolsKinds",
            manufacturers: "manufacturers",
            toolsModelsFilter: "toolsModelsFilter",
        }),
        manufacturerId: {
            get() {
                return this.toolsModelsFilter.manufacturer_id;
            },
            set(value) {
                this.$store.dispatch("setToolsModelsFilter",
                    {
                        ...this.toolsModelsFilter,
                        manufacturer_id: value,
                    });
            },
        },
        toolKindId: {
            get() {
                return this.toolsModelsFilter.tool_kind_id;
            },
            set(toolKindId) {
                this.$store.dispatch("setToolsModelsFilter",
                    {
                        ...this.toolsModelsFilter,
                        tool_kind_id: toolKindId,
                    });
            },
        },
        toolModel: {
            get() {
                return this.toolsModelsFilter.model;
            },
            set(toolModel) {
                this.$store.dispatch("setToolsModelsFilter",
                    {
                        ...this.toolsModelsFilter,
                        model: toolModel,
                    });
            },
        },
        isVerified: {
            get() {
                return this.toolsModelsFilter.is_verified;
            },
            set(v) {
                this.$store.dispatch("setToolsModelsFilter",
                    {
                        ...this.toolsModelsFilter,
                        is_verified: v,
                    });
            },
        },
    },
    beforeUnmount() {
        this.$store.dispatch("setToolsModelsFilter", {});
    },
};
</script>
