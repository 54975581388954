<template>
    <div class="content content__flex-with-table">
        <div class="content-title">
            Подсистемы ГАС "Правосудие"
            ({{ programsSubsystems.length }})
        </div>
        <div class="content-body">
            <div class="programs-subsystems-grid">
                <div class="cell cell-header cell-center">
                    №
                </div>
                <div class="cell cell-header cell-center">
                    Наименование
                </div>
                <div class="cell cell-header cell-center">
                    <q-btn
                        v-if="canBeEdited"
                        round
                        :icon="matAdd"
                        size="0.7rem"
                        color="primary"
                        @click="editItem(null)"
                    />
                </div>

                <div
                    v-for="(programSubsystem, index) in programsSubsystems"
                    :key="index"
                    class="row-wrapper"
                    @click="() => editItem(index)"
                >
                    <div class="cell cell-center">
                        {{ index + 1 }}
                    </div>
                    <div class="cell">
                        {{ programSubsystem.title }}
                    </div>
                    <div class="cell cell-center">
                        <q-btn
                            v-if="canBeEdited"
                            round
                            :icon="matDelete"
                            size="0.7rem"
                            @click="(e) => deleteItem(e, index)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RIGHTS_CONSTANTS } from "@/store/constants";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";

import DialogEditSimple from "@/components/Dialogs/DialogEditSimple.vue";

export default {
    name: "ProgramsSubsystems",
    components: {
    },
    computed: {
        ...mapGetters({
            programsSubsystems: "programsSubsystems",
            currentUser: "currentUser",
        }),
        canBeEdited() {
            return !!(this.currentUser.rightsObject.RIGHT_REFERENCE_GAS_SUBSYSTEM & RIGHTS_CONSTANTS.FULL);
        },
    },
    methods: {
        editItem(index) {
            if (!this.canBeEdited) {
                return;
            }

            this.$q.dialog({
                component: DialogEditSimple,
                componentProps: {
                    dialogTitle: "Подсистема ГАС \"Правосудие\"",
                    itemForEdit: index === null
                        ? ""
                        : this.programsSubsystems[index].title,
                },
            })
                .onOk((programSubsystemTitle) => {
                    this.$store.dispatch(
                        "saveProgramSubsystem",
                        {
                            id: index === null ? null : this.programsSubsystems[index].id,
                            title: programSubsystemTitle,
                        },
                    )
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
        deleteItem(event, index) {
            event.stopPropagation();

            this.$q.dialog({
                component: DialogConfirm,
                componentProps: {
                    dialogMessage: `Удалить подсистему ГАС "Правосудие" "${this.programsSubsystems[index].title}"?`,
                },
            })
                .onOk(() => {
                    this.$store.dispatch("deleteProgramSubsystem", this.programsSubsystems[index].id)
                        .then((message) => {
                            this.$q.notify({
                                type: "notify-success",
                                message,
                            });
                        })
                        .catch((err) => {
                            this.$q.notify({
                                type: "notify-failure",
                                message: err,
                            });
                        });
                });
        },
    },
};
</script>
