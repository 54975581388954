<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    {{ dialogTitle }}
                </div>
            </q-card-section>

            <q-separator />

            <div class="simple-three-grid">
                <div class="cell cell-header cell-center">
                    №
                </div>
                <div class="cell cell-header cell-center">
                    ФИО
                </div>
                <div class="cell cell-header cell-center" />
                <div
                    v-for="(enngineer, engineerIndex) in workers"
                    :key="engineerIndex"
                    class="row-wrapper"
                >
                    <div class="cell cell-center">
                        {{ engineerIndex + 1 }}
                    </div>
                    <div class="cell">
                        {{ enngineer.surname }}
                        {{ enngineer.name }}
                        {{ enngineer.patronymic || "" }}
                    </div>
                    <div class="cell cell-center">
                        <q-btn
                            round
                            :icon="matCheck"
                            size="sm"
                            class="poeso-table--control-button"
                            @click="() => chooseEngineer(enngineer.id)"
                        />
                    </div>
                </div>
            </div>

            <q-separator />

            <q-card-actions align="right">
                <q-btn
                    v-close-popup
                    flat
                    label="Отменить"
                    color="primary"
                    :icon="matCancel"
                    @click="onCancelClick"
                />
            </q-card-actions>
        </q-card>
    </q-dialog>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    name: "DialogSearchEngineer",
    props: {
        dialogTitle: {
            type: String,
            required: true,
        },
        workerType: {
            type: String,
            default: "engineer",
        },
    },
    emits: ["ok"],
    computed: {
        ...mapGetters({
            engineers: "engineers",
            usersHotline: "usersHotline",
        }),
        workers() {
            return this.workerType === "engineer"
                ? this.engineers
                : this.usersHotline;
        },
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        chooseEngineer(id) {
            this.$emit("ok", id);

            this.hide();
        },
        onCancelClick() {
            this.hide();
        },
    },
};
</script>
