<template>
    <q-dialog
        ref="dialog"
        persistent
    >
        <q-card style="width: 700px; max-width: 80vw;">
            <q-card-section>
                <div class="text-h6">
                    Выбрать подписантов для документа
                </div>
            </q-card-section>

            <q-separator />

            <q-card-section
                style="max-height: 70vh;"
                class="scroll"
            >
                <PoeDatePicker
                    v-if="showDocDate"
                    v-model="actDate"
                    labelTitle="Дата акта"
                    :futureDateAllowed="true"
                    style="flex-grow: 0;"
                />

                <div class="q-gutter-sm">
                    <q-radio
                        v-model="signersCount"
                        :val="2"
                        label="2 подписанта"
                    />
                    <q-radio
                        v-model="signersCount"
                        :val="3"
                        label="3 подписанта"
                    />
                </div>

                <DialogSignersSigner
                    v-for="signerIndex in +signersCount"
                    :key="signerIndex"
                    ref="refSigner"
                    v-model="signers[signerIndex - 1]"
                    :signers="signers"
                    :rightSigningDocs="rights[signerIndex - 1]"
                />
            </q-card-section>

            <q-separator />

            <div
                class="flex"
                style="padding: 8px;"
            >
                <q-field
                    outlined
                    label="Вид подписания документов"
                    stackLabel
                >
                    <q-radio
                        v-model="signType"
                        val="paper"
                        label="На бумажном носителе"
                    />
                    <q-radio
                        v-model="signType"
                        val="electron"
                        label="Посредством ЭЦП"
                    />
                </q-field>

                <q-space />

                <q-btn
                    flat
                    label="Продолжить"
                    color="primary"
                    :icon="matPlayArrow"
                    @click="onOkClick"
                />
                <q-btn
                    v-close-popup
                    flat
                    label="Выйти"
                    color="primary"
                    :icon="matCancel"
                />
            </div>
        </q-card>
    </q-dialog>
</template>

<script>
import { date } from "quasar";
import PoeDatePicker from "@/components/Common/PoeDatePicker.vue";
import DialogSignersSigner from "./DialogSignersSigner.vue";

export default {
    name: "DialogSigners",
    components: {
        DialogSignersSigner,
        PoeDatePicker,
    },
    props: {
        signersCountByOrgKind: {
            type: Number,
            required: true,
        },
        showDocDate: {
            type: Boolean,
            default: false,
        },
    },
    emits: ["ok"],
    data() {
        return {
            actDate: date.formatDate(new Date(), "DD.MM.YYYY"),
            signersCount: this.signersCountByOrgKind,
            signers: [
                { id: null },
                { id: null },
                { id: null },
            ],
            rights: [
                "RIGHT_SIGNING_DOCS_IAC",
                "RIGHT_SIGNING_DOCS_UNIT",
                "RIGHT_SIGNING_DOCS_FINAL",
            ],
            signType: "electron",
        };
    },
    methods: {
        show() {
            this.$refs.dialog.show();
        },
        hide() {
            this.$refs.dialog.hide();
        },
        onOkClick() {
            let bError = false;
            for (let i = 0; i < +this.signersCount; i++) {
                bError = this.$refs.refSigner[i].validate() || bError;
            }
            if (bError) {
                return;
            }
            this.$emit(
                "ok",
                {
                    actDate: this.actDate,
                    signers: this.signers,
                    signType: this.signType,
                },
            );
            this.hide();
        },
    },
};
</script>
